// ヘッダー
.ly_header {
	display: flex;
	justify-content: space-between;
	padding: 0 16px;
	height: 64px;

	&_inner {
		display: flex;
		align-items: center;

		// 左側メニュー
		&__ttl {
			flex: 0 1 3360px;	// 店舗選択メニュー側に寄せるため大きめの値を設定 3840 - 480(右側メニュー幅)

			// ドロワーメニュー展開ボタン
			.el_navToggle {
				flex: 0 0 24px;
				width: 24px;
				height: 21px;
				margin-right: 24px;
			}

			.el_systemName {
				flex: 0 0 217px;
				picture {
					width: 100%;
				}

				img {
					width: 100%;
				}
			}

			.el_links {
				margin-left: auto;
				height: 48px;

				// ガイドリンク
				.el_linkIcon__guide {
					flex: 0 0 24px;
					box-sizing: content-box;
					display: block;
					width: 24px;
					height: 24px;
					margin-left: auto;
					padding: 4px 16px;
					background-image: url(../img/icon-q.svg);
					background-position: center;
				}
				
				// ガイドリンク(ヘッダー)
				.el_linkIcon__guideL {
					display: inline-block;
					width: 188px;
					height: 48px;
					margin: 0 4px;
					background: $main1 0% 0% no-repeat padding-box;
					border-radius: 48px;
					text-align: center;

					> span {
						line-height: 48px;
						vertical-align: middle;
						color: #FFF;
						font-size: 18px;
						font-weight: bold;

						&::before {
							position: relative;
							content: "";
							display: inline-block;
							top: 6px;
							left: -8px;
							width: 24px;
							height: 24px;
							background-image: url(../img/q-beginner-s.svg);
							background-size: contain;
							background-repeat: no-repeat;
						}

						.br {
							display: none;
						}
					}
				}
				// お問い合わせリンク(ヘッダー)
				.el_linkIcon__contactL {
					display: inline-block;
					width: 188px;
					height: 48px;
					margin: 0 4px;
					background: $main1 0% 0% no-repeat padding-box;
					border-radius: 48px;
					text-align: center;

					> span {
						line-height: 48px;
						vertical-align: middle;
						color: #FFF;
						font-size: 18px;
						font-weight: bold;

						&::before {
							position: relative;
							content: "";
							display: inline-block;
							top: 6px;
							left: -8px;
							width: 24px;
							height: 24px;
							background-image: url(../img/icon-mail.svg);
							background-size: contain;
							background-repeat: no-repeat;
						}

						.br {
							display: none;
						}
					}
				}
				// よくある質問リンク(ヘッダー)
				.el_linkIcon__faqL {
					display: inline-block;
					width: 188px;
					height: 48px;
					margin: 0 4px;
					background: $main1 0% 0% no-repeat padding-box;
					border-radius: 48px;
					text-align: center;

					> span {
						line-height: 48px;
						vertical-align: middle;
						color: #FFF;
						font-size: 18px;
						font-weight: bold;

						&::before {
							position: relative;
							content: "";
							display: inline-block;
							top: 6px;
							left: -8px;
							width: 24px;
							height: 24px;
							background-image: url(../img/icon-q-w.svg);
							background-size: contain;
							background-repeat: no-repeat;
						}

						.br {
							display: none;
						}
					}
				}
			}

		}

		// 右側メニュー
		&__menu {
			flex: 0 1 480px;
		}
	}
}

@include mediaQuery($MOBILE) {
	.ly_header {
		flex-direction: column;
		position: sticky;
		top: 0;
		left: 0;
		height: auto;
		padding: 0;
		box-shadow: 0 0 2px $gray_16;
		z-index: 20;

		&_inner {
			flex: none;
		}

		&_inner__ttl {
			justify-content: space-between;
			padding: 4px 4px 4px 24px;
			background-color: #FFF;
			z-index: 30;
			border-bottom: 1px solid $gray2;

			.el_navToggle {
				width: 27px;
				margin-right: 0;
			}

			.el_systemName {
				flex: 0 1 217px;
				margin-left: 16px;
			}

			.el_links {
				margin-left: 0;
				height: 56px;

				.el_linkIcon__guide {
					margin-left: 0;
					background-image: url(../img/icon-q-w.svg);
				}

				// ガイドリンク(ヘッダー)
				.el_linkIcon__guideL {
					width: 61px;
					height: 56px;
					margin: 0 4px;
					padding-top: 4px;
					background: $main1 0% 0% no-repeat padding-box;
					border-radius: 2px;
					text-align: center;

					> span {
						line-height: 0;
						vertical-align: middle;
						color: #FFF;
						font-size: 11px;
						font-weight: bold;

						&::before {
							position: relative;
							content: "";
							display: inline-block;
							top: 0;
							left: 0;
							width: 16px;
							height: 16px;
							background-image: url(../img/q-beginner-s.svg);
							background-size: contain;
							background-repeat: no-repeat;
						}

						.br {
							display: inline;
						}
					}
				}
				// お問い合わせリンク(ヘッダー)
				.el_linkIcon__contactL {
					width: 61px;
					height: 56px;
					margin: 0 4px;
					padding-top: 4px;
					background: $main1 0% 0% no-repeat padding-box;
					border-radius: 2px;
					text-align: center;

					> span {
						line-height: 0;
						vertical-align: middle;
						color: #FFF;
						font-size: 11px;
						font-weight: bold;

						&::before {
							position: relative;
							content: "";
							display: inline-block;
							top: 0;
							left: 0;
							width: 16px;
							height: 16px;
							background-image: url(../img/icon-mail.svg);
							background-size: contain;
							background-repeat: no-repeat;
						}

						.br {
							display: inline;
						}
					}
				}
				// よくある質問リンク(ヘッダー)
				.el_linkIcon__faqL {
					width: 61px;
					height: 56px;
					margin: 0 4px;
					padding-top: 4px;
					background: $main1 0% 0% no-repeat padding-box;
					border-radius: 2px;
					text-align: center;

					> span {
						line-height: 0;
						vertical-align: middle;
						color: #FFF;
						font-size: 11px;
						font-weight: bold;

						&::before {
							position: relative;
							content: "";
							display: inline-block;
							top: 0;
							left: 0;
							width: 16px;
							height: 16px;
							background-image: url(../img/icon-q-w.svg);
							background-size: contain;
							background-repeat: no-repeat;
						}

						.br {
							display: inline;
						}
					}
				}
			}
		}

		&_inner__menu {
			justify-content: space-between;
			padding: 0 24px;
			background-color: #FFF;
			z-index: 10;
		}
	}
}

// ボディ
.ly_body {
	display: flex;
	min-height: calc(100vh - 64px);
}

// サイドドロワー
.ly_side {
	flex: 0 0 auto;
	background-color: $drawer;
	order: 1;
	z-index: 10;
}

@include mediaQuery($MOBILE) {
	.ly_side {
		position: fixed;
		top: 64px;
		left: 0;
		z-index: 30;
	}
}

// メイン
.ly_main {
	flex: 1 1 auto;
	background-color: $gray1;
	order: 2;
	z-index: 5;

	.ly_main_error {
		padding: 20px 0;
		background-color: $error;
		line-height: 1.8;
		text-align: center;
		font-weight: bold;
		color: #FFF;

		h2 {
			font-size: 18px;
		}
	}

	.ly_main_content {
		padding: 48px;
	}
}

@include mediaQuery($MOBILE) {
	.ly_main {
		overflow-x: hidden;

		.ly_main_content, .ly_main_error {
			padding: 24px;
		}
	}
}

// ログインページ
.ly_login {
	display: flex;
	min-height: 100vh;
}

.ly_entry {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex: 0 1 1200px;
	padding: 0 32px;
	background-color: $main1;
}

.ly_loginForm {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex: 0 1 720px;
	padding-right: 80px;
	padding-left: 80px;

	&_inner {
		width: 100%;
	}
}

// パスワード再発行ページ
.ly_reissue {
	display: flex;
	justify-content: space-around;
	align-items: center;
	min-height: 100vh;
	padding: 0 32px;
	background-image: linear-gradient(180deg, $main1 0%, $main3 100%);

	.el_entryTitle {
		margin-bottom: 32px;
		text-align: center;
	}
}

@include mediaQuery($MOBILE) {
	.ly_login {
		flex-direction: column;
		justify-content: center;
		background-color: $main1;
	}

	.ly_entry {
		flex: none;
		padding: 0 40px;

		.el_entryTitle {
			> img {
				width: 100%;
			}
		}
	}

	.ly_loginForm {
		flex: none;
		padding: 8px 24px 24px;
	}

	.ly_loginForm_inner {
		padding: 24px;
		border-radius: 6px;
		background-color: none;

		> div {
			padding: 24px;
			border-radius: 6px;
			background-color: #FFF;
		}
	}

	.ly_reissue {
		align-items: center;
		padding: 24px;
	}
}

@include mediaQuery($SP) {
	.ly_login {
		padding: 24px 0;
	}

	.ly_reissue {

		&_inner {

			> .el_entryTitle {

				> img {
					width: 100%;
				}
			}
		}
	}
}