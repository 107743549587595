// TOC:
// 1. レイアウト
// 2. マージン
// 3. パディング
// 4. テキスト
// 5. デバイスサイズ
// 6.

// 1. レイアウト
// ===========================================================================

// 両端寄せ
.hp_justified {
	display: flex !important;
	justify-content: space-between !important;
}

// ベースライン揃え
.hp_alignBaseline {
	display: flex;
	align-items: baseline !important;
}


// 2. マージン
// ===========================================================================

// $m*_listの項目がそれぞれクラス名と値に書き出される。

// top
$mt_list: 2, 8, 16, 32;
@each $num in $mt_list {
	.mt_#{$num} {
		margin-top: #{$num}px !important;
	}
}

// right
$mr_list: 8, 32;
@each $num in $mr_list {
	.mr_#{$num} {
		margin-right: #{$num}px !important;
	}
}

// bottom
$mb_list: 0, 8, 16, 20, 24, 32, 48;
@each $num in $mb_list {
	.mb_#{$num} {
		margin-bottom: #{$num}px !important;
	}
}

// left
$ml_list: 8, 16, 26;
@each $num in $ml_list {
	.ml_#{$num} {
		margin-left: #{$num}px !important;
	}
}

@include mediaQuery($MOBILE) {
	// left
	$ml_list: 0, 26, 96;
	@each $num in $ml_list {
		.mobile_ml_#{$num} {
			margin-left: #{$num}px !important;
		}
	}
}

@include mediaQuery($SP) {
	// all
	$ma_list: 0;
	@each $num in $ma_list {
		.sp_ma_#{$num} {
			margin: #{$num}px !important;
		}
	}

	// top
	$mt_list: 16;
	@each $num in $mt_list {
		.sp_mt_#{$num} {
			margin-top: #{$num}px !important;
		}
	}

	// left
	$ml_list: 26;
	@each $num in $ml_list {
		.sp_ml_#{$num} {
			margin-left: #{$num}px !important;
		}
	}

	// bottom
	$mb_list: 16;
	@each $num in $mb_list {
		.sp_mb_#{$num} {
			margin-bottom: #{$num}px !important;
		}
	}
}




// 3. パディング
// ===========================================================================

.pl_26 {
	padding-left: 26px !important;
}


// 4. テキスト
// ===========================================================================

// $fs_listの項目がそれぞれクラス名と値に書き出される。

// サイズ
$fs_list: 12, 14, 18, 24;
@each $num in $fs_list {
	.fs_#{$num} {
		font-size: #{$num}px !important;
	}
}


// 色
.txt_blue {
	color: $main1 !important;
}

.txt_red {
	color: $red !important;
}

.txt_bold {
	font-weight: bold;
}

.txt_alignRight {
	text-align: right !important;
}

.txt_alignCenter {
	text-align: center !important;
}

// 改行時のインデント
$indent_list: 26;
@each $num in $indent_list {
	.break_indent_#{$num} {
		padding-left: #{$num}px !important;
		text-indent: -#{$num}px !important;
	}
}



// 5. デバイスサイズ
// ===========================================================================

.show_mobile {
	display: none !important;
}

.show_sp {
	display: none !important;
}

@include mediaQuery($MOBILE) {
	.show_mobile {
		display: block !important;
	}

	.mobile_block {
		display: block !important;

		> *:first-child {
			margin-left: 0 !important;
		}
	}

	$indent_list: 26;
	@each $num in $indent_list {
		.mobile_indent_#{$num} {
			padding-left: #{$num}px !important;
		}
	}
}

@include mediaQuery($SP) {
	.show_sp {
		display: block !important;
	}

	.sp_block {
		display: block !important;

		> *:first-child {
			margin-left: 0 !important;
		}
	}

	$indent_list: 26;
	@each $num in $indent_list {
		.sp_indent_#{$num} {
			padding-left: #{$num}px !important;
		}
	}
}



// 6.
// ===========================================================================





// 7.
// ===========================================================================




