// TOC:
// 1.  見出し
// 2.  テキスト
// 3.  ボタン
// 4.  テーブル
// 5.  管理者バッジ
// 6.  ファイルドロップ
// 7.  チェックマーク
// 8.  ラジオ・チェックボックス
// 9.  インプットフォーム
// 10. ログイン状態を保存
// 11. お気に入り
// 12. グラフ
// 13. 外部リンク


// 1. 見出し
// ================================================================================

// トップページのパネルタイトル
.el_panel_ttlTop {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 16px;
	padding-bottom: 16px;
	border-bottom: 2px solid $main1;
	line-height: 1.4;
	font-weight: bold;
	font-size: 18px;
	color: $main1;

	&.el_panel_ttlTop__simple {
		margin-bottom: 0;
		border-bottom: none;
	}
}

// トップページ以外のページ名
.el_pageTtl {
	margin-bottom: 16px;
	font-weight: bold;
	font-size: 24px;
	color: $main1;
}

.el_lv2Heading {
	margin-bottom: 16px;
	padding-bottom: 8px;
	border-bottom: 2px solid currentColor;
	font-weight: bold;
	font-size: 18px;
	color: $main1;
}

.el_lv3Heading {
	margin-bottom: 16px;
	padding-left: 8px;
	border-left: 2px solid currentColor;
	font-weight: bold;
	color: $main1;
}

.el_lv4Heading {
	margin-bottom: 16px;
	font-weight: bold;
	color: $main1;
}

.el_analysisHeading {
	margin-bottom: 16px;
	font-weight: bold;
	color: $main1;

	> span {
		margin-left: 8px;
		font-weight: normal;
		font-size: 12px;
		color: $base;
	}
}

.el_withIconHeading {
	display: flex;
	align-items: center;
	justify-content: center;
	align-items: center;
	gap: 8px 16px;
	font-size: 32px;
	padding-block: 32px !important;

	br {
		display: none;
	}

	@include mediaQuery($MOBILE) {
		flex-direction: column;
		text-align: center;
		padding-block: 24px !important;

		br {
			display: initial;
		}
	}

	img {
		width: 40px;
		height: auto;
	}
}

// 2. テキスト
// ================================================================================

// ページタイトル下の説明分文
.el_pageDesc {
	margin-bottom: 24px;
	color: $main1;
}

.el_txtOrange {
	color: $accent1;
}

// フォームエラー
.el_errorMessage {
	margin-top: 8px;
	color: $error;
}

.el_resultCount {
	margin-top: 16px;
	margin-bottom: 24px;

	&:last-child {
		margin-bottom: 0;
	}
}

.el_note {
	font-weight: bold;
	font-size: 12px;
	color: $accent1;
}

button.el_note:hover {
	color: $accent2;
}

// トップページの合計件数
.el_panel_totalNum {
	line-height: 1;
	text-align: center;
	font-weight: bold;
	font-size: 40px;
	// font-size: 100%;
	color: $accent1;
	// color: #F39;

	&.is_plus {
		padding-right: 24px;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			bottom: 5px;
			right: 0;
			border-style: solid;
			border-width: 0 8px 16px 8px;
			border-color: transparent transparent $accent1 transparent;
		}
	}

	&.is_minus {
		padding-right: 24px;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			bottom: 5px;
			right: 0;
			border-style: solid;
			border-width: 16px 8px 0 8px;
			border-color: $accent1 transparent transparent transparent;
		}
	}
}

@include mediaQuery(1680) {

	.el_panel_totalNum {
		font-size: 2.38095vw;

		> span.fs_18.txt_blue {
			font-size: 1.07142vw !important;
			white-space: nowrap;
		}
	}
}

@include mediaQuery($MOBILE) {

	.el_panel_totalNum {
		font-size: 40px;

		> span.fs_18.txt_blue {
			font-size: 18px !important;
		}
	}
}

@include mediaQuery(640) {

	.el_panel_totalNum {
		font-size: 3.90625vw;

		> span.fs_18.txt_blue {
			font-size: 2.8125vw !important;
		}
	}
}

@include mediaQuery($SP) {

	.el_panel_totalNum {
		font-size: 8.33333vw;

		> span.fs_18.txt_blue {
			font-size: 3.75vw !important;
		}
	}
}

// トップページの合計件数(単位)
.el_panel_totalNum_unit {
	margin-left: 8px;
	font-size: 24px;
	color: $main1;
}

.el_num {
	font-weight: bold;
	font-size: 18px;

	&_unit {
		margin-left: 8px;
		font-size: 14px;
	}
}

.el_selectedCount {
	display: inline-block;
	font-weight: bold;
	color: $accent1;
}

// キャンペーン登録時に未入力項目があるとき
.el_incomplete {
	margin-right: 16px;
	font-weight: bold;
	color: $error;
}

// キャンペーン登録
.el_selectedCategory {
	margin-bottom: 16px;
	font-weight: bold;
	font-size: 18px;
	color: $main1;
}

.el_selectedStep {
	margin-bottom: 8px;
	font-weight: bold;
	font-size: 18px;
	color: $main1;
}

// レビュー分析の表示期間ボタンのキャプション
.el_termCaption {
	margin-right: 16px;
	font-weight: bold;
}

.el_graphLimit {
	font-weight: bold;
	color: $accent1;
}

.el_backLink {
	text-decoration: underline;
	font-weight: bold;
	color: $main1;

	&:hover {
		color: $main2;
	}
}

.el_noticeLink {
	display: block;
	text-align: right;
	text-decoration: underline;
	font-weight: bold;
	color: $main1;

	&:hover {
		color: $main2;
	}
}

.el_externalLink {
	text-decoration: underline;
	font-weight: bold;
	color: $main1;

	&:hover {
		color: $main2;
	}
}

.el_rakutenSettingDesc {
	margin-bottom: 24px;
	color: $main1;
}

.el_rakutenSettingNote {
	margin-bottom: 24px;
	color: $accent1;
}


// 3. ボタン
// ================================================================================

.el_btn {
	padding: 16px 23px;
	border-radius: 4px;
	border: 1px solid $accent2;
	background-image: linear-gradient(180deg, $accent4 0%, $accent5 68%, $accent1 100%);
	text-align: center;
	font-weight: bold;
	font-size: 18px;
	color: #FFF;

	&:hover {
		background-image: linear-gradient(180deg, $accent2 0%, $accent6, $accent7 100%);
	}
}

.el_largeBtnWrap {
	display: flex;

	&:not(:last-child) {
		margin-bottom: 24px;
	}

	> * {
		flex: 0 1 auto;
		margin-right: 16px;
	}
}

@include mediaQuery($SP) {
	.el_largeBtnWrap {

		> * {
			margin-right: 16px;
			margin-left: 0;
			font-size: calc(100vw * 0.0375);

			&:last-child {
				margin-right: 0;
			}
		}
	}
}


// 大きいボタン
.el_largeBtn {

	padding: 16px 23px;
	border-radius: 4px;
	border: 1px solid $accent2;
	background-image: linear-gradient(180deg, $accent4 0%, $accent5 68%, $accent1 100%);
	text-align: center;
	font-weight: bold;
	font-size: 18px;
	color: #FFF;

	&:hover {
		background-image: linear-gradient(180deg, $accent2 0%, $accent6, $accent7 100%);
	}

	&.el_largeBtn__fullWidth {
		width: 100%;
	}

	&:disabled {
		filter: grayscale(100%);
	}
}


// 大きいボタン
.el_largeBtnBlueInv {

	padding: 16px 23px;
	border-radius: 4px;
	border: 1px solid currentColor;
	text-align: center;
	font-weight: bold;
	font-size: 18px;
	color: $main1;

	&:hover {
		background-color: $main4;
	}
}

.el_largeBtn.el_largeBtn__prev {
	border: 1px solid currentColor;
	background-image: none;
	background-color: #FFF;
	color: $gray4;

	&:hover {
		background-color: $gray2;
	}
}

@include mediaQuery($SP) {
	.el_largeBtn.el_largeBtn__prev {

		+ .el_largeBtn {
			margin-left: 8px;
		}
	}
}

// 保存失敗時のボタン
.el_largeBtn.is_error {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		right: calc(-32px + -8px);
		width: 32px;
		height: 32px;
		background-image: url(../img/ng.svg);
		transform: translateY(-50%);
		pointer-events: none;
	}
}

// 保存成功時のボタン
.el_largeBtn.is_success {
	position: relative;

	margin-right: 48px;

	// 自動でmargin-rightの削除
	-moz-animation: removeMargin 1s ease 5s forwards;
	/* Firefox */
	-webkit-animation: removeMargin 1s ease 5s forwards;
	/* Safari and Chrome */
	-o-animation: removeMargin 1s ease 5s forwards;
	/* Opera */
	animation: removeMargin 1s ease 5s forwards;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;

	@keyframes removeMargin {
		from {
			margin-right: 48px;
		}
		to {
			margin-right: 16px;
		}
	}
	@-webkit-keyframes removeMargin {
		from {
			margin-right: 48px;
		}
		to {
			margin-right: 16px;
		}
	}

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		right: calc(-32px + -8px);
		width: 32px;
		height: 32px;
		background-image: url(../img/ok.svg);
		transform: translateY(-50%);
		pointer-events: none;

		// 自動で消える
		-moz-animation: fadeOut 1s ease 5s forwards;
		/* Firefox */
		-webkit-animation: fadeOut 1s ease 5s forwards;
		/* Safari and Chrome */
		-o-animation: fadeOut 1s ease 5s forwards;
		/* Opera */
		animation: fadeOut 1s ease 5s forwards;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;

		@keyframes fadeOut {
			from {
				opacity: 1;
			}
			to {
				opacity: 0;
			}
		}
		@-webkit-keyframes fadeOut {
			from {
				opacity: 1;
			}
			to {
				opacity: 0;
			}
		}
	}
}

// ボタン - 反転
.el_btnInv {
	padding-top: 8px;
	padding-right: 15px;
	padding-bottom: 8px;
	padding-left: 15px;
	border-radius: 4px;
	border: 1px solid currentColor;
	font-weight: bold;
	color: $accent1;

	&:disabled {
		background-color: #FFF;
		filter: grayscale(100%) contrast(75%);
	}

	&:hover {
		background-color: $accent3;
	}
}

.el_btnTerm {
	padding-top: 8px;
	padding-right: 15px;
	padding-bottom: 8px;
	padding-left: 15px;
	border-radius: 4px;
	border: 1px solid currentColor;
	font-weight: bold;
	color: $gray4;

	&.is_active {
		color: $accent1;
		background-color: $accent3;
	}
}

// テーブル上部・下部のボタン
.el_btn {
	display: inline-block;
	padding: 8px 16px;
	border-radius: 4px;
	border: 1px solid $accent2;
	background-image: linear-gradient(180deg, $accent4 0%, $accent5 68%, $accent1 100%);
	text-align: center;
	font-weight: bold;
	font-size: 14px;
	color: #FFF;

	&:hover {
		background-image: linear-gradient(180deg, $accent2 0%, $accent6, $accent7 100%);
	}

	&.el_btn__plus {
		position: relative;
		padding-left: 34px;

		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 16px;
			width: 16px;
			height: 16px;
			background-image: url(../img/cplus.svg);
			background-repeat: no-repeat;
			transform: translateY(-50%);
		}
	}
}

// 削除ボタン
.el_btnBlueInv {
	padding: 8px 16px;
	border-radius: 4px;
	border: 1px solid currentColor;
	background-color: #FFF;
	font-weight: bold;
	color: $main1;

	&:disabled {
		filter: grayscale(100%) contrast(75%);
	}

	&:hover {
		background-color: $main4;
	}
}

// ボタンの役割を持ったテキスト
.el_btnTxt {
	font-weight: bold;
	font-size: 12px;
	color: $accent1;

	&:hover {
		color: $accent2;
	}
}

.el_btnTxt.el_btnTxt__underLine {
	padding-bottom: 1px;
	border-bottom: 1px solid $accent1;
	line-height: 1;

	&:hover {
		border-bottom-color: $accent2;
	}
}

// サイドドロワー開閉ボタン
.el_navToggle {
	background-image: url(../img/menu.svg);
	background-repeat: no-repeat;
	background-position: center;
}

.el_linkIcon {
	background-repeat: no-repeat;

	&:hover {
		opacity: $hover_opacity;
	}
}

// 注文詳細の閉じるボタン
.el_closeBtn {
	position: relative;
	width: 19px;
	height: 19px;

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 26.87005px;
		height: 2px;
		background-color: #FFF;
		transform-origin: left;
	}

	&::before {
		transform: rotate(45deg) translateX(-50%) translateY(calc(-50% + 1px));
	}

	&::after {
		transform: rotate(-45deg) translateX(-50%) translateY(calc(-50% + 1px));
	}
}

.el_roundBtn {
	display: inline-block;
	min-width: 96px;
	padding: 8px 16px;
	border-radius: 20px;
	background-color: $gray5;
	line-height: 1.8;
	font-size: 16px;
	color: #FFF;

	&:hover {
		background-color: rgba($gray5, .8);
	}
}

.el_btnWideBorderInv {
	display: inline-block;
	width: 384px;
	max-width: 100%;
	padding: 16px;
	border-radius: 4px;
	border: 2px solid currentColor;
	text-align: center;
	font-weight: 700;
	font-size: 18px;
	color: $main1;
	transition: opacity .3s ease-out;

	&:hover {
		opacity: .7;
	}
}

// 矢印マークつきボタン
.el_btnWithMarkInv {
	display: flex;
	align-items: center;
	gap: 8px;
	width: 100%;
	padding: 19px 16px;
	border-radius: 4px;
	border: 2px solid currentColor;
	font-weight: 700;
	font-size: 14px;
	text-decoration: none !important;
	color: $main1;
	transition: opacity .3s ease-out;

	&::before {
		content: "";
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		display: block;
		background: url(../img/icon-arrow02.svg) no-repeat center/contain;
	}

	&:hover {
		opacity: .6;
	}
}

// 4. テーブル
// ================================================================================



// 5. 管理者バッジ
// ================================================================================

.el_admin {
	background-image: url(../img/medal.svg);
	background-repeat: no-repeat;
}

.bl_table__userTable {

	.bl_table_body {

		.el_admin {
			background-position: left 17px center;
		}
	}
}


// 6. ファイルドロップ
// ================================================================================

.el_fileDrop {
	padding: 40px 8px;
	border: 1px dashed $accent1;

	> p {
		text-align: center;
		font-weight: bold;
		color: $accent1;
	}
}

.el_fileDrop.is_drag {
	background-color: $accent3;
}


// 7. チェックマーク
// ================================================================================

// 四角にチェックマーク ☑
input[type="checkbox"].el_checkMark {
	appearance: none;
	width: 16px;
	height: 16px;
	border-radius: 1px;
	border: 2px solid $gray4;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;

	&:checked {
		border: none;
		background-image: url(../img/check-on.svg);
	}
}


// 8. ラジオ・チェックボックス
// ================================================================================




// 9. インプットフォーム
// ================================================================================

// エラー時のインプットフォーム
input.is_error {
	padding-left: 34px;
	border: 2px solid $error;
	background-image: url(../img/ng.svg);
	background-repeat: no-repeat;
	background-position: left 8px center;
	background-size: 18px;

	+ span {
		display: inline-block;
		margin-top: 8px;
		color: $error;
	}
}

.el_inlineSelectWrap {
	position: relative;
	margin: 0 8px;

		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			right: 13px;
			width: 10px;
			height: 6px;
			background-image: url(../img/down.svg);
			background-repeat: no-repeat;
			transform: translateY(-50%);
			pointer-events: none;
		}

	> [class*="el_inlineInput"] {
		margin: 0;
	}
}

select[class*="el_inlineInput"] {
	line-height: 1.9;
}

input.el_inlineInputL {
	@include inlineInput(96px);
}

input.el_inlineInputM {
	@include inlineInput(48px);
}

input.el_inlineInputS {
	@include inlineInput(32px);
}

input.el_inlineInput {
	@include inlineInput(auto);
}

.sp_block {
	> .el_inlineInputM {
		margin-left: 8px !important;
	}
}

.mobile_block {
	> .el_inlineInputL {
		margin-left: 8px !important;
	}
}


// 境界線無しselect
.el_selectBorderLess {
	padding: 8px 42px 8px 16px;
	border: none;
	font-weight: bold;
	cursor: pointer;

	> option {
		font-weight: 500;
	}

	&Wrap {
		position: relative;
		display: inline-block;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 16px;
			width: 10px;
			height: 6px;
			background-image: url(../img/down.svg);
			background-repeat: no-repeat;
			transform: translateY(-50%);
			pointer-events: none;
		}
	}
}


.el_toggleBtn {
	@include toggleBtn("送信", "除外");
}

.el_toggleBtn__onoff {
	@include toggleBtn("ON", "OFF");
}

.el_toggleBtn__set {
	@include toggleBtn("設定", "解除");
}

.el_toggleBtn__send {
	@include toggleBtnL("送信する", "送信しない");
}

.el_searchBtn {
	box-sizing: content-box;
	width: 14px;
	height: 14px;
	padding: 10px 8px;
	background-image: url(../img/list-search.svg);
	background-repeat: no-repeat;
	background-position: center;
}

.el_searchInputWrap {
	position: relative;

	.el_searchBtn {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
}


// 10. ログイン状態を保存
// ================================================================================

.el_keepLogin {
	display: block;
	text-align: center;
	cursor: pointer;

	> .el_checkMark {
		vertical-align: text-bottom;
	}

	> span {
		vertical-align: middle;
		color: $main1;
	}
}


// 12. グラフ
// ================================================================================

.el_graphLegend {
	width: 48px;
	margin-right: 8px;
	@include graphLegend;
	color: $gray4;
}

$colors:
	$graph_order1,
	$graph_order2,
	$graph_order3,
	$graph_order4,
	$graph_order5,
	$graph_order6,
	$graph_order7,
	$graph_order8,
	$graph_order9,
	$graph_order10;

@for $i from 1 through 10 {
	.el_graphLegend.color#{$i} {
		color: nth($colors, $i);
	}
}


// ================================================================================

.el_doughnutTooltip {
	position: absolute;
	top: 58%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;

	&_label {
		margin-bottom: -8px;
		white-space: nowrap;
		text-align: center;
		font-weight: bold;
		font-size: 0.72916vw;
		font-size: 14px;
	}

	&_value {
		text-align: center;
		font-weight: bold;
		font-size: 24px;
	}

	&.el_doughnutTooltip__topPage {
		top: 50%;

		> .el_doughnutTooltip_label {
			width: 26px;
			height: 24px;
			margin: 0 auto;
			background-size: 26px 24px;
			background-repeat: no-repeat;
			background-position: center;
		}

		&1 {
			color: $sub1;

			> .el_doughnutTooltip_label {
				background-image: url(../img/rank-1.svg);
			}
		}

		&2 {
			color: $sub2;

			> .el_doughnutTooltip_label {
				background-image: url(../img/rank-2.svg);
			}
		}

		&3 {
			color: $sub3;

			> .el_doughnutTooltip_label {
				background-image: url(../img/rank-3.svg);
			}
		}

		&4 {
			color: $sub5;

			> .el_doughnutTooltip_label {
				width: auto;
				height: auto;

				&::before {
					content: "その他";
					font-size: 14px;
				}
			}
		}
	}
}

@include mediaQuery(1660) {

	.el_doughnutTooltip {
		&_label {
			font-size: 0.9vw;
		}

		&_value {
			font-size: 1.47vw;
		}
	}
}

@include mediaQuery(1024) {

	.el_doughnutTooltip {
		&_label {
			font-size: 14px;
		}

		&_value {
			font-size: 24px;
		}
	}
}

@include mediaQuery(480) {

	.el_doughnutTooltip {

		&_label {
			font-size: 2.91666vw;
		}

		&_value {
			font-size: 5vw;
		}
	}
}

.el_horizontalBarTooltip {
	position: absolute;
	top: 46px;
	left: 8px;
	padding: 8px;
	border-radius: 6px;
	border: 1px solid $gray4;
	background-color: rgba(#FFF, .95);
	z-index: 30;
	pointer-events: none;

	> li {
		display: flex;
		position: relative;
		margin-bottom: 8px;
		padding-left: 26px;

		&:last-child {
			margin-bottom: 0;
		}

		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			transform: translateY(-50%);
		}

		$colors: $sub1, $sub2, $sub3, $sub4, $sub5, $sub6, $sub7;
		@for $i from 1 through 7 {

		&:nth-child(7n+#{$i}) {
			&::before {
				background-color: nth($colors, $i);
			}
		}
	}

		> *:first-child {
			flex: 1 0 auto;
		}

		> *:last-child {
			flex: 0 0 auto;
		}
	}
}

.el_mixedTooltip {
	position: absolute;
	top: 46px;
	left: 8px;
	padding: 8px;
	border-radius: 6px;
	border: 1px solid $gray4;
	background-color: rgba(#FFF, .95);
	z-index: 30;
	pointer-events: none;

	> li {
		display: flex;
		position: relative;
		margin-bottom: 8px;
		padding-left: 26px;

		&:last-child {
			margin-bottom: 0;
		}

		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			width: 18px;
			height: 18px;
			transform: translateY(-50%);
		}
	}

	> .el_mixedTooltip_lineChart {
		&::before {
			border-radius: 50%;
		}
	}

	> .el_mixedTooltip_bar1 {
		&::before {
			background-color: $legend1;
		}
	}

	> .el_mixedTooltip_bar2 {
		&::before {
			background-color: $legend2;
		}
	}

	> .el_mixedTooltip_line1 {
		&::before {
			background-color: $legend3;
		}
	}

	> .el_mixedTooltip_line2 {
		&::before {
			background-color: $legend4;
		}
	}

	> .el_mixedTooltip_line3 {
		&::before {
			background-color: $legend5;
		}
	}

}

.el_lineTooltip {
	position: absolute;
	top: 80px;
	left: 8px;
	padding: 8px;
	border-radius: 6px;
	border: 1px solid $gray4;
	background-color: rgba(#FFF, .95);
	white-space: nowrap;
	font-weight: bold;
	font-size: 14px;
	transform: translate(-50%, -50%);
	pointer-events: none;

	&_title {
		margin-bottom: 8px;
	}

	&_list {
		> li {
			position: relative;
			margin-bottom: 8px;
			padding-left: 26px;
			text-align: right;

			&:last-child {
				margin-bottom: 0;
			}

			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 0;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				background-color: currentColor;
				transform: translateY(-50%);

				@at-root {
					.bl_canvasWrap__singleLineChart {
						.el_lineTooltip_list > li {
							padding-left: 0;

							&::before {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	&_label {
		margin-left: 8px;
	}
}

.el_barChartLegend {
	position: absolute;
	font-weight: bold;
	font-size: clamp(10px, 0.9vw, 18px);
	color: #FFF;
	transform: translateX(-50%);
}

@include mediaQuery($MOBILE) {
	.el_barChartLegend {
		font-size: clamp(10px, 2.1vw, 16px);
	}
}

@include mediaQuery($SP) {
	.el_barChartLegend {
		font-size: 4vw;
	}
}

.el_horizontalBarChartLegend {
	&Wrap {
		padding-top: 16px;
	}

	position: relative;
	display: inline-block;
	margin-right: 16px;
	margin-bottom: 8px;
	padding-left: 24px;

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		transform: translateY(-50%);
	}

	$colors: $sub1, $sub2, $sub3, $sub4, $sub5, $sub6, $sub7;
	@for $i from 1 through 7 {

		&:nth-child(7n+#{$i}) {
			&::before {
				background-color: nth($colors, $i);
			}
		}
	}
}


// 13. 外部リンク
// ================================================================================

.el_externalLink > a::after {
	display: inline-block;
	content: "";
	height: 14px;
	width: 14px;
	background-image: url(../img/icon-window-blue.svg);
	background-size: contain;
	vertical-align: middle;
}