// インラインの入力フォーム
@mixin inlineInput( $width: 100% ) {
	margin-right: 8px;
	margin-left: 8px;
	padding: 1px 8px;
	width: $width;
	border: 1px solid $gray2;
	text-align: right;
	vertical-align: baseline;
	line-height: 1;

	&.textL {
		text-align: left;
	}

	&::placeholder {
		color: $gray4;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		appearance: none;
	}

	&:first-child {
		margin-left: 0;
	}
}

// grow, shrink, width, head_align, body_align
// テーブルごとの列幅調整用
@mixin tableColumnStyle($settings) {

	@for $i from 1 through length($settings) {

		$set: nth($settings, $i);

		$grow: nth($set, 1);
		$shrink: nth($set, 2);
		$width: nth($set, 3);
		$head_align: nth($set, 4);
		$body_align: nth($set, 5);

		th:nth-child(#{$i}),
		td:nth-child(#{$i}) {
			flex: $grow $shrink $width;

			@if $shrink > 0 {
				min-width: calc($width / 3);
			}
		}

		th:nth-child(#{$i}) {
			text-align: $head_align;
		}

		td:nth-child(#{$i}) {
			justify-content: $body_align;
		}
	}
}

// スクロールバー
@mixin scrollBar($margin-top: 68) {

	&::-webkit-scrollbar {
		appearance: none;
		width: 12px;
		height: 12px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 6px;
		background-color: $main1;
	}

	&::-webkit-scrollbar-track {
		margin-top: #{$margin-top}px;
		border-radius: 6px;
		background-color: $gray3;
	}
}

@mixin drawerMarker( $top ) {
	&::after {
		content: "";
		display: inline-block;
		position: absolute;
		top: #{$top}px;
		right: 0;
		width: 4px;
		height: 50px;
		background-color: #FFF;
		opacity: 0;
	}

	&:hover {
		background-color: $sub1;
		transition: $drawer_duration;

		&::after {
			opacity: 1;
			transition: $drawer_duration;
		}
	}
}

// 色分け用の丸
@mixin analysisTableMark {
	tbody td:first-child {
		position: relative;
		padding-left: 36px;
		font-weight: bold;

		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 8px;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			transform: translateY(-50%);
		}
	}
}

// グラフへ順番に色を割り当てる
@mixin setAnalysisColors( $colors, $through ) {
	@for $i from 1 through $through {
		tbody tr:nth-child( #{$i} ) td:first-child::before {
			background-color: nth( $colors, $i );
		}
	}
}

@mixin setBarGraphColors( $colors, $through ) {
	@for $i from 1 through $through {
		&:nth-child( #{$i} )::before{
			background-color: nth( $colors, $i );
		}
	}
}

@mixin mediaQuery($width: $SP) {
	@media screen and (max-width: #{$width}px) {
		@content;
	}
}

@mixin mediaQueryHigher($width: $SP) {
	@media screen and (min-width: #{$width + 1}px) {
		@content;
	}
}

// 折れ線グラフの凡例
@mixin graphLegend($color:null) {

	position: relative;
	color: $color;

	&::before,
	&::after {
		display: inline-block;
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		background-color: currentColor;
		transform: translate(-50%, -50%);
	}

	&::before {
		width: 100%;
		height: 1px;
	}

	&::after {
		width: 8px;
		height: 8px;
		border-radius: 50%;
	}
}

@mixin toggleBtn($on, $off) {
	position: relative;
	display: block;
	width: 68px;
	height: 32px;
	overflow: hidden;
	cursor: pointer;

	> input[type="checkbox"] {
		appearance: none;
		width: 68px;
		height: 32px;
		border-radius: 16px;
		border: 1px solid $gray4;
		background-color: #FFF;
		background-image: none !important;

		+ span {
			position: absolute;
			top: 2px;
			left: 2px;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			background-color: $gray4;
			transition-duration: $toggleBtn_duration;

			&::before,
			&::after {
				position: absolute;
				top: 50%;
				white-space: nowrap;
				font-weight: bold;
				font-size: 12px;
				transform: translateY(-50%);
				transition-duration: $toggleBtn_duration;
			}

			&::before {
				content: $on;
				left: 8px;
				color: #FFF;
				opacity: 0;
			}

			&::after {
				content: $off;
				right: -28px;
				color: $gray4;
				opacity: 1;
			}
		}
	}

	> input[type="checkbox"]:checked {
		border-color: transparent;
		background-color: $accent1;

		+ span {
			left: 38px;
			background-color: #FFF;

			&::before {
				left: -28px;
				opacity: 1;
			}

			&::after {
				right: 8px;
				opacity: 0;
			}
		}
	}

	&.el_toggleBtn__onoff {

		> input[type="checkbox"] {

			+ span {

				&::before {
					content: "ON";
				}

				&::after {
					content: "OFF";
				}
			}
		}
	}
}

@mixin toggleBtnL($on, $off) {
	position: relative;
	display: block;
	width: 98px;
	height: 32px;
	overflow: hidden;
	cursor: pointer;

	> input[type="checkbox"] {
		appearance: none;
		width: 98px;
		height: 32px;
		border-radius: 16px;
		border: 1px solid $gray4;
		background-color: #FFF;
		background-image: none !important;

		+ span {
			position: absolute;
			top: 2px;
			left: 2px;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			background-color: $gray4;
			transition-duration: $toggleBtn_duration;

			&::before,
			&::after {
				position: absolute;
				top: 50%;
				white-space: nowrap;
				font-weight: bold;
				font-size: 12px;
				transform: translateY(-50%);
				transition-duration: $toggleBtn_duration;
			}

			&::before {
				content: $on;
				left: 8px;
				color: #FFF;
				opacity: 0;
			}

			&::after {
				content: $off;
				right: -62px;
				color: $gray4;
				opacity: 1;
			}
		}
	}

	> input[type="checkbox"]:checked {
		border-color: transparent;
		background-color: $accent1;

		+ span {
			left: 68px;
			background-color: #FFF;

			&::before {
				left: -55px;
				opacity: 1;
			}

			&::after {
				right: 8px;
				opacity: 0;
			}
		}
	}

	&.el_toggleBtn__onoff {

		> input[type="checkbox"] {

			+ span {

				&::before {
					content: "ON";
				}

				&::after {
					content: "OFF";
				}
			}
		}
	}
}