* {
  box-sizing: border-box;
}

:before, :after {
  box-sizing: inherit;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

p, table, blockquote, address, pre, iframe, form, figure, dl {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  clear: both;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  margin: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: inherit;
}

address {
  font-style: inherit;
}

a {
  color: inherit;
  background-color: #0000;
  text-decoration: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: inherit;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  vertical-align: bottom;
  width: 100%;
  border-style: none;
}

embed, object, iframe {
  vertical-align: bottom;
  border: 0;
}

button, input, optgroup, select, textarea {
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  text-align: inherit;
  background: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  margin: 0;
  padding: 0;
}

[type="checkbox"] {
  appearance: checkbox;
}

[type="radio"] {
  appearance: radio;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  cursor: pointer;
  appearance: none;
}

button[disabled], [type="button"][disabled], [type="reset"][disabled], [type="submit"][disabled] {
  cursor: default;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

option {
  padding: 0;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

label[for] {
  cursor: pointer;
}

details {
  display: block;
}

summary {
  display: list-item;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  text-align: left;
}

td, th {
  vertical-align: top;
  padding: 0;
}

th {
  text-align: left;
  font-weight: bold;
}

template, [hidden] {
  display: none;
}

html {
  font-size: 14px;
}

body {
  color: #242424;
  font-family: Yu Gothic;
  font-weight: 500;
}

p {
  line-height: 1.9;
}

label {
  cursor: pointer;
}

input {
  background-color: #fff;
}

input:disabled {
  filter: grayscale() contrast(75%);
}

input[type="text"], input[type="password"], input[type="email"], input[type="url"], input[type="number"] {
  width: 100%;
  border: 1px solid #e6e6e6;
  padding: 8px;
  line-height: 1;
}

input[type="text"]::placeholder, input[type="password"]::placeholder, input[type="email"]::placeholder, input[type="url"]::placeholder, input[type="number"]::placeholder {
  color: #b3b3b3;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="radio"], input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #b3b3b3;
  border-radius: 50%;
  margin-right: 8px;
}

input[type="radio"]:checked, input[type="checkbox"]:checked {
  background-image: url("radio-on.0a8c6eb7.svg");
  border: none;
}

textarea {
  width: 100%;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  padding: 8px;
  line-height: 1.9;
}

textarea::-webkit-scrollbar {
  appearance: none;
  width: 12px;
  height: 12px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #3f81cb;
  border-radius: 6px;
}

textarea::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 6px;
  margin-top: 0;
}

textarea:disabled {
  filter: grayscale() contrast(75%);
}

textarea::placeholder {
  color: #b3b3b3;
}

select {
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  padding: 8px 34px 8px 8px;
  line-height: 1;
  position: relative;
}

.el_selectWrap {
  position: relative;
}

.el_selectWrap:after {
  content: "";
  width: 10px;
  height: 6px;
  pointer-events: none;
  background-image: url("down.e302d382.svg");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
}

.el_selectWrap > select {
  min-width: 48px;
}

select:disabled {
  filter: grayscale() contrast(75%);
}

canvas {
  max-width: 100%;
}

.ly_header {
  height: 64px;
  justify-content: space-between;
  padding: 0 16px;
  display: flex;
}

.ly_header_inner {
  align-items: center;
  display: flex;
}

.ly_header_inner__ttl {
  flex: 0 3360px;
}

.ly_header_inner__ttl .el_navToggle {
  width: 24px;
  height: 21px;
  flex: 0 0 24px;
  margin-right: 24px;
}

.ly_header_inner__ttl .el_systemName {
  flex: 0 0 217px;
}

.ly_header_inner__ttl .el_systemName picture, .ly_header_inner__ttl .el_systemName img {
  width: 100%;
}

.ly_header_inner__ttl .el_links {
  height: 48px;
  margin-left: auto;
}

.ly_header_inner__ttl .el_links .el_linkIcon__guide {
  box-sizing: content-box;
  width: 24px;
  height: 24px;
  background-image: url("icon-q.0fca75dc.svg");
  background-position: center;
  flex: 0 0 24px;
  margin-left: auto;
  padding: 4px 16px;
  display: block;
}

.ly_header_inner__ttl .el_links .el_linkIcon__guideL {
  width: 188px;
  height: 48px;
  text-align: center;
  background: #3f81cb no-repeat padding-box padding-box;
  border-radius: 48px;
  margin: 0 4px;
  display: inline-block;
}

.ly_header_inner__ttl .el_links .el_linkIcon__guideL > span {
  vertical-align: middle;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 48px;
}

.ly_header_inner__ttl .el_links .el_linkIcon__guideL > span:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("q-beginner-s.14f81b8e.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  top: 6px;
  left: -8px;
}

.ly_header_inner__ttl .el_links .el_linkIcon__guideL > span .br {
  display: none;
}

.ly_header_inner__ttl .el_links .el_linkIcon__contactL {
  width: 188px;
  height: 48px;
  text-align: center;
  background: #3f81cb no-repeat padding-box padding-box;
  border-radius: 48px;
  margin: 0 4px;
  display: inline-block;
}

.ly_header_inner__ttl .el_links .el_linkIcon__contactL > span {
  vertical-align: middle;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 48px;
}

.ly_header_inner__ttl .el_links .el_linkIcon__contactL > span:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("icon-mail.cb9f4fd2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  top: 6px;
  left: -8px;
}

.ly_header_inner__ttl .el_links .el_linkIcon__contactL > span .br {
  display: none;
}

.ly_header_inner__ttl .el_links .el_linkIcon__faqL {
  width: 188px;
  height: 48px;
  text-align: center;
  background: #3f81cb no-repeat padding-box padding-box;
  border-radius: 48px;
  margin: 0 4px;
  display: inline-block;
}

.ly_header_inner__ttl .el_links .el_linkIcon__faqL > span {
  vertical-align: middle;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 48px;
}

.ly_header_inner__ttl .el_links .el_linkIcon__faqL > span:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("icon-q-w.d7a24ba5.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  top: 6px;
  left: -8px;
}

.ly_header_inner__ttl .el_links .el_linkIcon__faqL > span .br {
  display: none;
}

.ly_header_inner__menu {
  flex: 0 480px;
}

@media screen and (max-width: 1024px) {
  .ly_header {
    height: auto;
    z-index: 20;
    flex-direction: column;
    padding: 0;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0 0 2px #00000029;
  }

  .ly_header_inner {
    flex: none;
  }

  .ly_header_inner__ttl {
    z-index: 30;
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    justify-content: space-between;
    padding: 4px 4px 4px 24px;
  }

  .ly_header_inner__ttl .el_navToggle {
    width: 27px;
    margin-right: 0;
  }

  .ly_header_inner__ttl .el_systemName {
    flex: 0 217px;
    margin-left: 16px;
  }

  .ly_header_inner__ttl .el_links {
    height: 56px;
    margin-left: 0;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__guide {
    background-image: url("icon-q-w.d7a24ba5.svg");
    margin-left: 0;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__guideL {
    width: 61px;
    height: 56px;
    text-align: center;
    background: #3f81cb no-repeat padding-box padding-box;
    border-radius: 2px;
    margin: 0 4px;
    padding-top: 4px;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__guideL > span {
    vertical-align: middle;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    line-height: 0;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__guideL > span:before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url("q-beginner-s.14f81b8e.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__guideL > span .br {
    display: inline;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__contactL {
    width: 61px;
    height: 56px;
    text-align: center;
    background: #3f81cb no-repeat padding-box padding-box;
    border-radius: 2px;
    margin: 0 4px;
    padding-top: 4px;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__contactL > span {
    vertical-align: middle;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    line-height: 0;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__contactL > span:before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url("icon-mail.cb9f4fd2.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__contactL > span .br {
    display: inline;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__faqL {
    width: 61px;
    height: 56px;
    text-align: center;
    background: #3f81cb no-repeat padding-box padding-box;
    border-radius: 2px;
    margin: 0 4px;
    padding-top: 4px;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__faqL > span {
    vertical-align: middle;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    line-height: 0;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__faqL > span:before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url("icon-q-w.d7a24ba5.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
  }

  .ly_header_inner__ttl .el_links .el_linkIcon__faqL > span .br {
    display: inline;
  }

  .ly_header_inner__menu {
    z-index: 10;
    background-color: #fff;
    justify-content: space-between;
    padding: 0 24px;
  }
}

.ly_body {
  min-height: calc(100vh - 64px);
  display: flex;
}

.ly_side {
  z-index: 10;
  background-color: #172536;
  flex: none;
  order: 1;
}

@media screen and (max-width: 1024px) {
  .ly_side {
    z-index: 30;
    position: fixed;
    top: 64px;
    left: 0;
  }
}

.ly_main {
  z-index: 5;
  background-color: #edf1f2;
  flex: auto;
  order: 2;
}

.ly_main .ly_main_error {
  text-align: center;
  color: #fff;
  background-color: #e83131;
  padding: 20px 0;
  font-weight: bold;
  line-height: 1.8;
}

.ly_main .ly_main_error h2 {
  font-size: 18px;
}

.ly_main .ly_main_content {
  padding: 48px;
}

@media screen and (max-width: 1024px) {
  .ly_main {
    overflow-x: hidden;
  }

  .ly_main .ly_main_content, .ly_main .ly_main_error {
    padding: 24px;
  }
}

.ly_login {
  min-height: 100vh;
  display: flex;
}

.ly_entry {
  background-color: #3f81cb;
  flex: 0 1200px;
  justify-content: space-around;
  align-items: center;
  padding: 0 32px;
  display: flex;
}

.ly_loginForm {
  flex: 0 720px;
  justify-content: space-around;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;
}

.ly_loginForm_inner {
  width: 100%;
}

.ly_reissue {
  min-height: 100vh;
  background-image: linear-gradient(#3f81cb 0%, #9ec3ec 100%);
  justify-content: space-around;
  align-items: center;
  padding: 0 32px;
  display: flex;
}

.ly_reissue .el_entryTitle {
  text-align: center;
  margin-bottom: 32px;
}

@media screen and (max-width: 1024px) {
  .ly_login {
    background-color: #3f81cb;
    flex-direction: column;
    justify-content: center;
  }

  .ly_entry {
    flex: none;
    padding: 0 40px;
  }

  .ly_entry .el_entryTitle > img {
    width: 100%;
  }

  .ly_loginForm {
    flex: none;
    padding: 8px 24px 24px;
  }

  .ly_loginForm_inner {
    background-color: none;
    border-radius: 6px;
    padding: 24px;
  }

  .ly_loginForm_inner > div {
    background-color: #fff;
    border-radius: 6px;
    padding: 24px;
  }

  .ly_reissue {
    align-items: center;
    padding: 24px;
  }
}

@media screen and (max-width: 480px) {
  .ly_login {
    padding: 24px 0;
  }

  .ly_reissue_inner > .el_entryTitle > img {
    width: 100%;
  }
}

.bl_row {
  flex-wrap: wrap;
  margin-bottom: 48px;
  display: flex;
}

.bl_row > .bl_col {
  padding: 0 24px;
}

@media screen and (max-width: 480px) {
  .bl_row {
    flex-direction: column;
    margin: 0;
  }

  .bl_row > .bl_col {
    padding: 0;
  }

  .ly_main > .bl_row:last-child {
    margin-bottom: -24px;
  }
}

.ly_main > .bl_row > .bl_col, .ly_main > div > .bl_row > .bl_col {
  box-sizing: content-box;
}

.ly_main > .bl_row > .bl_col.bl_col__8:only-child, .ly_main > div > .bl_row > .bl_col.bl_col__8:only-child {
  width: 100%;
  flex: 0 1040px;
}

.ly_main > .bl_row > .bl_col.bl_col__6:only-child, .ly_main > div > .bl_row > .bl_col.bl_col__6:only-child {
  width: 100%;
  flex: 0 768px;
}

.ly_main > .bl_row > .bl_col.bl_col__4:only-child, .ly_main > div > .bl_row > .bl_col.bl_col__4:only-child {
  width: 100%;
  flex: 0 496px;
}

.ly_main > .bl_row > .bl_col.bl_col__3:only-child, .ly_main > div > .bl_row > .bl_col.bl_col__3:only-child {
  width: 100%;
  flex: 0 360px;
}

.bl_col.bl_col__12 {
  width: 100%;
  max-width: 1584px;
}

.bl_col.bl_col__9 {
  width: 76.7677%;
  max-width: 1216px;
}

.bl_col.bl_col__8 {
  width: 66.6667%;
  width: 61.9048%;
  max-width: 1040px;
}

.bl_col.bl_col__6 {
  width: 50%;
  width: 45.7143%;
  max-width: 768px;
  flex: auto;
}

.bl_col.bl_col__4 {
  width: 33.3333%;
  width: 29.5238%;
  max-width: 496px;
  flex: auto;
}

.bl_col.bl_col__3 {
  width: 25%;
  width: 21.4286%;
  max-width: 360px;
}

.bl_col:first-child {
  padding-left: 0;
}

.bl_col:last-child {
  padding-right: 0;
}

@media screen and (max-width: 1024px) {
  .bl_col.bl_col__mobileFullWidth {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .bl_col.bl_col__12, .bl_col.bl_col__9, .bl_col.bl_col__8, .bl_col.bl_col__6, .bl_col.bl_col__4, .bl_col.bl_col__3 {
    width: 100%;
    max-width: 100%;
  }
}

.bl_col > .bl_row > .bl_col:first-child {
  padding-left: 0;
}

.bl_col > .bl_row > .bl_col:last-child {
  padding-right: 0;
}

@media screen and (max-width: 1024px) {
  .ly_main__top > .bl_row:nth-child(2) {
    flex-wrap: nowrap;
  }

  .ly_main__top > .bl_row:nth-child(2) > .bl_col {
    flex: auto;
  }

  .ly_main__top > .bl_row:nth-child(2) > .bl_col:nth-child(2) > .bl_row {
    flex-direction: column;
  }

  .ly_main__top > .bl_row:nth-child(2) > .bl_col:nth-child(2) > .bl_row > .bl_col {
    width: 100%;
    flex: 0 auto;
    padding: 0;
  }

  .ly_main__top > .bl_row:nth-child(2) > .bl_col:nth-child(2) > .bl_row > .bl_col:first-child {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 480px) {
  .ly_main__top > .bl_row:nth-child(2) > .bl_col:nth-child(2) > .bl_row > .bl_col:first-child {
    margin-bottom: 0;
  }

  .ly_main__top > .bl_row:nth-child(2) > .bl_col:nth-child(2) > .bl_row > .bl_col:first-child > .bl_panel {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 1024px) {
  .ly_main__acquisition > .bl_row {
    flex-direction: column;
  }

  .ly_main__acquisition > .bl_row > .bl_col {
    flex: none !important;
  }

  .ly_main__acquisition > .bl_row > .bl_col > .bl_row {
    flex-direction: column;
  }

  .ly_main__acquisition > .bl_row > .bl_col > .bl_row:last-child {
    margin-bottom: 0;
  }

  .ly_main__acquisition > .bl_row > .bl_col > .bl_row > .bl_col {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }

  .ly_main__acquisition > .bl_row > .bl_col > .bl_row > .bl_col:last-child > .bl_panel {
    margin-bottom: 0;
  }

  .ly_main__acquisition > .bl_row > .bl_col > .bl_row > .bl_col > .bl_panel {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 480px) {
  .ly_main__acquisition > .bl_row > .bl_col > .bl_row > .bl_col > .bl_panel {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 1200px) {
  .ly_main__reviewStatus > .bl_row:nth-child(4) {
    flex-direction: column;
  }

  .ly_main__reviewStatus > .bl_row:nth-child(4) > .bl_col {
    max-width: 100%;
    width: 100%;
    margin-bottom: 48px;
    padding: 0;
    flex: none !important;
  }

  .ly_main__reviewStatus > .bl_row:nth-child(4) > .bl_col:first-child .bl_reviewData {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: -16px;
  }

  .ly_main__reviewStatus > .bl_row:nth-child(4) > .bl_col:first-child .bl_reviewData_item {
    flex: 1 0 auto;
    margin-bottom: 16px;
  }

  .ly_main__reviewStatus > .bl_row:nth-child(4) > .bl_col:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .ly_main__reviewStatus > .bl_row:nth-child(4) > .bl_col {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 960px) {
  .ly_main__exclusion > .bl_row {
    flex-direction: column;
  }

  .ly_main__exclusion > .bl_row > .bl_col {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }

  .ly_main__exclusion > .bl_row > .bl_col:first-child {
    margin-bottom: 48px;
  }

  .bl_table.bl_table.bl_table__headBorderBottom th:nth-child(1), .bl_table.bl_table.bl_table__headBorderBottom td:nth-child(1) {
    min-width: 244px;
    flex: 0 732px;
  }

  .bl_table.bl_table.bl_table__headBorderBottom th:nth-child(1) {
    text-align: center;
  }

  .bl_table.bl_table.bl_table__headBorderBottom td:nth-child(1) {
    justify-content: flex-start;
  }

  .bl_table.bl_table.bl_table__headBorderBottom th:nth-child(2), .bl_table.bl_table.bl_table__headBorderBottom td:nth-child(2) {
    flex: 0 0 110px;
  }

  .bl_table.bl_table.bl_table__headBorderBottom th:nth-child(2) {
    text-align: center;
  }

  .bl_table.bl_table.bl_table__headBorderBottom td:nth-child(2) {
    justify-content: center;
  }

  .bl_table.bl_table.bl_table__systemAlert th:nth-child(1), .bl_table.bl_table.bl_table__systemAlert td:nth-child(1) {
    min-width: 244px;
    flex: 0 732px;
  }

  .bl_table.bl_table.bl_table__systemAlert th:nth-child(1) {
    text-align: center;
  }

  .bl_table.bl_table.bl_table__systemAlert td:nth-child(1) {
    justify-content: flex-start;
  }

  .bl_table.bl_table.bl_table__systemAlert th:nth-child(2), .bl_table.bl_table.bl_table__systemAlert td:nth-child(2) {
    flex: 0 0 110px;
  }

  .bl_table.bl_table.bl_table__systemAlert th:nth-child(2) {
    text-align: center;
  }

  .bl_table.bl_table.bl_table__systemAlert td:nth-child(2) {
    justify-content: center;
  }

  .bl_table.bl_table.bl_table__plan th:nth-child(1), .bl_table.bl_table.bl_table__plan td:nth-child(1) {
    flex: 0 0 200px;
  }

  .bl_table.bl_table.bl_table__plan th:nth-child(1) {
    text-align: center;
  }

  .bl_table.bl_table.bl_table__plan td:nth-child(1) {
    justify-content: flex-start;
  }

  .bl_table.bl_table.bl_table__plan th:nth-child(2), .bl_table.bl_table.bl_table__plan td:nth-child(2) {
    min-width: 166.667px;
    flex: 0 500px;
  }

  .bl_table.bl_table.bl_table__plan th:nth-child(2) {
    text-align: left;
  }

  .bl_table.bl_table.bl_table__plan td:nth-child(2) {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 480px) {
  .ly_main__exclusion > .bl_row > .bl_col:first-child {
    margin-bottom: 0;
  }

  .bl_table.bl_table.bl_table__headBorderBottom th:nth-child(1), .bl_table.bl_table.bl_table__headBorderBottom td:nth-child(1) {
    min-width: 244px;
    flex: 0 732px;
  }

  .bl_table.bl_table.bl_table__headBorderBottom th:nth-child(1) {
    text-align: center;
  }

  .bl_table.bl_table.bl_table__headBorderBottom td:nth-child(1) {
    justify-content: flex-start;
  }

  .bl_table.bl_table.bl_table__headBorderBottom th:nth-child(2), .bl_table.bl_table.bl_table__headBorderBottom td:nth-child(2) {
    flex: 0 0 110px;
  }

  .bl_table.bl_table.bl_table__headBorderBottom th:nth-child(2) {
    text-align: center;
  }

  .bl_table.bl_table.bl_table__headBorderBottom td:nth-child(2) {
    justify-content: center;
  }

  .bl_table.bl_table.bl_table__systemAlert th:nth-child(1), .bl_table.bl_table.bl_table__systemAlert td:nth-child(1) {
    min-width: 244px;
    flex: 0 732px;
  }

  .bl_table.bl_table.bl_table__systemAlert th:nth-child(1) {
    text-align: center;
  }

  .bl_table.bl_table.bl_table__systemAlert td:nth-child(1) {
    justify-content: flex-start;
  }

  .bl_table.bl_table.bl_table__systemAlert th:nth-child(2), .bl_table.bl_table.bl_table__systemAlert td:nth-child(2) {
    flex: 0 0 110px;
  }

  .bl_table.bl_table.bl_table__systemAlert th:nth-child(2) {
    text-align: center;
  }

  .bl_table.bl_table.bl_table__systemAlert td:nth-child(2) {
    justify-content: center;
  }

  .bl_table.bl_table.bl_table__plan th:nth-child(1), .bl_table.bl_table.bl_table__plan td:nth-child(1) {
    flex: 0 0 200px;
  }

  .bl_table.bl_table.bl_table__plan th:nth-child(1) {
    text-align: center;
  }

  .bl_table.bl_table.bl_table__plan td:nth-child(1) {
    justify-content: flex-start;
  }

  .bl_table.bl_table.bl_table__plan th:nth-child(2), .bl_table.bl_table.bl_table__plan td:nth-child(2) {
    min-width: 166.667px;
    flex: 0 500px;
  }

  .bl_table.bl_table.bl_table__plan th:nth-child(2) {
    text-align: left;
  }

  .bl_table.bl_table.bl_table__plan td:nth-child(2) {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 960px) {
  .ly_main__rakutenSetting .bl_row {
    flex-direction: column;
  }

  .ly_main__rakutenSetting .bl_row > .bl_col {
    max-width: 100%;
    width: 100%;
    margin-bottom: 48px;
    padding: 0;
  }

  .ly_main__rakutenSetting .bl_row > .bl_col:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .ly_main__rakutenSetting .bl_row > .bl_col {
    margin-bottom: 0;
  }
}

.ly_main__guide .bl_row {
  flex-wrap: nowrap;
}

.ly_main__guide .bl_row > .bl_col {
  padding: 0;
}

.ly_main__guide .bl_row > .bl_col.bl_col__3 {
  max-width: 320px;
  flex: 0 0 320px;
  margin-right: 48px;
}

.ly_main__guide .bl_row > .bl_col.bl_col__9 {
  flex: 1216px;
}

@media screen and (max-width: 1200px) {
  .ly_main__guide .bl_row {
    flex-direction: column;
  }

  .ly_main__guide .bl_row > .bl_col.bl_col__3 {
    width: 100%;
    max-width: 100%;
    order: 1000;
    padding: 0;
  }

  .ly_main__guide .bl_row > .bl_col.bl_col__3 > .bl_panel {
    padding: 0 0 24px;
  }

  .ly_main__guide .bl_row > .bl_col.bl_col__9 {
    width: 100%;
    margin-bottom: 48px;
    padding: 0;
  }
}

.bl_panel_row {
  margin-bottom: 32px;
}

.bl_panel_row.bl_panel_row__indent {
  padding: 0 16px;
}

.bl_panel_row.bl_panel_row__disabled {
  color: #b3b3b3;
}

.bl_panel_row > p {
  margin-bottom: 8px;
}

.bl_panel_row > p > input[type="radio"]:not(:first-child) {
  margin-left: 24px;
}

.bl_panel_inputRow {
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
  padding-left: 0;
  display: flex;
}

.bl_panel_inputRow:last-child {
  margin-bottom: 24px;
}

.bl_panel_inputRow > * {
  margin-bottom: 8px;
}

.bl_panel_inputRow > .el_btnTxt:last-child {
  margin-left: 16px;
}

.bl_panel_indent {
  padding: 0 16px;
}

.bl_panel {
  background-color: #fff;
  border-radius: 6px;
  padding: 24px;
  position: relative;
  box-shadow: 1px 1px 4px #0000000d;
}

.bl_panel > :last-child {
  margin-bottom: 0 !important;
}

.bl_col .bl_row .bl_panel {
  height: 100%;
}

.bl_panel .el_linkIcon__guide {
  height: 24px;
  width: 24px;
  background-image: url("icon-q-w.d7a24ba5.svg");
  margin-left: 0;
  position: absolute;
  top: 16px;
  right: 16px;
}

.bl_panel .el_linkIcon__guideF {
  height: 16px;
  width: 16px;
  background-image: url("icon-q.0fca75dc.svg");
  background-size: contain;
  margin-left: 6px;
  display: inline-block;
}

@media screen and (max-width: 480px) {
  .bl_panel {
    margin-bottom: 24px;
  }
}

.bl_panel.bl_panel__bt {
  border-top: 4px solid #3f81cb;
  border-radius: 0 0 6px 6px;
}

.bl_panel.bl_panel__bt.bl_panel__guide_menu {
  max-height: calc(100vh - 160px);
  position: sticky;
  top: 48px;
  overflow-y: auto;
}

.bl_panel.bl_panel__ttlInv {
  padding-top: 0;
}

.bl_panel.bl_panel__ttlInv .el_lv2Heading:first-child {
  color: #fff;
  background-color: #3f81cb;
  border-radius: 6px 6px 0 0;
  margin-bottom: 24px;
  margin-left: -24px;
  margin-right: -24px;
  padding: 16px 24px;
  line-height: 1.4;
}

.bl_panel.bl_panel__warning {
  border: 2px solid #e83131;
  border-radius: 6px;
}

.bl_panel.bl_panel__warning .el_lv2Heading:before {
  vertical-align: middle;
  white-space: pre-wrap;
  content: url("icon-attention.15de58b9.svg") " ";
}

.bl_panel.bl_panel__warning .el_lv2Heading:first-child {
  color: #e83131;
  line-height: 1.4;
}

@media screen and (max-width: 1024px) {
  .bl_panel.bl_panel__warning .el_lv2Heading {
    text-align: center;
  }

  .bl_panel.bl_panel__warning .el_lv2Heading:before {
    white-space: pre;
    content: url("icon-attention.15de58b9.svg") "\a ";
  }
}

.bl_panel_innerCheck {
  padding: 0 26px;
}

.bl_label {
  align-items: flex-start;
  margin-right: 16px;
  line-height: 2.4;
  display: inline-flex;
}

.bl_label:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.bl_label > input {
  flex: none;
  margin-top: 8px;
}

.bl_label > .bl_inputFormWrap {
  margin-bottom: 8px !important;
}

.bl_label.bl_label__termWrap {
  align-items: flex-start;
}

.bl_label.bl_label__termWrap > input {
  flex: none;
  margin-top: 8px;
}

.bl_inputFormWrap {
  flex-wrap: wrap;
  align-items: center;
  line-height: 2.4;
  display: flex;
}

.bl_inputFormWrap:last-child {
  margin-bottom: 0;
}

.bl_inputFormWrap > .el_btnTxt {
  margin-left: 16px;
}

.datePickerPopper {
  line-height: normal;
}

.bl_panelTab {
  display: flex;
}

.bl_panelTab_item {
  color: #b3b3b3;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid;
  border-radius: 6px 6px 0 0;
  margin-right: 8px;
  padding: 16px 24px;
  font-size: 18px;
  font-weight: bold;
}

.bl_panelTab_item.is_active {
  color: #fff;
  background-color: #3f81cb;
  border-color: #0000;
}

@media screen and (max-width: 480px) {
  .bl_panelTab_item {
    padding: 16px;
  }
}

.bl_panel_headerFooter {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -24px;
  padding-bottom: 24px;
  display: flex;
}

.bl_panel_headerFooter > * {
  margin-bottom: 24px;
  margin-right: 16px;
}

.bl_panel_headerFooter:last-child {
  margin-top: -24px;
  padding-bottom: 0;
}

.bl_panel_headerFooter:last-child > * {
  margin-top: 24px;
  margin-bottom: 0;
}

.bl_table, .bl_virtual_table {
  display: block;
}

.bl_table::-webkit-scrollbar, .bl_virtual_table::-webkit-scrollbar {
  appearance: none;
  width: 12px;
  height: 12px;
}

.bl_table::-webkit-scrollbar-thumb, .bl_virtual_table::-webkit-scrollbar-thumb {
  background-color: #3f81cb;
  border-radius: 6px;
}

.bl_table::-webkit-scrollbar-track, .bl_virtual_table::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 6px;
  margin-top: 68px;
}

.bl_table, .bl_virtual_table table {
  width: 100%;
  white-space: nowrap;
  border-bottom: 2px solid #3f81cb;
  overflow: auto;
}

.bl_table tr, .bl_virtual_table table tr {
  width: 100%;
  display: flex;
}

.bl_table th, .bl_table td, .bl_virtual_table table th, .bl_virtual_table table td {
  white-space: normal;
  word-break: break-all;
  background-color: #fff;
  padding: 24px 16px;
  display: block;
}

.bl_table th, .bl_virtual_table table th {
  font-size: 14px;
  font-weight: bold;
}

.bl_table td, .bl_virtual_table table td {
  align-items: center;
  display: flex;
}

.bl_table td > img, .bl_virtual_table table td > img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  display: inline-block;
}

.bl_table_head, .bl_virtual_table table > thead {
  width: 100%;
  z-index: 5;
  display: block;
  position: sticky;
  top: 0;
}

.bl_table_head th, .bl_virtual_table table > thead th {
  color: #3f81cb;
  border-top: 2px solid #3f81cb;
  border-bottom: 2px solid #3f81cb;
}

.bl_table_body, .bl_virtual_table table > tbody {
  width: 100%;
  display: block;
}

.bl_table_body td, .bl_virtual_table table > tbody td {
  border-bottom: 1px solid #e6e6e6;
}

.bl_table_filterHead {
  position: relative;
}

.bl_table_filterHeadTtl {
  cursor: pointer;
}

.bl_table_filterHeadTtl:after {
  content: "";
  width: 14px;
  height: 14px;
  background-image: url("list-off.403e478d.svg");
  background-repeat: no-repeat;
  margin-left: 2px;
  display: inline-block;
  transform: translateY(1px);
}

.bl_table_filterHead .bl_tableSearchForm {
  text-align: left;
  color: #242424;
  background-color: #edf3fa;
  border: 1px solid #3f81cb;
  padding: 16px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.bl_table_filterHead .bl_tableSearchForm > * {
  width: 100%;
  white-space: nowrap;
}

.bl_table_filterHead .bl_tableSearchForm__right {
  left: auto;
  right: 0;
}

.bl_table_filterHead .bl_tableSearchForm_ttl {
  margin-bottom: 8px;
}

.bl_table_filterHead .bl_tableSearchForm input[type="text"] {
  width: 240px;
  padding-right: 30px;
  font-weight: 500;
}

.bl_table_filterHead .bl_tableSearchForm select {
  min-width: 140px;
  font-weight: 500;
}

.bl_table_filterHead .bl_tableSearchForm select > option {
  font-weight: 500;
}

.bl_table_filterHead.is_active {
  color: #fff;
  background-color: #3f81cb;
}

.bl_table_filterHead.is_active .bl_table_filterHeadTtl:after {
  background-image: url("list-on.5e6d7a1a.svg");
}

.bl_table_filterHead.is_active .bl_tableSearchForm {
  display: block;
}

.bl_table_filterHead.bl_table_filterHead__beforeBtn.is_active {
  background: none;
  padding: 0;
}

.bl_table_filterHead.bl_table_filterHead__beforeBtn.is_active .bl_table_filterHeadTtl {
  color: #fff;
  background-color: #3f81cb;
  padding: 24px 16px;
  display: inline-block;
}

@media screen and (max-width: 480px) {
  .bl_table {
    max-width: calc(100vw - 96px);
  }

  .bl_table_filterHead .bl_tableSearchForm input[type="text"] {
    width: 200px;
  }
}

.bl_nestTable {
  flex: 0 744px;
  padding-left: 16px;
  padding-right: 16px;
  display: block;
}

.bl_table .bl_table_body .bl_nestTableWrap {
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.bl_nestTable tbody {
  display: block;
}

.bl_nestTable tr {
  display: flex;
}

.bl_nestTable tr:last-child th, .bl_nestTable tr:last-child td {
  border-bottom: none;
}

.bl_nestTable th, .bl_nestTable td {
  border-bottom: 1px solid #e6e6e6;
  padding-top: 24px;
  padding-bottom: 24px;
  display: block;
}

.bl_nestTable th {
  align-items: center;
  display: flex;
  flex-basis: auto !important;
}

.bl_nestTable td {
  flex-grow: 1 !important;
  flex-basis: auto !important;
}

.bl_nestTable + div {
  flex: 0 0 152px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}

.bl_borderTable {
  width: 100%;
}

.bl_borderTable tr:last-child th, .bl_borderTable tr:last-child td {
  border-bottom: none;
}

.bl_borderTable th, .bl_borderTable td {
  vertical-align: middle;
  white-space: nowrap;
  border-bottom: 1px solid #e6e6e6;
  padding: 24px;
}

.bl_table_btnWrap {
  margin-left: 32px;
  display: flex;
}

.bl_table_btnWrap:first-child {
  margin-left: 0;
}

.bl_table_btnWrap > * {
  margin-right: 8px;
  font-size: 12px;
}

.bl_table_btnWrap > :last-child {
  margin-right: 0;
}

.bl_table_btnWrap > .el_btnInv, .bl_table_btnWrap > .el_btnTerm {
  white-space: nowrap;
}

.bl_table.bl_table__totalReview {
  height: 792px;
}

.bl_table.bl_table__totalReview th:nth-child(1), .bl_table.bl_table__totalReview td:nth-child(1) {
  min-width: 170.667px;
  flex: 0 512px;
}

.bl_table.bl_table__totalReview th:nth-child(1) {
  text-align: left;
}

.bl_table.bl_table__totalReview td:nth-child(1) {
  justify-content: flex-start;
}

.bl_table.bl_table__totalReview th:nth-child(2), .bl_table.bl_table__totalReview td:nth-child(2) {
  flex: 1 0 104px;
}

.bl_table.bl_table__totalReview th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__totalReview td:nth-child(2) {
  justify-content: flex-end;
}

.bl_table.bl_table__totalReview th:nth-child(3), .bl_table.bl_table__totalReview td:nth-child(3) {
  flex: 1 0 104px;
}

.bl_table.bl_table__totalReview th:nth-child(3) {
  text-align: center;
}

.bl_table.bl_table__totalReview td:nth-child(3) {
  justify-content: flex-end;
}

.bl_table.bl_table__totalReview th, .bl_table.bl_table__totalReview td {
  padding: 16px 8px;
}

.bl_table.bl_table__totalReview td:nth-child(2), .bl_table.bl_table__totalReview td:nth-child(3) {
  font-size: 18px;
  font-weight: bold;
}

.bl_table.bl_table__totalReview td:nth-child(2) .el_unit, .bl_table.bl_table__totalReview td:nth-child(3) .el_unit {
  margin-left: 8px;
  font-size: 14px;
}

.bl_table.bl_table__totalReview::-webkit-scrollbar-track {
  margin-top: 50px;
}

.bl_table.bl_table__userTable {
  height: 556px;
}

.bl_table.bl_table__userTable th:nth-child(1), .bl_table.bl_table__userTable td:nth-child(1) {
  flex: 0 0 224px;
}

.bl_table.bl_table__userTable th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__userTable td:nth-child(1) {
  justify-content: flex-start;
}

.bl_table.bl_table__userTable th:nth-child(2), .bl_table.bl_table__userTable td:nth-child(2) {
  min-width: 283px;
  flex: 849px;
}

.bl_table.bl_table__userTable th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__userTable td:nth-child(2) {
  justify-content: flex-start;
}

.bl_table.bl_table__userTable th:nth-child(3), .bl_table.bl_table__userTable td:nth-child(3) {
  flex: 0 0 180px;
}

.bl_table.bl_table__userTable th:nth-child(3) {
  text-align: center;
}

.bl_table.bl_table__userTable td:nth-child(3) {
  justify-content: center;
}

.bl_table.bl_table__userTable th:nth-child(4), .bl_table.bl_table__userTable td:nth-child(4) {
  flex: 0 0 134px;
}

.bl_table.bl_table__userTable th:nth-child(4) {
  text-align: center;
}

.bl_table.bl_table__userTable td:nth-child(4) {
  justify-content: center;
}

.bl_table.bl_table__userTable th:nth-child(5), .bl_table.bl_table__userTable td:nth-child(5) {
  flex: 0 0 134px;
}

.bl_table.bl_table__userTable th:nth-child(5) {
  text-align: center;
}

.bl_table.bl_table__userTable td:nth-child(5) {
  justify-content: center;
}

.bl_table.bl_table__userTable th:nth-child(6), .bl_table.bl_table__userTable td:nth-child(6) {
  flex: 0 0 154px;
}

.bl_table.bl_table__userTable th:nth-child(6) {
  text-align: left;
}

.bl_table.bl_table__userTable td:nth-child(6) {
  justify-content: center;
}

.bl_table.bl_table__userTable th:nth-child(4) {
  padding-left: 50px;
}

.bl_table.bl_table__userTable td:nth-child(1) {
  padding-left: 39px;
}

.bl_table__reviewStatus {
  height: 445px;
}

.bl_table.bl_table__reviewStatus th:nth-child(1), .bl_table.bl_table__reviewStatus td:nth-child(1), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(1), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(1) {
  flex: 0 0 138px;
}

.bl_table.bl_table__reviewStatus th:nth-child(1), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__reviewStatus td:nth-child(1), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(1) {
  justify-content: center;
}

.bl_table.bl_table__reviewStatus th:nth-child(2), .bl_table.bl_table__reviewStatus td:nth-child(2), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(2), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(2) {
  flex: 0 0 170px;
}

.bl_table.bl_table__reviewStatus th:nth-child(2), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__reviewStatus td:nth-child(2), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(2) {
  justify-content: center;
}

.bl_table.bl_table__reviewStatus th:nth-child(3), .bl_table.bl_table__reviewStatus td:nth-child(3), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(3), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(3) {
  min-width: 161.667px;
  flex: 0 485px;
}

.bl_table.bl_table__reviewStatus th:nth-child(3), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(3) {
  text-align: center;
}

.bl_table.bl_table__reviewStatus td:nth-child(3), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(3) {
  justify-content: flex-start;
}

.bl_table.bl_table__reviewStatus th:nth-child(4), .bl_table.bl_table__reviewStatus td:nth-child(4), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(4), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(4) {
  flex: 0 0 120px;
}

.bl_table.bl_table__reviewStatus th:nth-child(4), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(4) {
  text-align: center;
}

.bl_table.bl_table__reviewStatus td:nth-child(4), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(4) {
  justify-content: center;
}

.bl_table.bl_table__reviewStatus th:nth-child(5), .bl_table.bl_table__reviewStatus td:nth-child(5), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(5), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(5) {
  flex: 0 0 116px;
}

.bl_table.bl_table__reviewStatus th:nth-child(5), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(5) {
  text-align: center;
}

.bl_table.bl_table__reviewStatus td:nth-child(5), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(5) {
  justify-content: center;
}

.bl_table.bl_table__reviewStatus th:nth-child(6), .bl_table.bl_table__reviewStatus td:nth-child(6), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(6), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(6) {
  flex: 0 0 144px;
}

.bl_table.bl_table__reviewStatus th:nth-child(6), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(6) {
  text-align: center;
}

.bl_table.bl_table__reviewStatus td:nth-child(6), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(6) {
  justify-content: center;
}

.bl_table.bl_table__reviewStatus th:nth-child(7), .bl_table.bl_table__reviewStatus td:nth-child(7), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(7), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(7) {
  flex: 0 0 130px;
}

.bl_table.bl_table__reviewStatus th:nth-child(7), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(7) {
  text-align: center;
}

.bl_table.bl_table__reviewStatus td:nth-child(7), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(7) {
  justify-content: center;
}

.bl_table.bl_table__reviewStatus th:nth-child(8), .bl_table.bl_table__reviewStatus td:nth-child(8), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(8), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(8) {
  flex: 0 0 102px;
}

.bl_table.bl_table__reviewStatus th:nth-child(8), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(8) {
  text-align: center;
}

.bl_table.bl_table__reviewStatus td:nth-child(8), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(8) {
  justify-content: center;
}

.bl_table.bl_table__reviewStatus th:nth-child(9), .bl_table.bl_table__reviewStatus td:nth-child(9), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(9), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(9) {
  flex: 0 0 135px;
}

.bl_table.bl_table__reviewStatus th:nth-child(9), .bl_virtual_table.bl_table__reviewStatus table th:nth-child(9) {
  text-align: center;
}

.bl_table.bl_table__reviewStatus td:nth-child(9), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(9) {
  justify-content: center;
}

.bl_table.bl_table__reviewStatus td:nth-child(1), .bl_table.bl_table__reviewStatus td:nth-child(4), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(1), .bl_virtual_table.bl_table__reviewStatus table td:nth-child(4) {
  flex-direction: column;
}

.bl_virtual_table.bl_table__reception {
  height: 629px;
}

.bl_virtual_table.bl_table__reception th:nth-child(1), .bl_virtual_table.bl_table__reception td:nth-child(1) {
  flex: 0 0 48px;
}

.bl_virtual_table.bl_table__reception th:nth-child(1) {
  text-align: center;
}

.bl_virtual_table.bl_table__reception td:nth-child(1) {
  justify-content: center;
}

.bl_virtual_table.bl_table__reception th:nth-child(2), .bl_virtual_table.bl_table__reception td:nth-child(2) {
  flex: 0 0 108px;
}

.bl_virtual_table.bl_table__reception th:nth-child(2) {
  text-align: center;
}

.bl_virtual_table.bl_table__reception td:nth-child(2) {
  justify-content: center;
}

.bl_virtual_table.bl_table__reception th:nth-child(3), .bl_virtual_table.bl_table__reception td:nth-child(3) {
  flex: 0 0 138px;
}

.bl_virtual_table.bl_table__reception th:nth-child(3) {
  text-align: center;
}

.bl_virtual_table.bl_table__reception td:nth-child(3) {
  justify-content: center;
}

.bl_virtual_table.bl_table__reception th:nth-child(4), .bl_virtual_table.bl_table__reception td:nth-child(4) {
  min-width: 225px;
  flex: 675px;
}

.bl_virtual_table.bl_table__reception th:nth-child(4) {
  text-align: center;
}

.bl_virtual_table.bl_table__reception td:nth-child(4) {
  justify-content: flex-start;
}

.bl_virtual_table.bl_table__reception th:nth-child(5), .bl_virtual_table.bl_table__reception td:nth-child(5) {
  flex: 0 0 160px;
}

.bl_virtual_table.bl_table__reception th:nth-child(5) {
  text-align: center;
}

.bl_virtual_table.bl_table__reception td:nth-child(5) {
  justify-content: center;
}

.bl_virtual_table.bl_table__reception th:nth-child(6), .bl_virtual_table.bl_table__reception td:nth-child(6) {
  flex: 0 0 132px;
}

.bl_virtual_table.bl_table__reception th:nth-child(6) {
  text-align: flex-start;
}

.bl_virtual_table.bl_table__reception td:nth-child(6) {
  justify-content: center;
}

.bl_virtual_table.bl_table__reception th:nth-child(7), .bl_virtual_table.bl_table__reception td:nth-child(7) {
  flex: 0 0 168px;
}

.bl_virtual_table.bl_table__reception th:nth-child(7) {
  text-align: center;
}

.bl_virtual_table.bl_table__reception td:nth-child(7) {
  justify-content: center;
}

.bl_virtual_table.bl_table__reception th:nth-child(8), .bl_virtual_table.bl_table__reception td:nth-child(8) {
  flex: 0 0 108px;
}

.bl_virtual_table.bl_table__reception th:nth-child(8) {
  text-align: center;
}

.bl_virtual_table.bl_table__reception td:nth-child(8) {
  justify-content: center;
}

.bl_virtual_table.bl_table__reception td > .el_btnTxt__underLine {
  margin-left: 32px;
}

.bl_table.bl_table__privilege {
  height: 632px;
}

.bl_table.bl_table__privilege th:nth-child(1), .bl_table.bl_table__privilege td:nth-child(1) {
  flex: 0 0 48px;
}

.bl_table.bl_table__privilege th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__privilege td:nth-child(1) {
  justify-content: center;
}

.bl_table.bl_table__privilege th:nth-child(2), .bl_table.bl_table__privilege td:nth-child(2) {
  min-width: 135.333px;
  flex: 406px;
}

.bl_table.bl_table__privilege th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__privilege td:nth-child(2) {
  justify-content: flex-start;
}

.bl_table.bl_table__privilege th:nth-child(3), .bl_table.bl_table__privilege td:nth-child(3) {
  flex: 0 0 74px;
}

.bl_table.bl_table__privilege th:nth-child(3) {
  text-align: center;
}

.bl_table.bl_table__privilege td:nth-child(3) {
  justify-content: center;
}

.bl_table.bl_table__privilege th:nth-child(4), .bl_table.bl_table__privilege td:nth-child(4) {
  flex: 0 0 112px;
}

.bl_table.bl_table__privilege th:nth-child(4) {
  text-align: center;
}

.bl_table.bl_table__privilege td:nth-child(4) {
  justify-content: center;
}

.bl_table.bl_table__privilege th:nth-child(5), .bl_table.bl_table__privilege td:nth-child(5) {
  min-width: 298.667px;
  flex: 1 .3 896px;
}

.bl_table.bl_table__privilege th:nth-child(5) {
  text-align: center;
}

.bl_table.bl_table__privilege td:nth-child(5) {
  justify-content: center;
}

.bl_table.bl_table__coupon {
  height: 632px;
}

.bl_table.bl_table__coupon th:nth-child(1), .bl_table.bl_table__coupon td:nth-child(1) {
  flex: 0 0 48px;
}

.bl_table.bl_table__coupon th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__coupon td:nth-child(1) {
  justify-content: center;
}

.bl_table.bl_table__coupon th:nth-child(2), .bl_table.bl_table__coupon td:nth-child(2) {
  min-width: 160px;
  flex: 480px;
}

.bl_table.bl_table__coupon th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__coupon td:nth-child(2) {
  justify-content: flex-start;
}

.bl_table.bl_table__coupon th:nth-child(3), .bl_table.bl_table__coupon td:nth-child(3) {
  flex: 0 0 112px;
}

.bl_table.bl_table__coupon th:nth-child(3) {
  text-align: center;
}

.bl_table.bl_table__coupon td:nth-child(3) {
  justify-content: center;
}

.bl_table.bl_table__coupon th:nth-child(4), .bl_table.bl_table__coupon td:nth-child(4) {
  min-width: 298.667px;
  flex: 1 .3 896px;
}

.bl_table.bl_table__coupon th:nth-child(4) {
  text-align: center;
}

.bl_table.bl_table__coupon td:nth-child(4) {
  justify-content: center;
}

.bl_table.bl_table__productGroup {
  height: 691px;
}

.bl_table.bl_table__productGroup th:nth-child(1), .bl_table.bl_table__productGroup td:nth-child(1) {
  flex: 0 0 48px;
}

.bl_table.bl_table__productGroup th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__productGroup td:nth-child(1) {
  justify-content: center;
}

.bl_table.bl_table__productGroup th:nth-child(2), .bl_table.bl_table__productGroup td:nth-child(2) {
  min-width: 160px;
  flex: 480px;
}

.bl_table.bl_table__productGroup th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__productGroup td:nth-child(2) {
  justify-content: flex-start;
}

.bl_table.bl_table__productGroup th:nth-child(3), .bl_table.bl_table__productGroup td:nth-child(3) {
  flex: 1 0 200px;
}

.bl_table.bl_table__productGroup th:nth-child(3) {
  text-align: center;
}

.bl_table.bl_table__productGroup td:nth-child(3) {
  justify-content: flex-start;
}

.bl_table.bl_table__productGroup th:nth-child(4), .bl_table.bl_table__productGroup td:nth-child(4) {
  min-width: 269.333px;
  flex: 1 .4 808px;
}

.bl_table.bl_table__productGroup th:nth-child(4) {
  text-align: center;
}

.bl_table.bl_table__productGroup td:nth-child(4) {
  justify-content: center;
}

.bl_table.bl_table__campaign {
  height: 691px;
}

.bl_table.bl_table__campaign th:nth-child(1), .bl_table.bl_table__campaign td:nth-child(1) {
  flex: 0 0 32px;
}

.bl_table.bl_table__campaign th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__campaign td:nth-child(1) {
  justify-content: center;
}

.bl_table.bl_table__campaign th:nth-child(2), .bl_table.bl_table__campaign td:nth-child(2) {
  flex: 0 0 48px;
}

.bl_table.bl_table__campaign th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__campaign td:nth-child(2) {
  justify-content: center;
}

.bl_table.bl_table__campaign th:nth-child(3), .bl_table.bl_table__campaign td:nth-child(3) {
  min-width: 156px;
  flex: 468px;
}

.bl_table.bl_table__campaign th:nth-child(3) {
  text-align: center;
}

.bl_table.bl_table__campaign td:nth-child(3) {
  justify-content: flex-start;
}

.bl_table.bl_table__campaign th:nth-child(4), .bl_table.bl_table__campaign td:nth-child(4) {
  flex: 0 0 160px;
}

.bl_table.bl_table__campaign th:nth-child(4) {
  text-align: center;
}

.bl_table.bl_table__campaign td:nth-child(4) {
  justify-content: center;
}

.bl_table.bl_table__campaign th:nth-child(5), .bl_table.bl_table__campaign td:nth-child(5) {
  flex: 0 0 160px;
}

.bl_table.bl_table__campaign th:nth-child(5) {
  text-align: center;
}

.bl_table.bl_table__campaign td:nth-child(5) {
  justify-content: center;
}

.bl_table.bl_table__campaign th:nth-child(6), .bl_table.bl_table__campaign td:nth-child(6) {
  min-width: 153.333px;
  flex: 460px;
}

.bl_table.bl_table__campaign th:nth-child(6) {
  text-align: center;
}

.bl_table.bl_table__campaign td:nth-child(6) {
  justify-content: flex-start;
}

.bl_table.bl_table__campaign th:nth-child(7), .bl_table.bl_table__campaign td:nth-child(7) {
  flex: 0 0 240px;
}

.bl_table.bl_table__campaign th:nth-child(7) {
  text-align: left;
}

.bl_table.bl_table__campaign td:nth-child(7) {
  justify-content: space-between;
}

.bl_table.bl_table__campaign td:nth-child(5) {
  text-align: center;
}

.bl_table.bl_table__campaign td:nth-child(1) {
  padding: 0;
}

.bl_table.bl_table__campaign .el_dragIndicator {
  width: 100%;
  height: 100%;
  background-image: url("drag_indicator_black_24dp.cde17cf5.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.bl_table.bl_table__mailTemplate {
  height: 687px;
}

.bl_table.bl_table__mailTemplate th:nth-child(1), .bl_table.bl_table__mailTemplate td:nth-child(1) {
  flex: 0 0 48px;
}

.bl_table.bl_table__mailTemplate th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__mailTemplate td:nth-child(1) {
  justify-content: center;
}

.bl_table.bl_table__mailTemplate th:nth-child(2), .bl_table.bl_table__mailTemplate td:nth-child(2) {
  min-width: 392px;
  flex: 0 1176px;
}

.bl_table.bl_table__mailTemplate th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__mailTemplate td:nth-child(2) {
  justify-content: flex-start;
}

.bl_table.bl_table__mailTemplate th:nth-child(3), .bl_table.bl_table__mailTemplate td:nth-child(3) {
  flex: 0 0 312px;
}

.bl_table.bl_table__mailTemplate th:nth-child(3) {
  text-align: left;
}

.bl_table.bl_table__mailTemplate td:nth-child(3) {
  justify-content: center;
}

.bl_table.bl_table__mailTemplate td:nth-child(2) {
  padding-left: 48px;
}

.bl_table.bl_table__mailTemplate .is_favorite {
  position: relative;
}

.bl_table.bl_table__mailTemplate .is_favorite:before {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("star.b8b37c01.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.bl_table.bl_analysisTable th:nth-child(1), .bl_table.bl_analysisTable td:nth-child(1) {
  min-width: 160px;
  flex: 480px;
}

.bl_table.bl_analysisTable th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_analysisTable td:nth-child(1) {
  justify-content: left;
}

.bl_table.bl_analysisTable th:nth-child(2), .bl_table.bl_analysisTable td:nth-child(2) {
  flex: 0 0 200px;
}

.bl_table.bl_analysisTable th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_analysisTable td:nth-child(2) {
  justify-content: right;
}

.bl_table.bl_analysisTable th:nth-child(3), .bl_table.bl_analysisTable td:nth-child(3) {
  flex: 0 0 200px;
}

.bl_table.bl_analysisTable th:nth-child(3) {
  text-align: center;
}

.bl_table.bl_analysisTable td:nth-child(3) {
  justify-content: right;
}

.bl_table.bl_postRateTable th:nth-child(1), .bl_table.bl_postRateTable td:nth-child(1) {
  min-width: 40px;
  flex: 120px;
}

.bl_table.bl_postRateTable th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_postRateTable td:nth-child(1) {
  justify-content: center;
}

.bl_table.bl_postRateTable th:nth-child(2), .bl_table.bl_postRateTable td:nth-child(2) {
  flex: 0 0 130px;
}

.bl_table.bl_postRateTable th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_postRateTable td:nth-child(2) {
  justify-content: right;
}

.bl_table.bl_postRateTable th:nth-child(3), .bl_table.bl_postRateTable td:nth-child(3) {
  flex: 0 0 130px;
}

.bl_table.bl_postRateTable th:nth-child(3) {
  text-align: center;
}

.bl_table.bl_postRateTable td:nth-child(3) {
  justify-content: right;
}

.bl_table.bl_postRateTable th:nth-child(4), .bl_table.bl_postRateTable td:nth-child(4) {
  flex: 0 0 130px;
}

.bl_table.bl_postRateTable th:nth-child(4) {
  text-align: center;
}

.bl_table.bl_postRateTable td:nth-child(4) {
  justify-content: right;
}

.bl_table.bl_postRateTable th:nth-child(5), .bl_table.bl_postRateTable td:nth-child(5) {
  flex: 0 0 130px;
}

.bl_table.bl_postRateTable th:nth-child(5) {
  text-align: center;
}

.bl_table.bl_postRateTable td:nth-child(5) {
  justify-content: right;
}

.bl_table.bl_postRateTable th:nth-child(6), .bl_table.bl_postRateTable td:nth-child(6) {
  flex: 0 0 130px;
}

.bl_table.bl_postRateTable th:nth-child(6) {
  text-align: center;
}

.bl_table.bl_postRateTable td:nth-child(6) {
  justify-content: right;
}

.bl_table.bl_postRateTable th:nth-child(7), .bl_table.bl_postRateTable td:nth-child(7) {
  flex: 0 0 130px;
}

.bl_table.bl_postRateTable th:nth-child(7) {
  text-align: center;
}

.bl_table.bl_postRateTable td:nth-child(7) {
  justify-content: right;
}

.bl_table.bl_postRateTable th:nth-child(8), .bl_table.bl_postRateTable td:nth-child(8) {
  flex: 0 0 130px;
}

.bl_table.bl_postRateTable th:nth-child(8) {
  text-align: center;
}

.bl_table.bl_postRateTable td:nth-child(8) {
  justify-content: right;
}

.bl_table.bl_table__receptionRate {
  height: 691px;
}

.bl_table.bl_table__receptionRate th:nth-child(1), .bl_table.bl_table__receptionRate td:nth-child(1) {
  flex: 0 0 48px;
}

.bl_table.bl_table__receptionRate th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__receptionRate td:nth-child(1) {
  justify-content: center;
}

.bl_table.bl_table__receptionRate th:nth-child(2), .bl_table.bl_table__receptionRate td:nth-child(2) {
  min-width: 152px;
  flex: 456px;
}

.bl_table.bl_table__receptionRate th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__receptionRate td:nth-child(2) {
  justify-content: flex-start;
}

.bl_table.bl_table__receptionRate th:nth-child(3), .bl_table.bl_table__receptionRate td:nth-child(3) {
  flex: 0 0 160px;
}

.bl_table.bl_table__receptionRate th:nth-child(3) {
  text-align: center;
}

.bl_table.bl_table__receptionRate td:nth-child(3) {
  justify-content: center;
}

.bl_table.bl_table__receptionRate th:nth-child(4), .bl_table.bl_table__receptionRate td:nth-child(4) {
  flex: 0 0 882px;
}

.bl_table.bl_table__receptionRate th:nth-child(4) {
  text-align: center;
}

.bl_table.bl_table__receptionRate td:nth-child(4) {
  justify-content: center;
}

.bl_analysisTable.bl_analysisTable__rank tbody td:first-child {
  padding-left: 36px;
  font-weight: bold;
  position: relative;
}

.bl_analysisTable.bl_analysisTable__rank tbody td:first-child:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.bl_analysisTable.bl_analysisTable__rank tbody tr:nth-child(1) td:first-child:before {
  background-color: #3d4854;
}

.bl_analysisTable.bl_analysisTable__rank tbody tr:nth-child(2) td:first-child:before {
  background-color: #47c5e6;
}

.bl_analysisTable.bl_analysisTable__rank tbody tr:nth-child(3) td:first-child:before {
  background-color: #eba707;
}

.bl_analysisTable.bl_analysisTable__rank tbody tr:nth-child(4) td:first-child:before {
  background-color: #e2e1cb;
}

.bl_analysisTable.bl_analysisTable__rank tbody tr:nth-child(5) td:first-child:before {
  background-color: #92e831;
}

.bl_analysisTable.bl_analysisTable__gender tbody td:first-child {
  padding-left: 36px;
  font-weight: bold;
  position: relative;
}

.bl_analysisTable.bl_analysisTable__gender tbody td:first-child:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.bl_analysisTable.bl_analysisTable__gender tbody tr:nth-child(1) td:first-child:before {
  background-color: #3d4854;
}

.bl_analysisTable.bl_analysisTable__gender tbody tr:nth-child(2) td:first-child:before {
  background-color: #47c5e6;
}

.bl_analysisTable.bl_analysisTable__gender tbody tr:nth-child(3) td:first-child:before {
  background-color: #eba707;
}

.bl_analysisTable.bl_analysisTable__age tbody td:first-child {
  padding-left: 36px;
  font-weight: bold;
  position: relative;
}

.bl_analysisTable.bl_analysisTable__age tbody td:first-child:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.bl_analysisTable.bl_analysisTable__age tbody tr:nth-child(1) td:first-child:before {
  background-color: #3d4854;
}

.bl_analysisTable.bl_analysisTable__age tbody tr:nth-child(2) td:first-child:before {
  background-color: #47c5e6;
}

.bl_analysisTable.bl_analysisTable__age tbody tr:nth-child(3) td:first-child:before {
  background-color: #eba707;
}

.bl_analysisTable.bl_analysisTable__age tbody tr:nth-child(4) td:first-child:before {
  background-color: #e2e1cb;
}

.bl_analysisTable.bl_analysisTable__age tbody tr:nth-child(5) td:first-child:before {
  background-color: #92e831;
}

.bl_analysisTable.bl_analysisTable__age tbody tr:nth-child(6) td:first-child:before {
  background-color: #f368ae;
}

.bl_analysisTable.bl_analysisTable__age tbody tr:nth-child(7) td:first-child:before {
  background-color: #8d7ec7;
}

.bl_table_barGraphUnit {
  justify-content: space-between !important;
  display: flex !important;
}

.bl_table_barGraphWrap {
  flex: 0 50%;
}

.bl_table_barGraphWrap:first-child {
  padding-right: 12px;
}

.bl_table_barGraphWrap:last-child {
  padding-left: 12px;
}

.bl_table_barGraphWrap > p {
  color: #3f81cb;
  margin-bottom: 8px;
  font-weight: bold;
}

.bl_table_barGraphWrap > ul {
  display: flex;
}

.bl_table_barGraphWrap > ul > li {
  margin-right: 16px;
  padding-left: 26px;
  font-weight: bold;
  position: relative;
}

.bl_table_barGraphWrap > ul > li:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.bl_table_barGraphWrap > ul > li:nth-child(1):before {
  background-color: #f3680b;
}

.bl_table_barGraphWrap > ul > li:nth-child(2):before {
  background-color: #ffde20;
}

.bl_analysisTable.bl_analysisTable__bycase {
  height: 640px;
  border-bottom: 1px solid #3f81cb;
}

.bl_analysisTable.bl_analysisTable__bycase th:nth-child(1), .bl_analysisTable.bl_analysisTable__bycase td:nth-child(1) {
  min-width: 384px;
  flex: 1152px;
}

.bl_analysisTable.bl_analysisTable__bycase th:nth-child(1) {
  text-align: left;
}

.bl_analysisTable.bl_analysisTable__bycase td:nth-child(1) {
  justify-content: flex-start;
}

.bl_analysisTable.bl_analysisTable__bycase th:nth-child(2), .bl_analysisTable.bl_analysisTable__bycase td:nth-child(2) {
  flex: 0 0 100px;
}

.bl_analysisTable.bl_analysisTable__bycase th:nth-child(2) {
  text-align: center;
}

.bl_analysisTable.bl_analysisTable__bycase td:nth-child(2) {
  justify-content: center;
}

.bl_analysisTable.bl_analysisTable__bycase th:nth-child(3), .bl_analysisTable.bl_analysisTable__bycase td:nth-child(3) {
  flex: 0 0 128px;
}

.bl_analysisTable.bl_analysisTable__bycase th:nth-child(3) {
  text-align: center;
}

.bl_analysisTable.bl_analysisTable__bycase td:nth-child(3) {
  justify-content: center;
}

.bl_analysisTable.bl_analysisTable__bycase th:nth-child(4), .bl_analysisTable.bl_analysisTable__bycase td:nth-child(4) {
  flex: 0 0 156px;
}

.bl_analysisTable.bl_analysisTable__bycase th:nth-child(4) {
  text-align: center;
}

.bl_analysisTable.bl_analysisTable__bycase td:nth-child(4) {
  justify-content: center;
}

.bl_analysisTable.bl_analysisTable__bycase::-webkit-scrollbar-track {
  margin-top: 49px;
}

.bl_table.bl_table__headBorderBottom {
  height: 560px;
}

.bl_table.bl_table__headBorderBottom th:nth-child(1), .bl_table.bl_table__headBorderBottom td:nth-child(1) {
  min-width: 116px;
  flex: 0 348px;
}

.bl_table.bl_table__headBorderBottom th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__headBorderBottom td:nth-child(1) {
  justify-content: flex-start;
}

.bl_table.bl_table__headBorderBottom th:nth-child(2), .bl_table.bl_table__headBorderBottom td:nth-child(2) {
  flex: 0 0 110px;
}

.bl_table.bl_table__headBorderBottom th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__headBorderBottom td:nth-child(2) {
  justify-content: center;
}

.bl_table.bl_table__headBorderBottom::-webkit-scrollbar-track {
  margin-top: 51px;
}

.bl_table.bl_table__headBorderBottom th {
  border-top: none;
  padding-top: 16px;
  padding-bottom: 16px;
}

.bl_table.bl_table__systemAlert {
  border-bottom: none;
}

.bl_table.bl_table__systemAlert th:nth-child(1), .bl_table.bl_table__systemAlert td:nth-child(1) {
  min-width: 294px;
  flex: 0 882px;
}

.bl_table.bl_table__systemAlert th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__systemAlert td:nth-child(1) {
  justify-content: flex-start;
}

.bl_table.bl_table__systemAlert th:nth-child(2), .bl_table.bl_table__systemAlert td:nth-child(2) {
  flex: 0 0 110px;
}

.bl_table.bl_table__systemAlert th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__systemAlert td:nth-child(2) {
  justify-content: center;
}

.bl_table.bl_table__systemAlert td {
  border-bottom: none;
  flex-wrap: wrap;
  padding-top: 16px;
  padding-bottom: 16px;
}

.bl_table.bl_table__plan {
  border-bottom: none;
}

.bl_table.bl_table__plan th:nth-child(1), .bl_table.bl_table__plan td:nth-child(1) {
  flex: 0 0 200px;
}

.bl_table.bl_table__plan th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__plan td:nth-child(1) {
  justify-content: flex-start;
}

.bl_table.bl_table__plan th:nth-child(2), .bl_table.bl_table__plan td:nth-child(2) {
  min-width: 166.667px;
  flex: 0 500px;
}

.bl_table.bl_table__plan th:nth-child(2) {
  text-align: left;
}

.bl_table.bl_table__plan td:nth-child(2) {
  justify-content: flex-start;
}

.bl_table.bl_table__plan td {
  border-bottom: none;
  flex-wrap: wrap;
  padding-top: 16px;
  padding-bottom: 16px;
}

.bl_table.bl_table__notCovered {
  height: 688px;
}

.bl_table.bl_table__notCovered th:nth-child(1), .bl_table.bl_table__notCovered td:nth-child(1) {
  flex: 0 0 48px;
}

.bl_table.bl_table__notCovered th:nth-child(1) {
  text-align: center;
}

.bl_table.bl_table__notCovered td:nth-child(1) {
  justify-content: center;
}

.bl_table.bl_table__notCovered th:nth-child(2), .bl_table.bl_table__notCovered td:nth-child(2) {
  min-width: 136.667px;
  flex: 0 410px;
}

.bl_table.bl_table__notCovered th:nth-child(2) {
  text-align: center;
}

.bl_table.bl_table__notCovered td:nth-child(2) {
  justify-content: flex-start;
}

.bl_table.bl_table__notCovered th:nth-child(3), .bl_table.bl_table__notCovered td:nth-child(3) {
  min-width: 242px;
  flex: 0 726px;
}

.bl_table.bl_table__notCovered th:nth-child(3) {
  text-align: center;
}

.bl_table.bl_table__notCovered td:nth-child(3) {
  justify-content: flex-start;
}

.bl_table.bl_table__notCovered th:nth-child(4), .bl_table.bl_table__notCovered td:nth-child(4) {
  flex: 0 0 162px;
}

.bl_table.bl_table__notCovered th:nth-child(4) {
  text-align: center;
}

.bl_table.bl_table__notCovered td:nth-child(4) {
  justify-content: center;
}

.bl_table.bl_table__notCovered th:nth-child(5), .bl_table.bl_table__notCovered td:nth-child(5) {
  flex: 0 0 162px;
}

.bl_table.bl_table__notCovered th:nth-child(5) {
  text-align: center;
}

.bl_table.bl_table__notCovered td:nth-child(5) {
  justify-content: center;
}

.bl_table.bl_table__notCovered th:nth-child(6), .bl_table.bl_table__notCovered td:nth-child(6) {
  flex: 0 0 152px;
}

.bl_table.bl_table__notCovered th:nth-child(6) {
  text-align: left;
}

.bl_table.bl_table__notCovered td:nth-child(6) {
  justify-content: center;
}

.bl_table.bl_table__notCovered th:nth-child(6) {
  padding-left: 40px;
}

.bl_table.bl_table__prefecture {
  border-bottom: none;
}

.bl_table.bl_table__prefecture th:nth-child(1), .bl_table.bl_table__prefecture td:nth-child(1) {
  flex: 0 0 20%;
}

.bl_table.bl_table__prefecture th:nth-child(1) {
  text-align: left;
}

.bl_table.bl_table__prefecture td:nth-child(1) {
  justify-content: left;
}

.bl_table.bl_table__prefecture th:nth-child(2), .bl_table.bl_table__prefecture td:nth-child(2) {
  flex: 0 0 80%;
}

.bl_table.bl_table__prefecture th:nth-child(2) {
  text-align: left;
}

.bl_table.bl_table__prefecture td:nth-child(2) {
  justify-content: left;
}

.bl_table.bl_table__prefecture td {
  white-space: normal;
  border-bottom: 1px solid #e6e6e6;
  padding-top: 8px;
  padding-bottom: 8px;
}

.bl_table.bl_table__prefecture td label {
  white-space: nowrap;
}

.bl_reviewData {
  justify-content: space-between;
  display: flex;
}

.bl_reviewData_item {
  border-left: 1px solid #e6e6e6;
  flex: 0 190px;
  padding-left: 16px;
  padding-right: 16px;
}

.bl_reviewData_item:last-child {
  border-right: 1px solid #e6e6e6;
}

.bl_reviewData_heading {
  color: #3f81cb;
  margin-bottom: 9px;
  font-weight: bold;
  line-height: 1.8;
}

.bl_reviewData_item > p {
  font-size: 24px;
  font-weight: bold;
}

.bl_reviewData .el_unit {
  margin-left: 8px;
  font-size: 14px !important;
}

@media screen and (max-width: 480px) {
  .bl_reviewData {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }

  .bl_reviewData_item {
    border: none;
    border-bottom: 1px solid #e6e6e6;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .bl_reviewData_item:nth-child(2n+1) {
    padding-left: 0;
    position: relative;
  }

  .bl_reviewData_item:nth-child(2n+1):last-child {
    border-bottom: none;
  }

  .bl_reviewData_item:nth-child(2n+1):after {
    content: "";
    width: 1px;
    height: 82.0225%;
    background-color: #e6e6e6;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .bl_reviewData_item:last-child {
    border-right: none;
  }
}

.bl_loginFormRow {
  margin-bottom: 32px;
}

.bl_loginFormRow:last-child {
  margin-bottom: 0;
}

.bl_blueLabel {
  color: #3f81cb;
  margin-bottom: 8px;
  font-size: 1.28571rem;
  font-weight: bold;
  display: inline-block;
}

.bl_imgUpload {
  display: flex;
}

.bl_imgUpload_imgWrap {
  width: 80px;
  height: 80px;
  border: 1px solid #e6e6e6;
  flex: none;
  margin-right: 16px;
}

.bl_imgUpload_imgWrap > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bl_imgUpload_logoimgWrap {
  width: 140px;
  height: 100px;
  border: 1px solid #e6e6e6;
  flex: none;
  margin-right: 16px;
}

.bl_imgUpload_logoimgWrap > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bl_imgUpload_txtWrap {
  flex-direction: column;
  padding-top: 8px;
  display: flex;
}

.bl_imgUpload_txtWrap label {
  cursor: pointer;
}

.bl_imgUpload_txtWrap input[type="file"] {
  display: none;
}

.bl_imgUpload_txtWrap .el_btnTxt {
  margin-bottom: 8px;
  line-height: 1.4;
  display: inline-block;
}

.bl_imgUpload_txtWrap > p {
  font-size: 12px;
}

.bl_password {
  position: relative;
}

.bl_password input {
  padding-right: 40px;
}

.bl_password .el_showPassword:before {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("eye-slash-fill.b5a4aeb0.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.bl_password .el_showPassword.active:before {
  background-image: url("eye-fill.16b9e1ef.svg");
}

.bl_keywordComment {
  white-space: nowrap;
  align-items: center;
  padding-left: 26px;
  display: flex;
}

.bl_keywordComment > input {
  margin-left: 8px;
  margin-right: 8px;
}

.bl_acc_ttl {
  cursor: pointer;
  padding-left: 1.5em;
  position: relative;
}

.bl_acc_ttl:before {
  content: "";
  width: 1em;
  height: 100%;
  background-color: currentColor;
  position: absolute;
  left: 0;
  -webkit-mask-image: url("move.7c0b50ae.svg");
  mask-image: url("move.7c0b50ae.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bl_acc_ttl.is_active:before {
  transform: rotate(90deg);
}

.bl_acc_ttl.is_active + .bl_acc_body {
  display: block;
}

.bl_acc_body {
  display: none;
}

.bl_noticeList {
  margin-bottom: 16px;
}

.bl_noticeList li {
  margin-bottom: 16px;
  line-height: 1.9;
  display: flex;
}

.bl_noticeList li:last-child {
  margin-bottom: 0;
}

.bl_noticeList li > time {
  margin-right: 24px;
}

.bl_warningList {
  margin-bottom: 16px;
}

.bl_warningList li {
  border-left: 4px solid #e83131;
  margin-bottom: 16px;
  padding-left: 10px;
  line-height: 1.9;
}

.bl_warningList li:last-child {
  margin-bottom: 0;
}

.bl_warningList li > h3 {
  color: #e83131;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
}

.bl_rank {
  padding-bottom: 16px;
}

.bl_rank_item {
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 26px;
  margin-bottom: 16px;
  padding-top: 6px;
  padding-left: 32px;
  font-weight: bold;
  line-height: 1.3;
}

.bl_rank_item:last-child {
  margin-bottom: 0;
}

.bl_rank_item__1 {
  color: #3d4854;
  background-image: url("rank-1.eb781340.svg");
}

.bl_rank_item__2 {
  color: #47c5e6;
  background-image: url("rank-2.e92a0cab.svg");
}

.bl_rank_item__3 {
  color: #eba707;
  background-image: url("rank-3.96aa7365.svg");
}

.bl_checkList {
  padding: 18px 16px;
}

.bl_checkListWrap {
  height: 510px;
  border: 1px solid #e6e6e6;
  overflow: auto;
}

.bl_checkListWrap::-webkit-scrollbar {
  appearance: none;
  width: 12px;
  height: 12px;
}

.bl_checkListWrap::-webkit-scrollbar-thumb {
  background-color: #3f81cb;
  border-radius: 6px;
}

.bl_checkListWrap::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 6px;
  margin-top: 68px;
}

.bl_checkListWrap::-webkit-scrollbar-track {
  margin-top: 0;
}

.bl_checkList_item {
  margin-bottom: 18px;
}

.bl_checkList_item:last-child {
  margin-bottom: 0;
}

.bl_pullDown {
  border-left: 1px solid #e6e6e6;
  position: relative;
}

.bl_pullDown:last-child {
  border-right: 1px solid #e6e6e6;
}

.bl_pullDown_selected {
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 15;
  background-color: #fff;
  margin-top: -16px;
  padding: 24px 40px 8px 48px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.bl_pullDown__shop .bl_pullDown_selected {
  max-width: calc(21em + 88px);
}

.bl_pullDown__user .bl_pullDown_selected {
  max-width: calc(9em + 88px);
}

.bl_pullDown_selected:before, .bl_pullDown_selected:after {
  content: "";
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: calc(50% + 8px);
  transform: translateY(-50%);
}

.bl_pullDown_selected:before {
  left: 16px;
}

.bl_pullDown_selected:after {
  right: 24px;
}

.bl_pullDown_list {
  transition-property: bottom opacity;
  opacity: 0;
  z-index: 10;
  border: 1px solid #e6e6e6;
  padding-top: 9px;
  transition-duration: .3s;
  position: absolute;
  top: 40px;
  overflow: hidden;
  transform: translateY(-120%);
}

.bl_pullDown_item > a {
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  padding: 16px 24px 16px 36px;
  display: block;
  overflow: hidden;
}

.bl_pullDown__shop .bl_pullDown_item > a {
  max-width: calc(21em + 48px);
}

.bl_pullDown_item > a:hover {
  background-color: #e6e6e6;
}

.bl_pullDown_item > span {
  color: #f3680b;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  padding: 6px 24px;
  font-weight: bold;
  display: block;
  overflow: hidden;
}

.bl_pullDown__shop .bl_pullDown_item > span {
  max-width: calc(21em + 48px);
}

@media screen and (max-width: 1024px) {
  .bl_pullDown {
    border-left: none;
    border-right: 1px solid #e6e6e6;
  }

  .bl_pullDown__shop {
    flex: 0 0 55%;
  }

  .bl_pullDown__shop .bl_pullDown_selected {
    width: 100%;
    max-width: calc(49.5vw - 48px);
    padding: 16px 16px 16px 42px;
  }

  .bl_pullDown__shop .bl_pullDown_list {
    padding-top: 2px;
  }

  .bl_pullDown__user {
    flex: 0 0 45%;
  }

  .bl_pullDown__user .bl_pullDown_selected {
    width: 100%;
    max-width: calc(40.5vw - 48px);
    padding: 16px 0 16px 32px;
  }

  .bl_pullDown__user .bl_pullDown_list {
    left: 0;
    right: auto !important;
  }

  .bl_pullDown:last-child {
    border-right: none;
  }

  .bl_pullDown_selected {
    margin-top: 0;
    padding: 16px 24px 16px 34px;
  }

  .bl_pullDown_selected:before, .bl_pullDown_selected:after {
    top: 50%;
  }

  .bl_pullDown_selected:before {
    left: 8px;
  }

  .bl_pullDown_selected:after {
    right: 0;
  }
}

@media screen and (max-width: 480px) {
  .bl_pullDown__shop .bl_pullDown_item > a {
    max-width: calc(21em + 100vw - 432px);
  }
}

.bl_pullDown__shop .bl_pullDown_list {
  left: 0;
}

.bl_pullDown__shop .bl_pullDown_selected:before {
  width: 18px;
  height: 16px;
  background-image: url("icon-shop.7c73ad4b.svg");
}

.bl_pullDown__shop .bl_pullDown_selected:after {
  width: 8px;
  height: 6px;
  background-image: url("down.e302d382.svg");
}

.bl_pullDown__user .bl_pullDown_list {
  right: 0;
}

.bl_pullDown__user .bl_pullDown_selected:before {
  width: 16px;
  height: 16px;
  background-image: url("icon-user.7964f034.svg");
}

.bl_pullDown.is_open .bl_pullDown_list {
  opacity: 1;
  transform: translateY(0%);
}

@media screen and (max-width: 1024px) {
  .bl_pullDown.is_open .bl_pullDown_list {
    top: 48px;
  }
}

.bl_drawer {
  color: #fff;
  font-weight: bold;
}

.bl_drawer:after {
  content: "";
  width: 100%;
  height: calc(100vh - 285px);
  background-color: #172536;
  display: block;
}

.bl_drawer_item {
  border-bottom: 1px solid #fff;
  position: relative;
}

.bl_drawer_item__top .bl_drawer_icon {
  background-image: url("icon-top.78432dd5.svg");
}

.bl_drawer_item__privilege .bl_drawer_icon {
  background-image: url("icon-review.0221a1d6.svg");
}

.bl_drawer_item__mail .bl_drawer_icon {
  background-image: url("icon-mail.cb9f4fd2.svg");
}

.bl_drawer_item__analysis .bl_drawer_icon {
  background-image: url("icon-bunseki.076c612a.svg");
}

.bl_drawer_item__setting .bl_drawer_icon {
  background-image: url("icon-set.334d8c41.svg");
}

.bl_drawer_ttlWrap {
  height: 56px;
  cursor: pointer;
  background-color: #172536;
  align-items: center;
  display: flex;
  position: relative;
}

.bl_drawer_ttlWrap:after {
  content: "";
  width: 4px;
  height: 50px;
  opacity: 0;
  background-color: #fff;
  display: inline-block;
  position: absolute;
  top: 3px;
  right: 0;
}

.bl_drawer_ttlWrap:hover {
  background-color: #3d4854;
  transition: all .3s;
}

.bl_drawer_ttlWrap:hover:after {
  opacity: 1;
  transition: all .3s;
}

.bl_drawer_ttlWrap:before {
  content: "";
  width: 10px;
  height: 18px;
  transform-origin: center;
  background-image: url("move.7c0b50ae.svg");
  background-repeat: no-repeat;
  transition-property: transform;
  transition-duration: .3s;
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

.bl_drawer_item__top .bl_drawer_ttlWrap:before {
  display: none !important;
}

.bl_drawer_icon {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 16px;
  margin-right: 16px;
  display: inline-block;
}

.bl_drawer_subList {
  overflow-y: hidden;
}

.bl_drawer_subList > li > a {
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 56px;
  display: block;
  position: relative;
}

.bl_drawer_subList > li > a:after {
  content: "";
  width: 4px;
  height: 50px;
  opacity: 0;
  background-color: #fff;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.bl_drawer_subList > li > a:hover {
  background-color: #3d4854;
  transition: all .3s;
}

.bl_drawer_subList > li > a:hover:after {
  opacity: 1;
  transition: all .3s;
}

.bl_drawer_subList > li > a.is_current {
  background-color: #3f81cb;
}

.bl_drawer_subList > li > a.is_current:after {
  opacity: 1;
  transition: all .3s;
}

@media screen and (min-width: 1025px) {
  .bl_drawer:not(.is_open) .bl_drawer_ttl {
    display: none;
  }

  .bl_drawer:not(.is_open) .bl_drawer_subList {
    position: absolute;
  }

  .bl_drawer:not(.is_open) .bl_drawer_subList > li > a {
    padding: 17px;
  }

  .bl_drawer:not(.is_open) .bl_drawer_item:not(.is_open) .bl_drawer_subList {
    width: 200px;
    height: auto;
    z-index: -10;
    background-color: #172536;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 16px;
    transition-duration: .3s;
    top: 0;
    left: calc(-100% - 200px);
  }

  .bl_drawer:not(.is_open) .bl_drawer_item.is_open .bl_drawer_ttlWrap {
    background-color: #3d4854;
  }

  .bl_drawer:not(.is_open) .bl_drawer_item.is_open .bl_drawer_subList {
    width: 200px;
    height: auto;
    z-index: -5;
    background-color: #172536;
    border-top: none;
    transition-duration: .3s;
    top: 0;
    left: calc(100% + 2px);
  }

  .bl_drawer.is_open {
    width: 240px;
  }

  .bl_drawer.is_open .bl_drawer_item:not(.is_open) .bl_drawer_ttlWrap {
    position: relative;
  }

  .bl_drawer.is_open .bl_drawer_item:not(.is_open) .bl_drawer_ttlWrap:before, .bl_drawer.is_open .bl_drawer_item:not(.is_open) .bl_drawer_ttl {
    display: block;
  }

  .bl_drawer.is_open .bl_drawer_item:not(.is_open) .bl_drawer_subList {
    height: 0;
    position: static;
  }

  .bl_drawer.is_open .bl_drawer_item.is_open .bl_drawer_ttlWrap {
    position: relative;
  }

  .bl_drawer.is_open .bl_drawer_item.is_open .bl_drawer_ttlWrap:before {
    display: block;
    transform: rotate(90deg);
  }

  .bl_drawer.is_open .bl_drawer_item.is_open .bl_drawer_subList {
    height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    transition-duration: .3s;
  }

  .bl_drawer.is_open .bl_drawer_item.is_open .bl_drawer_subList > li > a {
    padding: 17px 17px 17px 56px;
  }

  .bl_drawer_item.is_open .bl_drawer_subList {
    border-top: 1px solid #fff;
  }
}

@media screen and (max-width: 1024px) {
  .bl_drawer {
    width: 240px;
    transition-property: transform;
    transition-duration: .3s;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .bl_drawer_item:first-child {
    border-top: 1px solid #fff;
  }

  .bl_drawer:not(.is_open_mobile) .bl_drawer_ttl {
    display: none;
  }

  .bl_drawer:not(.is_open_mobile) .bl_drawer_subList {
    position: absolute;
  }

  .bl_drawer:not(.is_open_mobile) .bl_drawer_subList > li > a {
    padding: 17px;
  }

  .bl_drawer:not(.is_open_mobile) .bl_drawer_item:not(.is_open) .bl_drawer_subList {
    width: 200px;
    height: auto;
    z-index: -10;
    background-color: #172536;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 16px;
    transition-duration: .3s;
    top: 0;
    left: calc(-100% - 200px);
  }

  .bl_drawer:not(.is_open_mobile) .bl_drawer_item.is_open .bl_drawer_ttlWrap {
    background-color: #3d4854;
  }

  .bl_drawer:not(.is_open_mobile) .bl_drawer_item.is_open .bl_drawer_subList {
    width: 200px;
    height: auto;
    z-index: -5;
    background-color: #172536;
    border-top: none;
    transition-duration: .3s;
    top: 0;
    left: calc(100% + 2px);
  }

  .bl_drawer:not(.is_open_mobile) .bl_drawer_ttl {
    display: block;
  }

  .bl_drawer:not(.is_open_mobile) .bl_drawer_item.is_open .bl_drawer_subList {
    width: 200px;
    height: auto;
    z-index: -10;
    background-color: #172536;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 16px;
    transition-duration: .3s;
    top: 0;
    left: calc(-100% - 200px);
  }

  .bl_drawer.is_open_mobile {
    width: 240px;
    transform: translateX(0%);
  }

  .bl_drawer.is_open_mobile .bl_drawer_item:not(.is_open) .bl_drawer_ttlWrap {
    position: relative;
  }

  .bl_drawer.is_open_mobile .bl_drawer_item:not(.is_open) .bl_drawer_ttlWrap:before, .bl_drawer.is_open_mobile .bl_drawer_item:not(.is_open) .bl_drawer_ttl {
    display: block;
  }

  .bl_drawer.is_open_mobile .bl_drawer_item:not(.is_open) .bl_drawer_subList {
    height: 0;
    position: static;
  }

  .bl_drawer.is_open_mobile .bl_drawer_item.is_open .bl_drawer_ttlWrap {
    position: relative;
  }

  .bl_drawer.is_open_mobile .bl_drawer_item.is_open .bl_drawer_ttlWrap:before {
    display: block;
    transform: rotate(90deg);
  }

  .bl_drawer.is_open_mobile .bl_drawer_item.is_open .bl_drawer_subList {
    height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    transition-duration: .3s;
  }

  .bl_drawer.is_open_mobile .bl_drawer_item.is_open .bl_drawer_subList > li > a {
    padding: 17px 17px 17px 56px;
  }

  .bl_drawer_subList {
    background-color: #172536;
  }
}

.bl_loginForm_logoutNote {
  text-align: center;
  background-color: #e6e6e6;
  border-radius: 4px;
  margin-bottom: 48px;
  padding: 16px;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .bl_loginForm_logoutNote {
    margin-bottom: 24px;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake .5s;
}

.bl_passForm, .bl_inviteForm {
  max-width: 720px;
  height: auto;
  background-color: #fff;
  border-radius: 6px;
  padding: 80px;
}

@media screen and (max-width: 480px) {
  .bl_passForm, .bl_inviteForm {
    width: auto;
    height: auto;
    padding: 24px;
  }
}

.bl_modalBg {
  width: 100%;
  height: 100vh;
  z-index: 35;
  background-color: #242424cc;
  position: fixed;
  top: 0;
  left: 0;
}

div.show_detail .ly_body {
  z-index: 30;
  position: relative;
}

div.show_detail .ly_main {
  z-index: 30;
}

.bl_modal {
  width: 496px;
  max-width: calc(100% - 48px);
  background-color: #fff;
  border-radius: 6px;
  padding: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #0000001a;
}

.bl_modal_header {
  background-color: #f3680b;
  border-radius: 6px 6px 0 0;
  justify-content: space-between;
  margin-top: -24px;
  margin-left: -24px;
  margin-right: -24px;
  padding: 16px 24px;
  display: flex;
}

.bl_modal_ttl {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.bl_modal_table {
  max-height: calc(100vh - 148px);
  white-space: nowrap;
  margin-top: 24px;
  display: block;
  overflow-y: auto;
}

.bl_modal_table::-webkit-scrollbar {
  appearance: none;
  width: 12px;
  height: 12px;
}

.bl_modal_table::-webkit-scrollbar-thumb {
  background-color: #3f81cb;
  border-radius: 6px;
}

.bl_modal_table::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 6px;
  margin-top: 0;
}

.bl_modal_table th, .bl_modal_table td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.bl_modal_table th {
  padding-right: 32px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .bl_modal_table {
    max-height: 300px;
  }
}

.bl_modal.bl_modal__mailPreview {
  width: 672px;
}

.bl_modal.bl_modal__mailPreview .bl_modal_mailHeader_row {
  border-bottom: 1px solid #e6e6e6;
  padding-top: 24px;
  padding-bottom: 16px;
  display: flex;
}

.bl_modal.bl_modal__mailPreview .bl_modal_mailHeader_row > dt {
  flex: 0 0 66px;
  font-weight: bold;
}

.bl_modal.bl_modal__mailPreview .bl_modal_mailHeader_row > dd {
  color: #b3b3b3;
  flex: 0 0 558px;
}

.bl_modal.bl_modal__mailPreview .bl_modal_mailBody {
  max-height: calc(100vh - 276px);
  padding-top: 24px;
  position: relative;
}

.bl_modal.bl_modal__mailPreview .bl_modal_mailBodyInner {
  height: 611px;
  max-height: calc(100vh - 292px);
  white-space: pre-wrap;
  border: 1px solid #e6e6e6;
  padding: 24px 20px 24px 24px;
  overflow-y: auto;
}

.bl_modal.bl_modal__mailPreview .bl_modal_mailBodyInner::-webkit-scrollbar {
  appearance: none;
  width: 12px;
  height: 12px;
}

.bl_modal.bl_modal__mailPreview .bl_modal_mailBodyInner::-webkit-scrollbar-thumb {
  background-color: #3f81cb;
  border-radius: 6px;
}

.bl_modal.bl_modal__mailPreview .bl_modal_mailBodyInner::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 6px;
  margin-top: 0;
}

@media screen and (max-width: 480px) {
  .bl_modal.bl_modal__mailPreview > .bl_modal_mailBody, .bl_modal.bl_modal__mailPreview .bl_modal_mailBodyInner {
    max-height: 120px;
  }
}

.bl_modal.bl_modal__shopPreview {
  width: 912px;
  max-width: calc(100% - 64px);
  font-family: メイリオ, Meiryo, ヒラギノ角ゴシック, Hiragino Sans, sans-serif;
  font-weight: normal;
  line-height: 1.9;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body {
  max-height: calc(100vh - 116px);
  padding: 16px 24px 48px;
  overflow-y: auto;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body::-webkit-scrollbar {
  appearance: none;
  width: 12px;
  height: 12px;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body::-webkit-scrollbar-thumb {
  background-color: #3f81cb;
  border-radius: 6px;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 6px;
  margin-top: 0;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body .bl_modal_bodyTtl {
  border-bottom: 1px solid #242424;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body .bl_modal_imgWrap {
  height: 96px;
  margin-bottom: 32px;
  position: relative;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body .bl_modal_imgWrap > img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.bl_modal.bl_modal__shopPreview .bl_modal_body .bl_modal_thankyou {
  text-align: center;
  font-size: 16px;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body .bl_modal_complete {
  text-align: center;
  color: #e61717;
  border: 2px solid;
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body .el_roundBtn:hover {
  background-color: gray;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body .bl_modal_btnWrap {
  text-align: center;
  margin-bottom: 48px;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body .bl_modal_notes {
  list-style-type: square;
  list-style-position: inside;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body .bl_modal_notes > li {
  line-height: 1.9;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body > * {
  margin-bottom: 32px;
}

.bl_modal.bl_modal__shopPreview .bl_modal_body a[href^="tel:"] {
  color: inherit;
  pointer-events: none;
  text-decoration: none;
}

@media screen and (max-width: 480px) {
  .bl_modal.bl_modal__shopPreview .bl_modal_body {
    max-height: 300px;
  }
}

.bl_selectProduct {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.bl_selectProduct:after {
  content: "";
  border: 8px solid #0000;
  border-left-width: 0;
  border-right-color: #3f81cb;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bl_selectProduct_selected {
  max-width: 460px;
  min-width: 0;
  overflow-wrap: break-word;
  flex: 0 460px;
  margin-right: 40px;
  position: relative;
}

.bl_selectProduct_selected textarea {
  resize: none;
  width: 100%;
  height: 435px;
  border: 1px solid #e6e6e6;
  padding: 16px;
}

.bl_selectProduct_selected textarea::-webkit-scrollbar {
  appearance: none;
  width: 12px;
  height: 12px;
}

.bl_selectProduct_selected textarea::-webkit-scrollbar-thumb {
  background-color: #3f81cb;
  border-radius: 6px;
}

.bl_selectProduct_selected textarea::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 6px;
  margin-top: 0;
}

.bl_selectProduct_selected .textarea {
  resize: none;
  max-width: 100%;
  width: 100%;
  height: 435px;
  border: 1px solid #e6e6e6;
  padding: 16px;
  line-height: 1.5;
  overflow: hidden auto;
}

.bl_selectProduct_selected .textarea::-webkit-scrollbar {
  appearance: none;
  width: 12px;
  height: 12px;
}

.bl_selectProduct_selected .textarea::-webkit-scrollbar-thumb {
  background-color: #3f81cb;
  border-radius: 6px;
}

.bl_selectProduct_selected .textarea::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 6px;
  margin-top: 0;
}

.bl_selectProduct_selected .textarea.disabled {
  filter: grayscale() contrast(75%);
  background-color: #fff;
}

.bl_selectProduct_selected .textarea[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  color: #aaa;
  display: block;
}

.bl_selectProduct_selected .textarea span[data-groupid] {
  color: #fff;
  background-color: #3f81cb;
  border-radius: 20px;
  margin: 1px;
  padding: 4px 8px 4px 24px;
  display: inline-block;
  position: relative;
}

.bl_selectProduct_selected .textarea span[data-groupid] > span:after {
  content: "";
  width: 22px;
  height: 100%;
  cursor: pointer;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.bl_selectProduct_selected .textarea span[data-groupid]:before, .bl_selectProduct_selected .textarea span[data-groupid]:after {
  content: "";
  transform-origin: center;
  cursor: pointer;
  background-color: #fff;
  display: inline-block;
  position: absolute;
}

.bl_selectProduct_selected .textarea span[data-groupid]:before {
  width: 10px;
  height: 1px;
  top: 50%;
  left: 6px;
  transform: translateY(calc(-50% - .5px))translateX(.5px)rotate(45deg);
}

.bl_selectProduct_selected .textarea span[data-groupid]:after {
  width: 1px;
  height: 10px;
  top: 50%;
  left: 11px;
  transform: translateY(calc(-50% - .5px))rotate(45deg);
}

.bl_selectProduct_selected > span {
  color: #fff;
  background-color: #3f81cb;
  border-radius: 20px;
  padding: 4px 8px 4px 24px;
  position: absolute;
}

.bl_selectProduct_selected > span > span:after {
  content: "";
  width: 22px;
  height: 22px;
  cursor: pointer;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 1px;
}

.bl_selectProduct_selected > span:before, .bl_selectProduct_selected > span:after {
  content: "";
  transform-origin: center;
  cursor: pointer;
  background-color: #fff;
  display: inline-block;
  position: absolute;
}

.bl_selectProduct_selected > span:before {
  width: 10px;
  height: 1px;
  top: 50%;
  left: 6px;
  transform: translateY(calc(-50% - .5px))translateX(.5px)rotate(45deg);
}

.bl_selectProduct_selected > span:after {
  width: 1px;
  height: 10px;
  top: 50%;
  left: 11px;
  transform: translateY(calc(-50% - .5px))rotate(45deg);
}

.bl_selectProduct_selection {
  flex: 0 460px;
}

.bl_selectProduct_selectionTab {
  display: flex;
}

.bl_selectProduct_selectionTab > li {
  color: #b3b3b3;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid;
  border-radius: 4px 4px 0 0;
  margin-right: 8px;
  padding: 8px 16px 8px 34px;
  font-weight: bold;
  position: relative;
}

.bl_selectProduct_selectionTab > li:last-child {
  margin-right: 0;
}

.bl_selectProduct_selectionTab > li.is_active {
  color: #fff;
  background-color: #3f81cb;
  border-color: #0000;
}

.bl_selectProduct_selectionTab > li.is_active:before {
  background-image: url("cplus.70b1309d.svg");
}

.bl_selectProduct_selectionTab > li:before {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("cplus-g.a6f47337.svg");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.bl_selectProduct_selectionInner {
  display: none;
  position: relative;
}

.bl_selectProduct_selectionInner.is_active {
  display: block;
}

.bl_selectProduct_group {
  height: 260px;
  border: 1px solid #3f81cb;
  padding: 16px;
  overflow-y: auto;
}

.bl_selectProduct_group li {
  margin-bottom: 16px;
  padding: 1px 0;
  line-height: 1;
}

.bl_selectProduct_group .el_checkMark {
  margin-right: 8px;
}

.bl_selectProduct_group::-webkit-scrollbar {
  appearance: none;
  width: 12px;
  height: 12px;
}

.bl_selectProduct_group::-webkit-scrollbar-thumb {
  background-color: #3f81cb;
  border-radius: 6px;
}

.bl_selectProduct_group::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 6px;
  margin-top: 0;
}

.bl_selectProduct_price {
  border: 1px solid #3f81cb;
  padding: 16px;
}

.bl_selectProduct_price > * {
  margin-bottom: 16px;
  display: block;
}

.bl_selectProduct_price > :last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 480px) {
  .bl_selectProduct {
    flex-direction: column;
    display: flex;
  }

  .bl_selectProduct:after {
    transform: translate(-50%, -50%)rotate(90deg);
  }

  .bl_selectProduct_selected {
    margin-bottom: 40px;
    margin-right: 0;
  }
}

.bl_progressTracker {
  margin-bottom: 16px;
  display: flex;
}

.bl_progressTracker_item:first-child {
  clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 50%, calc(100% - 16px) 100%, 0 100%);
  margin-left: 0;
  padding-left: 16px;
}

.bl_progressTracker_item {
  clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 50%, calc(100% - 16px) 100%, 0 100%, 16px 50%);
  color: #b3b3b3;
  background-color: #fff;
  margin-left: -14px;
  padding: 8px 32px;
  font-weight: bold;
}

.bl_progressTracker_item:not(.is_active):hover {
  color: #3f81cb;
}

.bl_progressTracker_item.is_active {
  color: #fff;
  background-color: #3f81cb;
}

.bl_progressTracker_step {
  margin-bottom: 4px;
  display: block;
}

.bl_progressTracker_title {
  font-size: 18px;
}

@media screen and (max-width: 1024px) {
  .bl_progressTracker_item:first-child {
    clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%, 0 100%);
    padding-left: 12px;
  }

  .bl_progressTracker_item {
    clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%, 0 100%, 8px 50%);
    margin-left: -5px;
    padding: 10px 16px;
  }

  .bl_progressTracker_step {
    margin-bottom: 0;
  }

  .bl_progressTracker_ttl {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .bl_progressTracker_item:first-child {
    clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%, 0 100%);
    padding-left: 2.5vw;
  }

  .bl_progressTracker_item {
    clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%, 0 100%, 8px 50%);
    margin-left: -1.041vw;
    padding: 2.083vw 3.333vw;
  }

  .bl_progressTracker_step {
    font-size: 2.916vw;
  }
}

.bl_mailTemp:first-child > .bl_mailTemp_ttl {
  margin-top: 0;
}

.bl_mailTemp_ttl {
  color: #fff;
  cursor: pointer;
  background-color: #3f81cb;
  margin-top: 24px;
  padding: 16px 48px 16px 16px;
  font-weight: bold;
  position: relative;
}

.bl_mailTemp_ttl:before, .bl_mailTemp_ttl:after {
  content: "";
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
}

.bl_mailTemp_ttl:after {
  width: 23px;
  height: 2px;
  right: 16px;
  transform: translateY(-50%);
}

.bl_mailTemp_ttl:before {
  width: 2px;
  height: 23px;
  right: 27px;
  transform: translateX(.5px)translateY(-50%);
}

.bl_mailTemp_ttl.is_active:before {
  display: none;
}

.bl_mailTemp_ttl.is_active + .bl_mailTemp_body {
  display: block;
}

.bl_mailTemp_body {
  padding: 24px 0;
  display: none;
}

.bl_mailTemp_selectTemp {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.bl_mailTemp_selectTemp:after {
  content: "";
  width: 8px;
  height: 6px;
  pointer-events: none;
  background-image: url("down.e302d382.svg");
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 50%;
  right: 126px;
  transform: translateY(-50%);
}

.bl_mailTemp_selectTemp > select {
  flex: 0 auto;
  margin-right: 16px;
}

.bl_mailTemp_selectTemp > .el_note {
  flex: none;
}

.bl_mailTemp_test {
  justify-content: space-between;
  display: flex;
}

.bl_mailTemp_test > .el_btnInv {
  flex: none;
  margin-right: 8px;
}

.bl_confirm_ttl {
  color: #fff;
  margin-right: 8px;
  font-size: 18px;
  font-weight: bold;
}

.bl_confirm_mailSection.bl_confirm_mailSection__disabled {
  color: #b3b3b3;
}

.bl_confirm_mailSection_ttl {
  color: #3f81cb;
  cursor: pointer;
  border-bottom: 2px solid;
  margin-bottom: 16px;
  padding: 8px 0;
  font-size: 18px;
  font-weight: bold;
  position: relative;
}

.bl_confirm_mailSection_ttl:before, .bl_confirm_mailSection_ttl:after {
  content: "";
  background-color: #3f81cb;
  display: inline-block;
  position: absolute;
}

.bl_confirm_mailSection_ttl:before {
  width: 2px;
  height: 23px;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
}

.bl_confirm_mailSection_ttl:after {
  width: 23px;
  height: 2px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.bl_confirm_mailSection_ttl + div, .bl_confirm_mailSection_ttl.is_open:before {
  display: none;
}

.bl_confirm_mailSection_ttl.is_open + div {
  display: block;
}

.bl_confirm p {
  margin-bottom: 16px;
}

.bl_confirm p:last-child {
  margin-bottom: 0;
}

.bl_confirm .el_incomplete {
  font-size: 14px;
}

.bl_confirm_row {
  margin-bottom: 24px;
}

.bl_confirm_row.bl_confirm_row__disabled {
  color: #b3b3b3;
}

.bl_confirm_row > .bl_selectProduct_selected {
  flex: none;
  margin-right: 0;
}

.bl_confirm_row > .bl_selectProduct_selected > textarea {
  height: 240px;
}

.bl_confirm_row ul.bl_errorStep_list > li {
  color: #3f81cb;
  margin: 12px;
  font-size: 13px;
  font-weight: bold;
}

.bl_confirm_row ul.bl_errorTarget_list {
  margin-bottom: 12px;
}

.bl_confirm_row ul.bl_errorTarget_list > li {
  color: #e83131;
  margin: 8px 0 8px 12px;
  font-size: 13px;
}

.bl_confirm_row ul.bl_errorTarget_list > li:before {
  content: "";
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background-image: url("icon-attention.15de58b9.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  margin-right: 4px;
  display: inline-block;
}

.bl_confirm_row ul.bl_errorTarget_list > li > a {
  font-weight: bold;
  text-decoration: underline;
}

.bl_confirm_row ul.bl_errorTarget_list > li > a:hover {
  color: #6896cb;
}

.bl_confirm_ttlWrap {
  background-color: #3f81cb;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 8px;
  display: flex;
}

.bl_confirm_ttlWrap > button {
  white-space: nowrap;
  color: #3f81cb;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 16px 4px;
  font-size: 12px;
  font-weight: bold;
}

.bl_analysisCap {
  position: relative;
}

.bl_analysisCap > p {
  margin-bottom: 8px;
  font-size: 12px;
}

.bl_analysisCap > p .el_btnTxt {
  margin-left: 8px;
}

.bl_graph_header, .bl_graph_header .bl_analysisCap, .bl_graph_body {
  margin-bottom: 24px;
}

.bl_graph_footer {
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.bl_graph_footer > span {
  margin-right: 16px;
}

.bl_graph_footer .el_btnTerm:not(:last-child) {
  margin-right: 8px;
}

.bl_graph_footer.bl_graph_footer__limit {
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  display: flex;
}

.bl_graph_footer.bl_graph_footer__limit > div {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -8px;
  padding-bottom: 16px;
  display: flex;
}

.bl_graph_footer.bl_graph_footer__limit > div > * {
  margin-bottom: 8px;
}

@media screen and (max-width: 380px) {
  .bl_graph_footer {
    justify-content: flex-start;
  }

  .bl_graph_footer > * {
    margin-bottom: 6px;
  }

  .bl_graph_footer__limit > div > .el_termCaption {
    text-align-last: left;
    flex: 0 0 100%;
  }
}

.bl_graph_legend {
  flex-wrap: wrap;
  margin-bottom: -16px;
  padding-bottom: 24px;
  display: flex;
}

.bl_graph_legend_item {
  align-items: center;
  margin-bottom: 16px;
  margin-right: 24px;
  display: flex;
}

.bl_graph_legend_item > * {
  margin-right: 8px;
}

.bl_graph_legend_item > :last-child {
  margin-right: 0;
}

.bl_graph_legend_item[class*="Num"] .bl_graph_legendGraphic {
  background-color: currentColor;
}

.bl_graph_legend_item[class*="Review"] .bl_graph_legendGraphic, .bl_graph_legend_item[class*="num"] .bl_graph_legendGraphic {
  position: relative;
}

.bl_graph_legend_item[class*="Review"] .bl_graph_legendGraphic:before, .bl_graph_legend_item[class*="Review"] .bl_graph_legendGraphic:after, .bl_graph_legend_item[class*="num"] .bl_graph_legendGraphic:before, .bl_graph_legend_item[class*="num"] .bl_graph_legendGraphic:after {
  content: "";
  background-color: currentColor;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bl_graph_legend_item[class*="Review"] .bl_graph_legendGraphic:before, .bl_graph_legend_item[class*="num"] .bl_graph_legendGraphic:before {
  width: 100%;
  height: 1px;
}

.bl_graph_legend_item[class*="Review"] .bl_graph_legendGraphic:after, .bl_graph_legend_item[class*="num"] .bl_graph_legendGraphic:after {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.bl_graph_legend_item__coveredNum .bl_graph_legendGraphic {
  color: #225c97;
}

.bl_graph_legend_item__notCoveredNum .bl_graph_legendGraphic {
  color: #67a6e6;
}

.bl_graph_legend_item__coveredReview .bl_graph_legendGraphic {
  color: #a844ff;
}

.bl_graph_legend_item__notCoveredReview .bl_graph_legendGraphic {
  color: #ffde20;
}

.bl_graph_legend_item__allReview .bl_graph_legendGraphic {
  color: #f368ae;
}

.bl_graph_legend_item__num .bl_graph_legendGraphic {
  color: #3f81cb;
}

.bl_graph_legendGraphic {
  width: 48px;
  height: 18px;
  flex: 0 auto;
  display: inline-block;
}

.bl_graph_legendTxt {
  font-weight: bold;
}

.bl_graph_legend .bl_graph_legend_item .el_toggleBtn {
  width: 48px;
  height: 18px;
  flex: none;
}

.bl_graph_legend .bl_graph_legend_item .el_toggleBtn > input[type="checkbox"] {
  width: 48px;
  height: 18px;
}

.bl_graph_legend .bl_graph_legend_item .el_toggleBtn > input[type="checkbox"]:checked + span {
  left: 32px;
}

.bl_graph_legend .bl_graph_legend_item .el_toggleBtn > input[type="checkbox"]:checked + span:before {
  left: -24px;
}

.bl_graph_legend .bl_graph_legend_item .el_toggleBtn > span {
  width: 14px;
  height: 14px;
}

.bl_graph_legend .bl_graph_legend_item .el_toggleBtn > span:before, .bl_graph_legend .bl_graph_legend_item .el_toggleBtn > span:after {
  font-size: 10px;
}

.bl_graph_legend .bl_graph_legend_item .el_toggleBtn > span:before {
  content: "ON";
}

.bl_graph_legend .bl_graph_legend_item .el_toggleBtn > span:after {
  content: "OFF";
  right: -24px;
}

.bl_productNumberUploader > label {
  margin-bottom: 8px;
  display: inline-block;
}

.bl_productNumberUploader input[type="file"] {
  display: none;
}

.bl_productNumberUploader > p {
  margin-bottom: 8px;
  font-size: 12px;
}

.bl_productNumberUploader textarea {
  height: 136px;
}

.bl_sortableField {
  padding: 16px 8px 0;
  display: flex;
}

.bl_sortableField_knob {
  width: 16px;
  cursor: grab;
  background-color: #e6e6e6;
  background-image: url("b3.d1df18ae.svg");
  background-position: center;
  background-repeat: no-repeat;
  flex: none;
  margin-right: 8px;
}

.bl_sortableField_txtWrap {
  flex: auto;
}

.bl_sortableField_txtWrap_header {
  margin-bottom: 8px;
  display: flex;
}

.bl_sortableField_txtWrap_header .el_selectWrap {
  flex: auto;
  margin-right: 16px;
}

.bl_sortableField_txtWrap_header .el_btnBlueInv {
  flex: none;
}

.bl_sortableField_txtWrap > textarea {
  width: calc(100% - 78px);
  height: 88px;
}

.bl_panel_row__indent .bl_sortableField {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 480px) {
  .bl_sortableField_txtWrap > textarea {
    width: 100%;
  }
}

.bl_addSentence {
  cursor: pointer;
  border: 1px dashed #e6e6e6;
  margin-top: 16px;
  padding: 8px;
}

.bl_addSentence > p {
  text-align: center;
  color: #b3b3b3;
  font-weight: bold;
  position: relative;
}

.bl_addSentence > p:after {
  content: "";
  width: 14px;
  height: 14px;
  background-image: url("plus-g.5ff4903a.svg");
  background-repeat: no-repeat;
  margin-left: 4px;
  display: inline-block;
  transform: translateY(1px);
}

.bl_doughnutWrap {
  margin-bottom: 22px;
  position: relative;
}

.bl_doughnutWrap .el_tooltip {
  height: 84px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bl_doughnutWrap .el_tip {
  font-size: 2.08333vw;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bl_doughnutWrap .el_tip:before {
  content: "";
  width: 26px;
  width: 1.35416vw;
  height: 24px;
  height: 1.25vw;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto .41666vw;
  display: block;
}

.bl_doughnutWrap .el_tip.el_tip__1 {
  color: #3d4854;
}

.bl_doughnutWrap .el_tip.el_tip__1:before {
  background-image: url("rank-1.eb781340.svg");
}

.bl_doughnutWrap .el_tip.el_tip__2 {
  color: #47c5e6;
}

.bl_doughnutWrap .el_tip.el_tip__2:before {
  background-image: url("rank-2.e92a0cab.svg");
}

.bl_doughnutWrap .el_tip.el_tip__3 {
  color: #eba707;
}

.bl_doughnutWrap .el_tip.el_tip__3:before {
  background-image: url("rank-3.96aa7365.svg");
}

.bl_doughnutWrap .el_tip.el_tip__other {
  color: #92e831;
}

.bl_doughnutWrap .el_tip.el_tip__other:before {
  content: "その他";
  width: fit-content;
  font-size: .625vw;
}

.bl_canvasUnit {
  justify-content: space-between;
  display: flex;
}

.bl_canvasUnit > .bl_canvasWrap {
  max-width: 46%;
  flex: 0 0 50%;
}

.bl_canvasWrap {
  margin-bottom: 24px;
  position: relative;
}

.bl_horizontalBarCanvasWrap {
  position: relative;
}

.bl_termSetting {
  width: 100%;
  z-index: 50;
  background-color: #fff;
  border: 1px solid #3f81cb;
  border-radius: 6px;
  padding: 24px;
  display: none;
  position: absolute;
  box-shadow: 1px 1px 4px #00000029;
}

.bl_termSetting.is_open {
  display: block;
}

.bl_termSetting_head {
  flex-wrap: wrap;
  margin-bottom: -8px;
  padding-bottom: 16px;
  display: flex;
}

.bl_termSetting_ttl {
  color: #3f81cb;
  margin-bottom: 8px;
  margin-right: 8px;
  font-weight: bold;
}

.bl_termSetting_desc {
  font-size: 12px;
}

.bl_termSetting_body, .bl_termSetting_row {
  margin-bottom: 16px;
}

.bl_termSetting_row:last-child {
  margin-bottom: 0;
}

.bl_termSetting_row:nth-child(1) {
  margin-bottom: -8px;
  padding-bottom: 24px;
}

.bl_termSetting_row:nth-child(1) > label {
  white-space: nowrap;
}

.bl_termSetting_row:nth-child(1) > label > span {
  display: inline-block;
}

.bl_termSetting_row:nth-child(1) > label > * {
  margin-bottom: 8px;
}

.bl_termSetting_row:nth-child(2) {
  margin-bottom: -8px;
}

.bl_termSetting_row:nth-child(2) > label > * {
  margin-bottom: 8px;
}

.bl_termSetting_row > label {
  margin-right: 16px;
}

.bl_termSetting_row > label:last-child {
  margin-right: 0;
}

.bl_termSetting_row > label > span {
  vertical-align: middle;
}

.bl_termSetting_row .el_inlineInputM:nth-child(2) {
  margin-left: 0;
}

.bl_termSetting_term {
  text-indent: -24px;
  margin-bottom: -8px;
  padding-left: 24px;
  display: inline-block;
}

.bl_termSetting_term:last-child {
  text-indent: 0;
  padding-left: 32px;
}

.bl_termSetting_term > * {
  margin-bottom: 8px;
}

.bl_guide h3 {
  color: #3f81cb;
  background-color: #f7fbff;
  border-left: 8px solid #3f81cb;
  margin-top: 0;
  margin-bottom: 16px;
  padding: 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.6;
}

.bl_guide > span {
  margin: 16px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.9;
}

.bl_guide a {
  color: #3f81cb;
  font-weight: 700;
  text-decoration: underline;
}

.bl_guide > ol, .bl_guide > div > div > ol {
  counter-reset: ordered-list;
  line-height: 1.9;
}

.bl_guide > ol > li, .bl_guide > div > div > ol > li {
  margin: 16px 0;
}

.bl_guide > ol > li > span, .bl_guide > ol > li p:not(.comments), .bl_guide > div > div > ol > li > span, .bl_guide > div > div > ol > li p:not(.comments) {
  text-indent: -28px;
  margin: 48px 0 32px;
  padding-left: 28px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.9;
  display: block;
  position: relative;
}

.bl_guide > ol > li > span:before, .bl_guide > ol > li p:not(.comments):before, .bl_guide > div > div > ol > li > span:before, .bl_guide > div > div > ol > li p:not(.comments):before {
  counter-increment: ordered-list;
  content: counter(ordered-list);
  width: 24px;
  height: 24px;
  text-indent: 0;
  color: #fff;
  background-color: #3f81cb;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  display: inline-flex;
}

.bl_guide > ol > li > span:after, .bl_guide > ol > li p:not(.comments):after, .bl_guide > div > div > ol > li > span:after, .bl_guide > div > div > ol > li p:not(.comments):after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #3f81cb;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.bl_guide .el_guideMenuTitle {
  background-color: unset;
  text-indent: unset;
  color: #3f81cb;
  align-items: center;
  margin: 32px 0 16px;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  display: flex;
}

.bl_guide .el_guideMenuTitle:before {
  content: "";
  width: 32px;
  height: 32px;
  border-radius: unset;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 8px;
  display: inline-block;
}

.bl_guide .el_guideMenuTitle.el_guideMenuTitle__setting:before {
  background-image: url("img-q-set.100a9123.svg");
}

.bl_guide .el_guideMenuTitle.el_guideMenuTitle__operation:before {
  background-image: url("img-q-beginner.9400c74f.svg");
}

.bl_guide .el_btnWideBorderInv {
  text-decoration: none;
}

.bl_guide .banner {
  cursor: pointer;
  width: 384px;
  max-width: 100%;
  margin-top: 48px;
  margin-inline: auto;
  transition: opacity .3s ease-out;
  display: block;
}

.bl_guide .banner img {
  height: auto;
}

.bl_guide .banner:hover {
  opacity: .6;
}

.bl_guide .banner + span {
  text-align: center;
  font-weight: 700;
}

.bl_guide .banner + span + h3 {
  margin-top: 48px;
}

.bl_guide span.banner_desc {
  text-align: center;
  font-weight: 700;
}

.bl_guide span.banner_desc + h3 {
  margin-top: 48px;
}

.bl_guideMenu {
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  gap: 16px 8px;
  margin: 16px 0 32px;
  display: grid;
}

.bl_guideMenu > li {
  border: 1px solid #e6e6e6;
  margin: 0 !important;
}

.bl_guideMenu > li > a:not(.el_btnWideBorderInv) {
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.9;
  text-decoration: none;
  transition: opacity .3s ease-out;
  display: flex;
}

.bl_guideMenu > li > a:not(.el_btnWideBorderInv):hover {
  opacity: .7;
}

.bl_guideMenu > li > a:not(.el_btnWideBorderInv):after {
  content: "";
  width: 10px;
  height: 12px;
  background-image: url("icon-arrow01.e074b83e.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.bl_guideMenu > li .el_btnWideBorderInv {
  cursor: pointer;
  width: 100%;
  line-height: 1.2;
}

.bl_guideMenu__unique {
  grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
}

.bl_guideMenu__unique > li {
  border: none;
}

@media screen and (max-width: 480px) {
  .bl_guideMenu {
    grid-template-columns: 1fr;
  }
}

.bl_guideNav {
  margin-bottom: 24px;
}

.bl_guideNav_title {
  color: #3f81cb;
  border-bottom: 2px solid #3f81cb;
  margin-bottom: 16px;
  padding-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
}

.bl_guideNav_list {
  margin: 8px 0;
}

.bl_guideNav_item > a {
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 700;
  transition: opacity .3s ease-out;
  display: flex;
}

.bl_guideNav_item > a:hover {
  opacity: .7;
}

.bl_guideNav_item > a.selectGuide:after {
  transform: rotate(90deg);
}

.bl_guideNav_item > a:after {
  content: "";
  width: 14px;
  height: 16px;
  background-image: url("icon-arrow01.e074b83e.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

.bl_guideNav_item .el_btnWideBorderInv {
  margin-top: 24px;
  line-height: 1.2;
}

.bl_guideNav_item_index.index_h3 > a {
  padding-left: 8px;
}

.bl_guideNav_item_index.index_h4 > a {
  padding-left: 16px;
}

.bl_guideNav_item_index > a {
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 13px;
  font-weight: 700;
  transition: opacity .3s ease-out;
  display: flex;
}

.bl_guideNav_item_index > a:hover {
  opacity: .7;
}

.bl_guideNav_item_index > a:after {
  content: "";
  width: 10.5px;
  height: 12px;
  background-image: url("icon-arrow01.e074b83e.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

@media screen and (max-width: 1200px) {
  .bl_guideNav .is_active .bl_guideNav_title:after {
    transform: translateY(-50%)rotate(180deg);
  }

  .bl_guideNav .is_active .bl_guideNav_list {
    height: auto;
    border-bottom: 1px solid #3f81cb;
  }

  .bl_guideNav_title {
    cursor: pointer;
    border-bottom-width: 1px;
    margin-bottom: 0;
    padding: 10px 24px;
    position: relative;
  }

  .bl_guideNav_title:before, .bl_guideNav_title:after {
    content: "";
    width: 14px;
    height: 2px;
    background-color: #3f81cb;
    display: block;
    position: absolute;
  }

  .bl_guideNav_title:before {
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
  }

  .bl_guideNav_title:after {
    transition: transform .3s ease-out;
    top: 50%;
    right: 24px;
    transform: translateY(-50%)rotate(90deg);
  }

  .bl_guideNav_list {
    height: 0;
    margin: 0;
    transition: height .3s ease-out;
    overflow: hidden;
  }

  .bl_guideNav_item {
    background-color: #fafafa;
  }

  .bl_guideNav_item > a {
    padding: 17px 24px;
  }

  .bl_guideNav + .el_btnWideBorderInv {
    width: calc(100% - 48px);
    margin: 0 auto;
    display: block;
  }

  .bl_guideNav_item_index.index_h3 > a {
    padding-left: 32px;
  }

  .bl_guideNav_item_index.index_h4 > a {
    padding-left: 40px;
  }

  .bl_guideNav_item_index > a {
    padding: 17px 24px;
  }
}

.bl_guideFlow > span {
  text-align: center;
  color: #3f81cb;
  font-size: 18px;
  font-weight: 700;
}

.bl_guideFlow > ol > li h3 {
  border: none;
  align-items: center;
  margin-left: 32px;
  padding: 16px 8px 16px 48px;
  font-size: 24px;
  line-height: 1.3;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .bl_guideFlow > ol > li h3 {
    padding-left: 32px;
    font-size: 18px;
  }
}

.bl_guideFlow > ol > li h3 > span {
  width: 64px;
  height: 64px;
  color: #fff;
  background-color: #3f81cb;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  line-height: 1.2;
  display: inline-flex;
  position: absolute;
  left: -32px;
}

@media screen and (max-width: 1024px) {
  .bl_guideFlow > ol > li h3 > span {
    width: 48px;
    height: 48px;
    font-size: 22px;
  }
}

.bl_guideFlow > ol > li h3 > span:before {
  content: "STEP";
  margin-top: 5px;
  font-size: 10px;
}

@media screen and (max-width: 1024px) {
  .bl_guideFlow > ol > li h3 > span:before {
    font-size: 8px;
  }
}

.bl_guideFlow > ol > li ul {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 32px;
  display: grid;
  padding-left: 80px !important;
}

@media screen and (max-width: 1024px) {
  .bl_guideFlow > ol > li ul {
    grid-template-columns: 1fr;
    padding-left: 0 !important;
  }
}

.bl_guideFlow > ol > li ul li {
  align-content: stretch;
  display: grid;
  margin: 0 !important;
}

.bl_guideFlow > ol > li > span {
  color: #f3680b;
  text-align: center;
  text-indent: 0;
  align-items: center;
  gap: 16px;
  font-size: 24px;
  display: flex;
  padding-left: 80px !important;
}

@media screen and (max-width: 1024px) {
  .bl_guideFlow > ol > li > span {
    font-size: 18px;
    padding-left: 0 !important;
  }
}

.bl_guideFlow > ol > li > span:before, .bl_guideFlow > ol > li > span:after {
  content: "";
  height: 2px;
  width: auto;
  max-width: 100%;
  min-width: 58px;
  background: url("border01.ba6a71ce.svg") center / contain repeat-x;
  flex-grow: 1;
  position: relative;
}

.el_btnTooltip {
  color: #f3680b;
  border: 1px solid;
  border-radius: 4px;
  padding: 8px 15px;
  font-weight: bold;
  position: relative;
}

.el_btnTooltip:disabled {
  filter: grayscale() contrast(75%);
  background-color: #fff;
}

.el_btnTooltip:hover {
  background-color: #ffebcc;
}

.el_btnTooltip > .el_tooltip-text_hidden {
  display: none;
}

.el_btnTooltip > .el_tooltip-text {
  opacity: 1;
  visibility: visible;
  white-space: nowrap;
  color: #fff;
  background: #333;
  border-radius: 3px;
  padding: 5px;
  font-size: .8rem;
  line-height: 1.3;
  transition: opacity .3s ease-in;
  display: inline-block;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.el_btnTooltip > .el_tooltip-text:before {
  content: "";
  border: 7px solid #0000;
  border-bottom-color: #333;
  margin-left: -7px;
  position: absolute;
  top: -13px;
  left: 50%;
}

.bl_faqList h3 {
  color: #3f81cb;
  background-color: #f7fbff;
  border-left: 8px solid #3f81cb;
  margin-top: 0;
  margin-bottom: 16px;
  padding: 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.6;
}

.bl_faqList h4 {
  text-indent: -19px;
  color: #fff;
  background-color: #3f81cb;
  margin-top: 0;
  margin-bottom: 16px;
  padding: 8px 8px 8px 27px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.6;
}

.bl_faqList h4:before {
  content: "";
  width: 11px;
  height: 11px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.bl_faqList a {
  color: #3f81cb;
  font-weight: 700;
  text-decoration: underline;
}

.bl_faqList > ul {
  margin-bottom: 16px;
}

.bl_faqList > ul > li {
  margin: 0;
}

.bl_faqList > ul > li > span {
  padding-left: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.9;
  display: block;
  position: relative;
}

.bl_faqList > ul > li > span:before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #3f81cb;
  border-radius: 50%;
  margin-right: 4px;
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 0;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.el_panel_ttlTop {
  color: #3f81cb;
  border-bottom: 2px solid #3f81cb;
  flex-wrap: wrap;
  margin-bottom: 16px;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  display: flex;
}

.el_panel_ttlTop.el_panel_ttlTop__simple {
  border-bottom: none;
  margin-bottom: 0;
}

.el_pageTtl {
  color: #3f81cb;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
}

.el_lv2Heading {
  color: #3f81cb;
  border-bottom: 2px solid;
  margin-bottom: 16px;
  padding-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
}

.el_lv3Heading {
  color: #3f81cb;
  border-left: 2px solid;
  margin-bottom: 16px;
  padding-left: 8px;
  font-weight: bold;
}

.el_lv4Heading, .el_analysisHeading {
  color: #3f81cb;
  margin-bottom: 16px;
  font-weight: bold;
}

.el_analysisHeading > span {
  color: #242424;
  margin-left: 8px;
  font-size: 12px;
  font-weight: normal;
}

.el_withIconHeading {
  justify-content: center;
  align-items: center;
  gap: 8px 16px;
  font-size: 32px;
  display: flex;
  padding-block: 32px !important;
}

.el_withIconHeading br {
  display: none;
}

@media screen and (max-width: 1024px) {
  .el_withIconHeading {
    text-align: center;
    flex-direction: column;
    padding-block: 24px !important;
  }

  .el_withIconHeading br {
    display: initial;
  }
}

.el_withIconHeading img {
  width: 40px;
  height: auto;
}

.el_pageDesc {
  color: #3f81cb;
  margin-bottom: 24px;
}

.el_txtOrange {
  color: #f3680b;
}

.el_errorMessage {
  color: #e83131;
  margin-top: 8px;
}

.el_resultCount {
  margin-top: 16px;
  margin-bottom: 24px;
}

.el_resultCount:last-child {
  margin-bottom: 0;
}

.el_note {
  color: #f3680b;
  font-size: 12px;
  font-weight: bold;
}

button.el_note:hover {
  color: #f3aa3d;
}

.el_panel_totalNum {
  text-align: center;
  color: #f3680b;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
}

.el_panel_totalNum.is_plus {
  padding-right: 24px;
  position: relative;
}

.el_panel_totalNum.is_plus:after {
  content: "";
  border: 8px solid #0000;
  border-top-width: 0;
  border-bottom: 16px solid #f3680b;
  position: absolute;
  bottom: 5px;
  right: 0;
}

.el_panel_totalNum.is_minus {
  padding-right: 24px;
  position: relative;
}

.el_panel_totalNum.is_minus:after {
  content: "";
  border: 8px solid #0000;
  border-top: 16px solid #f3680b;
  border-bottom-width: 0;
  position: absolute;
  bottom: 5px;
  right: 0;
}

@media screen and (max-width: 1680px) {
  .el_panel_totalNum {
    font-size: 2.38095vw;
  }

  .el_panel_totalNum > span.fs_18.txt_blue {
    white-space: nowrap;
    font-size: 1.07142vw !important;
  }
}

@media screen and (max-width: 1024px) {
  .el_panel_totalNum {
    font-size: 40px;
  }

  .el_panel_totalNum > span.fs_18.txt_blue {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 640px) {
  .el_panel_totalNum {
    font-size: 3.90625vw;
  }

  .el_panel_totalNum > span.fs_18.txt_blue {
    font-size: 2.8125vw !important;
  }
}

@media screen and (max-width: 480px) {
  .el_panel_totalNum {
    font-size: 8.33333vw;
  }

  .el_panel_totalNum > span.fs_18.txt_blue {
    font-size: 3.75vw !important;
  }
}

.el_panel_totalNum_unit {
  color: #3f81cb;
  margin-left: 8px;
  font-size: 24px;
}

.el_num {
  font-size: 18px;
  font-weight: bold;
}

.el_num_unit {
  margin-left: 8px;
  font-size: 14px;
}

.el_selectedCount {
  color: #f3680b;
  font-weight: bold;
  display: inline-block;
}

.el_incomplete {
  color: #e83131;
  margin-right: 16px;
  font-weight: bold;
}

.el_selectedCategory {
  color: #3f81cb;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
}

.el_selectedStep {
  color: #3f81cb;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
}

.el_termCaption {
  margin-right: 16px;
  font-weight: bold;
}

.el_graphLimit {
  color: #f3680b;
  font-weight: bold;
}

.el_backLink {
  color: #3f81cb;
  font-weight: bold;
  text-decoration: underline;
}

.el_backLink:hover {
  color: #6896cb;
}

.el_noticeLink {
  text-align: right;
  color: #3f81cb;
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

.el_noticeLink:hover {
  color: #6896cb;
}

.el_externalLink {
  color: #3f81cb;
  font-weight: bold;
  text-decoration: underline;
}

.el_externalLink:hover {
  color: #6896cb;
}

.el_rakutenSettingDesc {
  color: #3f81cb;
  margin-bottom: 24px;
}

.el_rakutenSettingNote {
  color: #f3680b;
  margin-bottom: 24px;
}

.el_btn {
  text-align: center;
  color: #fff;
  background-image: linear-gradient(#f3960b 0%, #f3820b 68%, #f3680b 100%);
  border: 1px solid #f3aa3d;
  border-radius: 4px;
  padding: 16px 23px;
  font-size: 18px;
  font-weight: bold;
}

.el_btn:hover {
  background-image: linear-gradient(#f3aa3d 0%, #f39a3d, #f3863d 100%);
}

.el_largeBtnWrap {
  display: flex;
}

.el_largeBtnWrap:not(:last-child) {
  margin-bottom: 24px;
}

.el_largeBtnWrap > * {
  flex: 0 auto;
  margin-right: 16px;
}

@media screen and (max-width: 480px) {
  .el_largeBtnWrap > * {
    margin-left: 0;
    margin-right: 16px;
    font-size: 3.75vw;
  }

  .el_largeBtnWrap > :last-child {
    margin-right: 0;
  }
}

.el_largeBtn {
  text-align: center;
  color: #fff;
  background-image: linear-gradient(#f3960b 0%, #f3820b 68%, #f3680b 100%);
  border: 1px solid #f3aa3d;
  border-radius: 4px;
  padding: 16px 23px;
  font-size: 18px;
  font-weight: bold;
}

.el_largeBtn:hover {
  background-image: linear-gradient(#f3aa3d 0%, #f39a3d, #f3863d 100%);
}

.el_largeBtn.el_largeBtn__fullWidth {
  width: 100%;
}

.el_largeBtn:disabled {
  filter: grayscale();
}

.el_largeBtnBlueInv {
  text-align: center;
  color: #3f81cb;
  border: 1px solid;
  border-radius: 4px;
  padding: 16px 23px;
  font-size: 18px;
  font-weight: bold;
}

.el_largeBtnBlueInv:hover {
  background-color: #edf3fa;
}

.el_largeBtn.el_largeBtn__prev {
  color: #b3b3b3;
  background-color: #fff;
  background-image: none;
  border: 1px solid;
}

.el_largeBtn.el_largeBtn__prev:hover {
  background-color: #e6e6e6;
}

@media screen and (max-width: 480px) {
  .el_largeBtn.el_largeBtn__prev + .el_largeBtn {
    margin-left: 8px;
  }
}

.el_largeBtn.is_error {
  position: relative;
}

.el_largeBtn.is_error:after {
  content: "";
  width: 32px;
  height: 32px;
  pointer-events: none;
  background-image: url("ng.ce95f74c.svg");
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
}

.el_largeBtn.is_success {
  margin-right: 48px;
  animation: removeMargin 1s 5s forwards;
  position: relative;
}

@keyframes removeMargin {
  from {
    margin-right: 48px;
  }

  to {
    margin-right: 16px;
  }
}

.el_largeBtn.is_success:after {
  content: "";
  width: 32px;
  height: 32px;
  pointer-events: none;
  background-image: url("ok.20d00c6a.svg");
  animation: fadeOut 1s 5s forwards;
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.el_btnInv {
  color: #f3680b;
  border: 1px solid;
  border-radius: 4px;
  padding: 8px 15px;
  font-weight: bold;
}

.el_btnInv:disabled {
  filter: grayscale() contrast(75%);
  background-color: #fff;
}

.el_btnInv:hover {
  background-color: #ffebcc;
}

.el_btnTerm {
  color: #b3b3b3;
  border: 1px solid;
  border-radius: 4px;
  padding: 8px 15px;
  font-weight: bold;
}

.el_btnTerm.is_active {
  color: #f3680b;
  background-color: #ffebcc;
}

.el_btn {
  text-align: center;
  color: #fff;
  background-image: linear-gradient(#f3960b 0%, #f3820b 68%, #f3680b 100%);
  border: 1px solid #f3aa3d;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
}

.el_btn:hover {
  background-image: linear-gradient(#f3aa3d 0%, #f39a3d, #f3863d 100%);
}

.el_btn.el_btn__plus {
  padding-left: 34px;
  position: relative;
}

.el_btn.el_btn__plus:before {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("cplus.70b1309d.svg");
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.el_btnBlueInv {
  color: #3f81cb;
  background-color: #fff;
  border: 1px solid;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: bold;
}

.el_btnBlueInv:disabled {
  filter: grayscale() contrast(75%);
}

.el_btnBlueInv:hover {
  background-color: #edf3fa;
}

.el_btnTxt {
  color: #f3680b;
  font-size: 12px;
  font-weight: bold;
}

.el_btnTxt:hover {
  color: #f3aa3d;
}

.el_btnTxt.el_btnTxt__underLine {
  border-bottom: 1px solid #f3680b;
  padding-bottom: 1px;
  line-height: 1;
}

.el_btnTxt.el_btnTxt__underLine:hover {
  border-bottom-color: #f3aa3d;
}

.el_navToggle {
  background-image: url("menu.42c29146.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.el_linkIcon {
  background-repeat: no-repeat;
}

.el_linkIcon:hover {
  opacity: .8;
}

.el_closeBtn {
  width: 19px;
  height: 19px;
  position: relative;
}

.el_closeBtn:before, .el_closeBtn:after {
  content: "";
  width: 26.8701px;
  height: 2px;
  transform-origin: 0;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
}

.el_closeBtn:before {
  transform: rotate(45deg)translateX(-50%)translateY(calc(1px - 50%));
}

.el_closeBtn:after {
  transform: rotate(-45deg)translateX(-50%)translateY(calc(1px - 50%));
}

.el_roundBtn {
  min-width: 96px;
  color: #fff;
  background-color: gray;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.8;
  display: inline-block;
}

.el_roundBtn:hover {
  background-color: #808080cc;
}

.el_btnWideBorderInv {
  width: 384px;
  max-width: 100%;
  text-align: center;
  color: #3f81cb;
  border: 2px solid;
  border-radius: 4px;
  padding: 16px;
  font-size: 18px;
  font-weight: 700;
  transition: opacity .3s ease-out;
  display: inline-block;
}

.el_btnWideBorderInv:hover {
  opacity: .7;
}

.el_btnWithMarkInv {
  width: 100%;
  color: #3f81cb;
  border: 2px solid;
  border-radius: 4px;
  align-items: center;
  gap: 8px;
  padding: 19px 16px;
  font-size: 14px;
  font-weight: 700;
  transition: opacity .3s ease-out;
  display: flex;
  text-decoration: none !important;
}

.el_btnWithMarkInv:before {
  content: "";
  width: 24px;
  height: 24px;
  background: url("icon-arrow02.659d21e1.svg") center / contain no-repeat;
  flex-shrink: 0;
  display: block;
}

.el_btnWithMarkInv:hover {
  opacity: .6;
}

.el_admin {
  background-image: url("medal.81cfa1e5.svg");
  background-repeat: no-repeat;
}

.bl_table__userTable .bl_table_body .el_admin {
  background-position: 17px 50%;
}

.el_fileDrop {
  border: 1px dashed #f3680b;
  padding: 40px 8px;
}

.el_fileDrop > p {
  text-align: center;
  color: #f3680b;
  font-weight: bold;
}

.el_fileDrop.is_drag {
  background-color: #ffebcc;
}

input[type="checkbox"].el_checkMark {
  appearance: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #b3b3b3;
  border-radius: 1px;
}

input[type="checkbox"].el_checkMark:checked {
  background-image: url("check-on.ad807fa3.svg");
  border: none;
}

input.is_error {
  background-image: url("ng.ce95f74c.svg");
  background-position: 8px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
  border: 2px solid #e83131;
  padding-left: 34px;
}

input.is_error + span {
  color: #e83131;
  margin-top: 8px;
  display: inline-block;
}

.el_inlineSelectWrap {
  margin: 0 8px;
  position: relative;
}

.el_inlineSelectWrap:after {
  content: "";
  width: 10px;
  height: 6px;
  pointer-events: none;
  background-image: url("down.e302d382.svg");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
}

.el_inlineSelectWrap > [class*="el_inlineInput"] {
  margin: 0;
}

select[class*="el_inlineInput"] {
  line-height: 1.9;
}

input.el_inlineInputL {
  width: 96px;
  text-align: right;
  vertical-align: baseline;
  border: 1px solid #e6e6e6;
  margin-left: 8px;
  margin-right: 8px;
  padding: 1px 8px;
  line-height: 1;
}

input.el_inlineInputL.textL {
  text-align: left;
}

input.el_inlineInputL::placeholder {
  color: #b3b3b3;
}

input.el_inlineInputL::-webkit-outer-spin-button, input.el_inlineInputL::-webkit-inner-spin-button {
  appearance: none;
}

input.el_inlineInputL:first-child {
  margin-left: 0;
}

input.el_inlineInputM {
  width: 48px;
  text-align: right;
  vertical-align: baseline;
  border: 1px solid #e6e6e6;
  margin-left: 8px;
  margin-right: 8px;
  padding: 1px 8px;
  line-height: 1;
}

input.el_inlineInputM.textL {
  text-align: left;
}

input.el_inlineInputM::placeholder {
  color: #b3b3b3;
}

input.el_inlineInputM::-webkit-outer-spin-button, input.el_inlineInputM::-webkit-inner-spin-button {
  appearance: none;
}

input.el_inlineInputM:first-child {
  margin-left: 0;
}

input.el_inlineInputS {
  width: 32px;
  text-align: right;
  vertical-align: baseline;
  border: 1px solid #e6e6e6;
  margin-left: 8px;
  margin-right: 8px;
  padding: 1px 8px;
  line-height: 1;
}

input.el_inlineInputS.textL {
  text-align: left;
}

input.el_inlineInputS::placeholder {
  color: #b3b3b3;
}

input.el_inlineInputS::-webkit-outer-spin-button, input.el_inlineInputS::-webkit-inner-spin-button {
  appearance: none;
}

input.el_inlineInputS:first-child {
  margin-left: 0;
}

input.el_inlineInput {
  width: auto;
  text-align: right;
  vertical-align: baseline;
  border: 1px solid #e6e6e6;
  margin-left: 8px;
  margin-right: 8px;
  padding: 1px 8px;
  line-height: 1;
}

input.el_inlineInput.textL {
  text-align: left;
}

input.el_inlineInput::placeholder {
  color: #b3b3b3;
}

input.el_inlineInput::-webkit-outer-spin-button, input.el_inlineInput::-webkit-inner-spin-button {
  appearance: none;
}

input.el_inlineInput:first-child {
  margin-left: 0;
}

.sp_block > .el_inlineInputM, .mobile_block > .el_inlineInputL {
  margin-left: 8px !important;
}

.el_selectBorderLess {
  cursor: pointer;
  border: none;
  padding: 8px 42px 8px 16px;
  font-weight: bold;
}

.el_selectBorderLess > option {
  font-weight: 500;
}

.el_selectBorderLessWrap {
  display: inline-block;
  position: relative;
}

.el_selectBorderLessWrap:after {
  content: "";
  width: 10px;
  height: 6px;
  pointer-events: none;
  background-image: url("down.e302d382.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.el_toggleBtn {
  width: 68px;
  height: 32px;
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
}

.el_toggleBtn > input[type="checkbox"] {
  appearance: none;
  width: 68px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 16px;
  background-image: none !important;
}

.el_toggleBtn > input[type="checkbox"] + span {
  width: 28px;
  height: 28px;
  background-color: #b3b3b3;
  border-radius: 50%;
  transition-duration: .2s;
  position: absolute;
  top: 2px;
  left: 2px;
}

.el_toggleBtn > input[type="checkbox"] + span:before, .el_toggleBtn > input[type="checkbox"] + span:after {
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  transition-duration: .2s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.el_toggleBtn > input[type="checkbox"] + span:before {
  content: "送信";
  color: #fff;
  opacity: 0;
  left: 8px;
}

.el_toggleBtn > input[type="checkbox"] + span:after {
  content: "除外";
  color: #b3b3b3;
  opacity: 1;
  right: -28px;
}

.el_toggleBtn > input[type="checkbox"]:checked {
  background-color: #f3680b;
  border-color: #0000;
}

.el_toggleBtn > input[type="checkbox"]:checked + span {
  background-color: #fff;
  left: 38px;
}

.el_toggleBtn > input[type="checkbox"]:checked + span:before {
  opacity: 1;
  left: -28px;
}

.el_toggleBtn > input[type="checkbox"]:checked + span:after {
  opacity: 0;
  right: 8px;
}

.el_toggleBtn.el_toggleBtn__onoff > input[type="checkbox"] + span:before {
  content: "ON";
}

.el_toggleBtn.el_toggleBtn__onoff > input[type="checkbox"] + span:after {
  content: "OFF";
}

.el_toggleBtn__onoff {
  width: 68px;
  height: 32px;
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
}

.el_toggleBtn__onoff > input[type="checkbox"] {
  appearance: none;
  width: 68px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 16px;
  background-image: none !important;
}

.el_toggleBtn__onoff > input[type="checkbox"] + span {
  width: 28px;
  height: 28px;
  background-color: #b3b3b3;
  border-radius: 50%;
  transition-duration: .2s;
  position: absolute;
  top: 2px;
  left: 2px;
}

.el_toggleBtn__onoff > input[type="checkbox"] + span:before, .el_toggleBtn__onoff > input[type="checkbox"] + span:after {
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  transition-duration: .2s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.el_toggleBtn__onoff > input[type="checkbox"] + span:before {
  content: "ON";
  color: #fff;
  opacity: 0;
  left: 8px;
}

.el_toggleBtn__onoff > input[type="checkbox"] + span:after {
  content: "OFF";
  color: #b3b3b3;
  opacity: 1;
  right: -28px;
}

.el_toggleBtn__onoff > input[type="checkbox"]:checked {
  background-color: #f3680b;
  border-color: #0000;
}

.el_toggleBtn__onoff > input[type="checkbox"]:checked + span {
  background-color: #fff;
  left: 38px;
}

.el_toggleBtn__onoff > input[type="checkbox"]:checked + span:before {
  opacity: 1;
  left: -28px;
}

.el_toggleBtn__onoff > input[type="checkbox"]:checked + span:after {
  opacity: 0;
  right: 8px;
}

.el_toggleBtn__onoff.el_toggleBtn__onoff > input[type="checkbox"] + span:before {
  content: "ON";
}

.el_toggleBtn__onoff.el_toggleBtn__onoff > input[type="checkbox"] + span:after {
  content: "OFF";
}

.el_toggleBtn__set {
  width: 68px;
  height: 32px;
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
}

.el_toggleBtn__set > input[type="checkbox"] {
  appearance: none;
  width: 68px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 16px;
  background-image: none !important;
}

.el_toggleBtn__set > input[type="checkbox"] + span {
  width: 28px;
  height: 28px;
  background-color: #b3b3b3;
  border-radius: 50%;
  transition-duration: .2s;
  position: absolute;
  top: 2px;
  left: 2px;
}

.el_toggleBtn__set > input[type="checkbox"] + span:before, .el_toggleBtn__set > input[type="checkbox"] + span:after {
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  transition-duration: .2s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.el_toggleBtn__set > input[type="checkbox"] + span:before {
  content: "設定";
  color: #fff;
  opacity: 0;
  left: 8px;
}

.el_toggleBtn__set > input[type="checkbox"] + span:after {
  content: "解除";
  color: #b3b3b3;
  opacity: 1;
  right: -28px;
}

.el_toggleBtn__set > input[type="checkbox"]:checked {
  background-color: #f3680b;
  border-color: #0000;
}

.el_toggleBtn__set > input[type="checkbox"]:checked + span {
  background-color: #fff;
  left: 38px;
}

.el_toggleBtn__set > input[type="checkbox"]:checked + span:before {
  opacity: 1;
  left: -28px;
}

.el_toggleBtn__set > input[type="checkbox"]:checked + span:after {
  opacity: 0;
  right: 8px;
}

.el_toggleBtn__set.el_toggleBtn__onoff > input[type="checkbox"] + span:before {
  content: "ON";
}

.el_toggleBtn__set.el_toggleBtn__onoff > input[type="checkbox"] + span:after {
  content: "OFF";
}

.el_toggleBtn__send {
  width: 98px;
  height: 32px;
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
}

.el_toggleBtn__send > input[type="checkbox"] {
  appearance: none;
  width: 98px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 16px;
  background-image: none !important;
}

.el_toggleBtn__send > input[type="checkbox"] + span {
  width: 28px;
  height: 28px;
  background-color: #b3b3b3;
  border-radius: 50%;
  transition-duration: .2s;
  position: absolute;
  top: 2px;
  left: 2px;
}

.el_toggleBtn__send > input[type="checkbox"] + span:before, .el_toggleBtn__send > input[type="checkbox"] + span:after {
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  transition-duration: .2s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.el_toggleBtn__send > input[type="checkbox"] + span:before {
  content: "送信する";
  color: #fff;
  opacity: 0;
  left: 8px;
}

.el_toggleBtn__send > input[type="checkbox"] + span:after {
  content: "送信しない";
  color: #b3b3b3;
  opacity: 1;
  right: -62px;
}

.el_toggleBtn__send > input[type="checkbox"]:checked {
  background-color: #f3680b;
  border-color: #0000;
}

.el_toggleBtn__send > input[type="checkbox"]:checked + span {
  background-color: #fff;
  left: 68px;
}

.el_toggleBtn__send > input[type="checkbox"]:checked + span:before {
  opacity: 1;
  left: -55px;
}

.el_toggleBtn__send > input[type="checkbox"]:checked + span:after {
  opacity: 0;
  right: 8px;
}

.el_toggleBtn__send.el_toggleBtn__onoff > input[type="checkbox"] + span:before {
  content: "ON";
}

.el_toggleBtn__send.el_toggleBtn__onoff > input[type="checkbox"] + span:after {
  content: "OFF";
}

.el_searchBtn {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background-image: url("list-search.4d5a9d64.svg");
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px 8px;
}

.el_searchInputWrap {
  position: relative;
}

.el_searchInputWrap .el_searchBtn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.el_keepLogin {
  text-align: center;
  cursor: pointer;
  display: block;
}

.el_keepLogin > .el_checkMark {
  vertical-align: text-bottom;
}

.el_keepLogin > span {
  vertical-align: middle;
  color: #3f81cb;
}

.el_graphLegend {
  width: 48px;
  color: #b3b3b3;
  margin-right: 8px;
  position: relative;
}

.el_graphLegend:before, .el_graphLegend:after {
  content: "";
  background-color: currentColor;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.el_graphLegend:before {
  width: 100%;
  height: 1px;
}

.el_graphLegend:after {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.el_graphLegend.color1 {
  color: #fe308a;
}

.el_graphLegend.color2 {
  color: #a844ff;
}

.el_graphLegend.color3 {
  color: #0d82cf;
}

.el_graphLegend.color4 {
  color: #47c5e6;
}

.el_graphLegend.color5 {
  color: #0aa553;
}

.el_graphLegend.color6 {
  color: #92e831;
}

.el_graphLegend.color7 {
  color: #ffde20;
}

.el_graphLegend.color8 {
  color: #ff9f2f;
}

.el_graphLegend.color9 {
  color: #f3680b;
}

.el_graphLegend.color10 {
  color: #fe2e36;
}

.el_doughnutTooltip {
  pointer-events: none;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.el_doughnutTooltip_label {
  white-space: nowrap;
  text-align: center;
  margin-bottom: -8px;
  font-size: 14px;
  font-weight: bold;
}

.el_doughnutTooltip_value {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.el_doughnutTooltip.el_doughnutTooltip__topPage {
  top: 50%;
}

.el_doughnutTooltip.el_doughnutTooltip__topPage > .el_doughnutTooltip_label {
  width: 26px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 26px 24px;
  margin: 0 auto;
}

.el_doughnutTooltip.el_doughnutTooltip__topPage1 {
  color: #3d4854;
}

.el_doughnutTooltip.el_doughnutTooltip__topPage1 > .el_doughnutTooltip_label {
  background-image: url("rank-1.eb781340.svg");
}

.el_doughnutTooltip.el_doughnutTooltip__topPage2 {
  color: #47c5e6;
}

.el_doughnutTooltip.el_doughnutTooltip__topPage2 > .el_doughnutTooltip_label {
  background-image: url("rank-2.e92a0cab.svg");
}

.el_doughnutTooltip.el_doughnutTooltip__topPage3 {
  color: #eba707;
}

.el_doughnutTooltip.el_doughnutTooltip__topPage3 > .el_doughnutTooltip_label {
  background-image: url("rank-3.96aa7365.svg");
}

.el_doughnutTooltip.el_doughnutTooltip__topPage4 {
  color: #92e831;
}

.el_doughnutTooltip.el_doughnutTooltip__topPage4 > .el_doughnutTooltip_label {
  width: auto;
  height: auto;
}

.el_doughnutTooltip.el_doughnutTooltip__topPage4 > .el_doughnutTooltip_label:before {
  content: "その他";
  font-size: 14px;
}

@media screen and (max-width: 1660px) {
  .el_doughnutTooltip_label {
    font-size: .9vw;
  }

  .el_doughnutTooltip_value {
    font-size: 1.47vw;
  }
}

@media screen and (max-width: 1024px) {
  .el_doughnutTooltip_label {
    font-size: 14px;
  }

  .el_doughnutTooltip_value {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .el_doughnutTooltip_label {
    font-size: 2.91666vw;
  }

  .el_doughnutTooltip_value {
    font-size: 5vw;
  }
}

.el_horizontalBarTooltip {
  z-index: 30;
  pointer-events: none;
  background-color: #fffffff2;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  top: 46px;
  left: 8px;
}

.el_horizontalBarTooltip > li {
  margin-bottom: 8px;
  padding-left: 26px;
  display: flex;
  position: relative;
}

.el_horizontalBarTooltip > li:last-child {
  margin-bottom: 0;
}

.el_horizontalBarTooltip > li:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.el_horizontalBarTooltip > li:nth-child(7n+1):before {
  background-color: #3d4854;
}

.el_horizontalBarTooltip > li:nth-child(7n+2):before {
  background-color: #47c5e6;
}

.el_horizontalBarTooltip > li:nth-child(7n+3):before {
  background-color: #eba707;
}

.el_horizontalBarTooltip > li:nth-child(7n+4):before {
  background-color: #e2e1cb;
}

.el_horizontalBarTooltip > li:nth-child(7n+5):before {
  background-color: #92e831;
}

.el_horizontalBarTooltip > li:nth-child(7n+6):before {
  background-color: #f368ae;
}

.el_horizontalBarTooltip > li:nth-child(7n+7):before {
  background-color: #8d7ec7;
}

.el_horizontalBarTooltip > li > :first-child {
  flex: 1 0 auto;
}

.el_horizontalBarTooltip > li > :last-child {
  flex: none;
}

.el_mixedTooltip {
  z-index: 30;
  pointer-events: none;
  background-color: #fffffff2;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  top: 46px;
  left: 8px;
}

.el_mixedTooltip > li {
  margin-bottom: 8px;
  padding-left: 26px;
  display: flex;
  position: relative;
}

.el_mixedTooltip > li:last-child {
  margin-bottom: 0;
}

.el_mixedTooltip > li:before {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.el_mixedTooltip > .el_mixedTooltip_lineChart:before {
  border-radius: 50%;
}

.el_mixedTooltip > .el_mixedTooltip_bar1:before {
  background-color: #225c97;
}

.el_mixedTooltip > .el_mixedTooltip_bar2:before {
  background-color: #67a6e6;
}

.el_mixedTooltip > .el_mixedTooltip_line1:before {
  background-color: #a844ff;
}

.el_mixedTooltip > .el_mixedTooltip_line2:before {
  background-color: #ffde20;
}

.el_mixedTooltip > .el_mixedTooltip_line3:before {
  background-color: #f368ae;
}

.el_lineTooltip {
  white-space: nowrap;
  pointer-events: none;
  background-color: #fffffff2;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 80px;
  left: 8px;
  transform: translate(-50%, -50%);
}

.el_lineTooltip_title {
  margin-bottom: 8px;
}

.el_lineTooltip_list > li {
  text-align: right;
  margin-bottom: 8px;
  padding-left: 26px;
  position: relative;
}

.el_lineTooltip_list > li:last-child {
  margin-bottom: 0;
}

.el_lineTooltip_list > li:before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.bl_canvasWrap__singleLineChart .el_lineTooltip_list > li {
  padding-left: 0;
}

.bl_canvasWrap__singleLineChart .el_lineTooltip_list > li:before {
  display: none;
}

.el_lineTooltip_label {
  margin-left: 8px;
}

.el_barChartLegend {
  color: #fff;
  font-size: clamp(10px, .9vw, 18px);
  font-weight: bold;
  position: absolute;
  transform: translateX(-50%);
}

@media screen and (max-width: 1024px) {
  .el_barChartLegend {
    font-size: clamp(10px, 2.1vw, 16px);
  }
}

@media screen and (max-width: 480px) {
  .el_barChartLegend {
    font-size: 4vw;
  }
}

.el_horizontalBarChartLegend {
  margin-bottom: 8px;
  margin-right: 16px;
  padding-left: 24px;
  display: inline-block;
  position: relative;
}

.el_horizontalBarChartLegendWrap {
  padding-top: 16px;
}

.el_horizontalBarChartLegend:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.el_horizontalBarChartLegend:nth-child(7n+1):before {
  background-color: #3d4854;
}

.el_horizontalBarChartLegend:nth-child(7n+2):before {
  background-color: #47c5e6;
}

.el_horizontalBarChartLegend:nth-child(7n+3):before {
  background-color: #eba707;
}

.el_horizontalBarChartLegend:nth-child(7n+4):before {
  background-color: #e2e1cb;
}

.el_horizontalBarChartLegend:nth-child(7n+5):before {
  background-color: #92e831;
}

.el_horizontalBarChartLegend:nth-child(7n+6):before {
  background-color: #f368ae;
}

.el_horizontalBarChartLegend:nth-child(7n+7):before {
  background-color: #8d7ec7;
}

.el_externalLink > a:after {
  content: "";
  height: 14px;
  width: 14px;
  vertical-align: middle;
  background-image: url("icon-window-blue.c1079114.svg");
  background-size: contain;
  display: inline-block;
}

.hp_justified {
  justify-content: space-between !important;
  display: flex !important;
}

.hp_alignBaseline {
  display: flex;
  align-items: baseline !important;
}

.mt_2 {
  margin-top: 2px !important;
}

.mt_8 {
  margin-top: 8px !important;
}

.mt_16 {
  margin-top: 16px !important;
}

.mt_32 {
  margin-top: 32px !important;
}

.mr_8 {
  margin-right: 8px !important;
}

.mr_32 {
  margin-right: 32px !important;
}

.mb_0 {
  margin-bottom: 0 !important;
}

.mb_8 {
  margin-bottom: 8px !important;
}

.mb_16 {
  margin-bottom: 16px !important;
}

.mb_20 {
  margin-bottom: 20px !important;
}

.mb_24 {
  margin-bottom: 24px !important;
}

.mb_32 {
  margin-bottom: 32px !important;
}

.mb_48 {
  margin-bottom: 48px !important;
}

.ml_8 {
  margin-left: 8px !important;
}

.ml_16 {
  margin-left: 16px !important;
}

.ml_26 {
  margin-left: 26px !important;
}

@media screen and (max-width: 1024px) {
  .mobile_ml_0 {
    margin-left: 0 !important;
  }

  .mobile_ml_26 {
    margin-left: 26px !important;
  }

  .mobile_ml_96 {
    margin-left: 96px !important;
  }
}

@media screen and (max-width: 480px) {
  .sp_ma_0 {
    margin: 0 !important;
  }

  .sp_mt_16 {
    margin-top: 16px !important;
  }

  .sp_ml_26 {
    margin-left: 26px !important;
  }

  .sp_mb_16 {
    margin-bottom: 16px !important;
  }
}

.pl_26 {
  padding-left: 26px !important;
}

.fs_12 {
  font-size: 12px !important;
}

.fs_14 {
  font-size: 14px !important;
}

.fs_18 {
  font-size: 18px !important;
}

.fs_24 {
  font-size: 24px !important;
}

.txt_blue {
  color: #3f81cb !important;
}

.txt_red {
  color: #e61717 !important;
}

.txt_bold {
  font-weight: bold;
}

.txt_alignRight {
  text-align: right !important;
}

.txt_alignCenter {
  text-align: center !important;
}

.break_indent_26 {
  text-indent: -26px !important;
  padding-left: 26px !important;
}

.show_mobile, .show_sp {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .show_mobile, .mobile_block {
    display: block !important;
  }

  .mobile_block > :first-child {
    margin-left: 0 !important;
  }

  .mobile_indent_26 {
    padding-left: 26px !important;
  }
}

@media screen and (max-width: 480px) {
  .show_sp, .sp_block {
    display: block !important;
  }

  .sp_block > :first-child {
    margin-left: 0 !important;
  }

  .sp_indent_26 {
    padding-left: 26px !important;
  }
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
  content: "";
  height: 9px;
  width: 9px;
  border: 3px solid #ccc;
  border-width: 3px 3px 0 0;
  display: block;
  position: absolute;
  top: 6px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  width: 0;
  margin-left: -4px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  box-sizing: content-box;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border: 8px solid #0000;
  position: absolute;
  left: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #aeaeae;
  bottom: -1px;
}

.react-datepicker-wrapper {
  width: 100%;
  border: 0;
  padding: 0;
  display: inline-block;
}

.react-datepicker {
  color: #000;
  background-color: #fff;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  font-family: Helvetica Neue, helvetica, arial, sans-serif;
  font-size: .8rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: .3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: .3rem;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 2px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #000;
  margin-top: 0;
  font-size: .944rem;
  font-weight: bold;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 2px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover :before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  width: 0;
  font-size: 20px;
  position: relative;
  top: -1px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next:before {
  left: -7px;
  transform: rotate(45deg);
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous:before {
  right: -7px;
  transform: rotate(225deg);
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  max-width: 180px;
  flex-wrap: wrap;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  width: 85px;
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #fff;
  border-bottom-right-radius: .3rem;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  text-align: center;
  border-bottom-right-radius: .3rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(195px + .85rem);
  width: 100%;
  box-sizing: content-box;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  white-space: nowrap;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #216ba5;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__week-number {
  color: #ccc;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: .3rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #fff;
  background-color: #2579ba;
  border-radius: .3rem;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #216ba580;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
  color: #000;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: #0000;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid #0000;
  border-radius: .3rem;
  position: relative;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 0;
  right: -16px;
  transform: rotate(135deg);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  width: 50%;
  z-index: 1;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  user-select: none;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #216ba5;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  width: 100vw;
  height: 100vh;
  z-index: 2147483647;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

/*# sourceMappingURL=index.df483c40.css.map */
