// TOC:
//  1. 行
//  2. 列
//  3. パネル
//  4. テーブル
//  5. フレックス & グリッド
//  6. フォーム
//  7. リスト
//  8. サイドドロワー
//  9. ログイン画面
// 10. モーダルウィンドウ
// 11. 商品選択
// 12. 進捗ナビゲーション
// 13. メールテンプレート
// 14. キャンペーン内容確認
// 15. 商品管理番号アップロード
// 16. グラフ
// 17. 利用ガイド
// 18. ツールチップ
// 19. よくある質問


// 1. 行
// ================================================================================

// メインの行
.bl_row {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 48px;

	> .bl_col {
		padding: 0 24px;
	}
}

@include mediaQuery($SP) {

	.bl_row {
		flex-direction: column;
		margin: 0;

		> .bl_col {
			padding: 0;
		}

		.ly_main > &:last-child {
			margin-bottom: -24px;
		}
	}
}


// 2. 列
// ================================================================================

// メインの列（行の中で使用）
.bl_col {

	// 最上位の.bl_colのみに適用。.bl_rowの子要素が自分のみの場合、自分の横幅を画面幅以下になるように調整
	@at-root {

		.ly_main > .bl_row > .bl_col, .ly_main > div > .bl_row > .bl_col {
			box-sizing: content-box;

			$column_list: 8 1040, 6 768, 4 496, 3 360;
			@each $column in $column_list {

				&.bl_col__#{nth($column, 1)}:only-child {
					flex: 0 1 #{nth($column, 2)}px;
					width: 100%;
				}
			}
		}
	}

	&.bl_col__12 {
		width: 100%;
		// width: 94.28571%;
		max-width: 1584px;
	}

	&.bl_col__9 {
		width: 76.76767%;
		max-width: 1216px;
	}

	&.bl_col__8 {
		width: 66.66666%;
		width: 61.90476%;
		max-width: 1040px;
	}

	&.bl_col__6 {
		width: 50%;
		width: 45.71428%;
		max-width: 768px;
		flex: 1 1 auto;
	}

	&.bl_col__4 {
		width: 33.33333%;
		width: 29.52380%;
		max-width: 496px;
		flex: 1 1 auto;
	}

	&.bl_col__3 {
		width: 25%;
		width: 21.42857%;
		max-width: 360px;
	}

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}
}

@include mediaQuery($MOBILE) {
	.bl_col.bl_col__mobileFullWidth {
		width: 100%;
	}
}

@include mediaQuery($SP) {

	$col_set: 12, 9, 8, 6, 4, 3;
	@each $col in $col_set {

		.bl_col.bl_col__#{$col} {
			width: 100%;
			max-width: 100%;
		}
	}
}

// .bl_colが入れ子になったときのスタイル
.bl_col > .bl_row {

	> .bl_col:first-child {
		padding-left: 0;
	}

	> .bl_col:last-child {
		padding-right: 0;
	}
}

@include mediaQuery($MOBILE) {

	.ly_main__top {

		> .bl_row:nth-child(2) {
			flex-wrap: nowrap;

			> .bl_col {
				flex: 1 1 auto;
			}

			> .bl_col:nth-child(2) {

				> .bl_row {
					flex-direction: column;

					> .bl_col {
						flex: 0 1 auto;
						width: 100%;
						padding: 0;
					}

					> .bl_col:first-child {
						margin-bottom: 48px;
					}
				}
			}
		}
	}
}

@include mediaQuery($SP) {
	.ly_main__top {

		> .bl_row:nth-child(2) {

			> .bl_col:nth-child(2) {

				> .bl_row {

					> .bl_col {

						&:first-child {
							margin-bottom: 0;

							> .bl_panel {
								margin-bottom: 24px;
							}
						}
					}
				}
			}
		}
	}
}

@include mediaQuery($MOBILE) {
	.ly_main__acquisition {
		> .bl_row {
			flex-direction: column;

			> .bl_col {
				flex: 0 0 auto !important;

				> .bl_row {
					flex-direction: column;

					&:last-child {
						margin-bottom: 0;
					}

					> .bl_col {
						max-width: 100%;
						width: 100%;
						padding: 0;

						&:last-child {

							> .bl_panel {
								margin-bottom: 0;
							}
						}

						> .bl_panel {
							margin-bottom: 48px;
						}
					}
				}
			}
		}
	}
}

@include mediaQuery($SP) {
	.ly_main__acquisition {
		> .bl_row {

			> .bl_col {

				> .bl_row {

					> .bl_col {

						> .bl_panel {
							margin-bottom: 24px !important;
						}
					}
				}
			}
		}
	}
}


@include mediaQuery(1200) {
	.ly_main__reviewStatus {
		> .bl_row:nth-child(4) {
			flex-direction: column;

			> .bl_col {
				flex: 0 0 auto !important;
				max-width: 100%;
				width: 100%;
				margin-bottom: 48px;
				padding: 0;

				&:first-child {
					.bl_reviewData {
						flex-wrap: wrap;
						justify-content: flex-start;
						margin-bottom: -16px;

						&_item {
							flex: 1 0 auto;
							margin-bottom: 16px;
						}
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

@include mediaQuery($SP) {

	.ly_main__reviewStatus {

		> .bl_row {

			&:nth-child(4) {

				> .bl_col {
					margin-bottom: 0;
				}
			}
		}
	}
}

@include mediaQuery(960) {
	.ly_main__exclusion {
		> .bl_row {
			flex-direction: column;

			> .bl_col {
				max-width: 100%;
				width: 100%;
				padding: 0;

				&:first-child {
					margin-bottom: 48px;
				}
			}
		}
	}

	.bl_table.bl_table.bl_table__headBorderBottom {
		$columns:
			0 1 732px center flex-start,
			0 0 110px center center;

		@include tableColumnStyle( $columns );
	}

	.bl_table.bl_table.bl_table__systemAlert {
		$columns:
			0 1 732px center flex-start,
			0 0 110px center center;

		@include tableColumnStyle( $columns );
	}

	.bl_table.bl_table.bl_table__plan {
		$columns:
			0 0 200px center flex-start,
			0 1 500px left flex-start;

		@include tableColumnStyle( $columns );
	}
}

@include mediaQuery($SP) {

	.ly_main__exclusion {

		> .bl_row {

			> .bl_col {

				&:first-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.bl_table.bl_table.bl_table__headBorderBottom {
		$columns:
			0 1 732px center flex-start,
			0 0 110px center center;

		@include tableColumnStyle( $columns );
	}

	.bl_table.bl_table.bl_table__systemAlert {
		$columns:
			0 1 732px center flex-start,
			0 0 110px center center;

		@include tableColumnStyle( $columns );
	}

	.bl_table.bl_table.bl_table__plan {
		$columns:
			0 0 200px center flex-start,
			0 1 500px left flex-start;

		@include tableColumnStyle( $columns );
	}
}

@include mediaQuery(960) {
	.ly_main__rakutenSetting {
		.bl_row {
			flex-direction: column;

			> .bl_col {
				max-width: 100%;
				width: 100%;
				margin-bottom: 48px;
				padding: 0;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

@include mediaQuery($SP) {

	.ly_main__rakutenSetting {

		.bl_row {

			> .bl_col {
				margin-bottom: 0;
			}
		}
	}
}

.ly_main__guide {
	.bl_row {
		flex-wrap: nowrap;
		// justify-content: space-between;

		> .bl_col {
			padding: 0;

			&.bl_col__3 {
				flex: 0 0 320px;
				max-width: 320px;
				margin-right: 48px;
			}

			&.bl_col__9 {
				flex: 1 1 1216px;
			}
		}
	}
}

@include mediaQuery(1200) {
	.ly_main__guide {
		.bl_row {
			flex-direction: column;

			> .bl_col {
				
				&.bl_col__3 {
					width: 100%;
					max-width: 100%;
					padding: 0;
					order: 1000;

					> .bl_panel {
						padding: 0 0 24px;
					}
				}
				
				&.bl_col__9 {
					width: 100%;
					margin-bottom: 48px;
					padding: 0;
				}
			}
		}
	}
}


// 3. パネル
// ================================================================================

// メインのパネル内の行
.bl_panel_row {
	margin-bottom: 32px;

	&.bl_panel_row__indent {
		padding: 0 16px;
	}

	&.bl_panel_row__disabled {
		color: #B3B3B3;
	}

	> p {
		margin-bottom: 8px;

		> input[type="radio"]:not(:first-child) {
			margin-left: 24px;
		}
	}
}

.bl_panel_inputRow {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 8px;
	margin-bottom: 16px;
	padding-left: 0;

	&:last-child {
		margin-bottom: 24px;
	}

	> * {
		margin-bottom: 8px;
	}

	> .el_btnTxt:last-child {
		margin-left: 16px;
	}
}

.bl_panel_indent {
	padding: 0 16px;
}

// メインのパネル
.bl_panel {
	padding: 24px;
	position: relative;
	border-radius: 6px;
	background-color: #FFF;
	box-shadow: 1px 1px 4px $gray-05;

	> *:last-child {
		margin-bottom: 0 !important;
	}

	@at-root {
		.bl_col .bl_row & {
			height: 100%;
		}
	}

	.el_linkIcon__guide {
		position: absolute;
		height: 24px;
		width: 24px;
		top: 16px;
		right: 16px;
		margin-left: 0;
		background-image: url(../img/icon-q-w.svg);
	}

	.el_linkIcon__guideF {
		display: inline-block;
		height: 16px;
		width: 16px;
		margin-left: 6px;
		background-image: url(../img/icon-q.svg);
		background-size: contain;
	}
}

@include mediaQuery($SP) {

	.bl_panel {
		margin-bottom: 24px;
	}
}

// パネル上ボーダー有り
.bl_panel.bl_panel__bt {
	border-radius: 0 0 6px 6px;
	border-top: 4px solid $main1;

	&.bl_panel__guide_menu {
		position: sticky;
		top: 48px;
		max-height: calc(100vh - 160px);
		overflow-y: auto;
	}
}

// タイトル色反転
.bl_panel.bl_panel__ttlInv {
	padding-top: 0;

	.el_lv2Heading:first-child {
		margin-right: -24px;
		margin-bottom: 24px;
		margin-left: -24px;
		padding: 16px 24px;
		border-radius: 6px 6px 0 0;
		background-color: $main1;
		line-height: 1.4;
		color: #FFF;
	}
}

// パネル枠有り
.bl_panel.bl_panel__warning {
	border-radius: 6px 6px 6px 6px;
	border: 2px solid $error;

	.el_lv2Heading::before {
		vertical-align: middle;
		white-space: pre-wrap;
		content: url(../img/icon-attention.svg) ' ';
	}

	.el_lv2Heading:first-child {
		line-height: 1.4;
		color: $error;
	}
}

@include mediaQuery($MOBILE) {
	.bl_panel.bl_panel__warning {
		.el_lv2Heading {
			text-align: center;

			&::before {
				white-space: pre;
				content: url(../img/icon-attention.svg) '\A';
			}
		}
	}
}

.bl_panel_innerCheck {
	padding: 0 26px;
}

.bl_label {
	display: inline-flex;
	align-items: flex-start;
	margin-right: 16px;
	line-height: 2.4;

	&:last-child {
		margin-right: 0;
		margin-bottom: 0;
	}

	> input {
		flex: 0 0 auto;
		margin-top: 8px;
	}

	> .bl_inputFormWrap {
		margin-bottom: 8px !important;
	}
}


.bl_label.bl_label__termWrap {
	align-items: flex-start;

	> input {
		flex: 0 0 auto;
		margin-top: 8px;
	}
}

.bl_inputFormWrap {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	line-height: 2.4;

	&:last-child {
		margin-bottom: 0;
	}

	> .el_btnTxt {
		margin-left: 16px;
	}
}

// DatePicker用の月移動の矢印がline-heightの指定でズレる対応
.datePickerPopper {
	line-height: normal;
}



// パネル上のタブ
.bl_panelTab {
	display: flex;

	&_item {
		padding: 16px 24px;
		margin-right: 8px;
		border-radius: 6px 6px 0px 0px;
		border: 1px solid currentColor;
		background-color: #FFF;
		font-weight: bold;
		font-size: 18px;
		color: $gray4;
		cursor: pointer;
	}

	&_item.is_active {
		border-color: transparent;
		background-color: $main1;
		color: #FFF;
	}
}

@include mediaQuery($SP) {
	.bl_panelTab {
		&_item {
			padding: 16px;
		}
	}
}

// パネル内上下のボタンなどのエリア
.bl_panel_headerFooter {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 24px;
	margin-bottom: -24px;

	> * {
		margin-right: 16px;
		margin-bottom: 24px;
	}

	// .bl_panelのフッターに配置された場合
	&:last-child {
		padding-bottom: 0;
		margin-top: -24px;

		> * {
			margin-top: 24px;
			margin-bottom: 0;
		}
	}
}


// 4. テーブル
// ================================================================================

// テーブル共通スタイル
.bl_table,
.bl_virtual_table {
	display: block;
	@include scrollBar;
}
.bl_table,
.bl_virtual_table table {
	width: 100%;
	border-bottom: 2px solid $main1;
	white-space: nowrap;
	overflow: auto;

	tr {
		display: flex;
		width: 100%;
	}

	th,
	td {
		display: block;
		padding: 24px 16px;
		background-color: #FFF;
		white-space: normal;
		word-break: break-all;
	}

	th {
		font-weight: bold;
		font-size: 14px;
	}

	td {
		display: flex;
		align-items: center;
	}

	td > img {
		display: inline-block;
		width: 80px;
		height: 80px;
		object-fit: contain;
	}
}

.bl_table_head,
.bl_virtual_table table > thead {
	position: sticky;
	top: 0;
	display: block;
	width: 100%;
	z-index: 5;

	th {
		border-top: 2px solid $main1;
		border-bottom: 2px solid $main1;
		color: $main1;
	}
}

.bl_table_body,
.bl_virtual_table table > tbody {
	display: block;
	width: 100%;

	td {
		border-bottom: 1px solid $gray2;
	}
}

// ヘッダーのフィルター
.bl_table_filterHead {
	position: relative;

	&Ttl {
		cursor: pointer;

		&::after {
			content: "";
			display: inline-block;
			width: 14px;
			height: 14px;
			margin-left: 2px;
			background-image: url(../img/list-off.svg);
			background-repeat: no-repeat;
			transform: translateY(1px);
		}
	}

	.bl_tableSearchForm {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		padding: 16px;
		border: 1px solid $main1;
		background-color: $main4;
		text-align: left;
		color: $base;

		> * {
			width: 100%;
			white-space: nowrap;
		}

		&__right {
			left: auto;
			right: 0;
		}

		&_ttl {
			margin-bottom: 8px;
		}

		input[type="text"] {
			width: 240px;
			padding-right: 30px;
			font-weight: 500;
		}

		select {
			min-width: 140px;
			font-weight: 500;

			> option {
				font-weight: 500;
			}
		}
	}

	&.is_active {
		background-color: $main1;
		color: #FFF;

		.bl_table_filterHeadTtl {

			&::after {
				background-image: url(../img/list-on.svg);
			}
		}

		.bl_tableSearchForm {
			display: block;
		}
	}

	&.bl_table_filterHead__beforeBtn.is_active {
		background: none;
		padding: 0;

		.bl_table_filterHeadTtl {
			display: inline-block;
			padding: 24px 16px;
			background-color: $main1;
			color: #FFF;
		}
	}
}

@include mediaQuery($SP) {
	.bl_table {
		max-width: calc(100vw - 96px);

		&_filterHead {

			.bl_tableSearchForm {

				input[type="text"] {
					width: 200px;
				}
			}
		}
	}
}

.bl_nestTable {
	display: block;
	flex: 0 1 744px;
	padding-right: 16px;
	padding-left: 16px;

	@at-root {
		.bl_table .bl_table_body &Wrap {
			display: flex;
			align-items: center;
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	tbody {
		display: block;
	}

	tr {
		display: flex;
	}

	tr:last-child th,
	tr:last-child td {
		border-bottom: none;
	}

	th,
	td {
		display: block;
		padding-top: 24px;
		padding-bottom: 24px;
		border-bottom: 1px solid $gray2;
	}

	th {
		display: flex;
		align-items: center;
		flex-basis: auto !important;
	}

	td {
		flex-basis: auto !important;
		flex-grow: 1 !important;
	}

	+ div {
		display: flex;
		justify-content: space-between;
		flex: 0 0 152px;
		padding-right: 16px;
		padding-left: 16px;
	}
}

.bl_borderTable {
	width: 100%;

	tr:last-child th,
	tr:last-child td {
		border-bottom: none;
	}

	th,
	td {
		vertical-align: middle;
		white-space: nowrap;
		padding: 24px;
		border-bottom: 1px solid $gray2;
	}
}

// テーブル内ボタンのラッパー
.bl_table_btnWrap {
	display: flex;
	margin-left: 32px;

	&:first-child {
		margin-left: 0;
	}

	// ボタン間のマージン
	> * {
		margin-right: 8px;
		font-size: 12px;
	}

	> *:last-child {
		margin-right: 0;
	}

	> .el_btnInv {
		white-space: nowrap;
	}

	> .el_btnTerm {
		white-space: nowrap;
	}
}


// トップページテーブル
.bl_table.bl_table__totalReview {

	height: 792px;

	// grow, shrink, width, head-align(text-align), body-align(justify-content)
	$columns:
		0 1 512px left flex-start,
		1 0 104px center flex-end,
		1 0 104px center flex-end;

	@include tableColumnStyle( $columns );

	th,
	td {
		padding: 16px 8px;
	}

	td:nth-child(2),
	td:nth-child(3) {
		font-weight: bold;
		font-size: 18px;

		.el_unit {
			margin-left: 8px;
			font-size: 14px;
		}
	}

	&::-webkit-scrollbar-track {
		margin-top: 50px;
	}
}

// ユーザーアカウントテーブル
.bl_table.bl_table__userTable {
	height: 556px;

	$columns:
		0 0 224px center flex-start,
		1 1 849px center flex-start,
		0 0 180px center center,
		0 0 134px center center,
		0 0 134px center center,
		0 0 154px left center;

	@include tableColumnStyle( $columns );

	th:nth-child(4) {
		padding-left: 50px;
	}

	td:nth-child(1) {
		padding-left: 39px;
	}
}

.bl_table__reviewStatus {
	height: 445px;
}
.bl_table.bl_table__reviewStatus,
.bl_virtual_table.bl_table__reviewStatus table {
	$columns:
		0 0 138px center center,
		0 0 170px center center,
		0 1 485px center flex-start,
		0 0 120px center center,
		0 0 116px center center,
		0 0 144px center center,
		0 0 130px center center,
		0 0 102px center center,
		0 0 135px center center;

	@include tableColumnStyle( $columns );

	td:nth-child(1),
	td:nth-child(4) {
	  flex-direction: column;
	}
}

// 特典申込受付テーブル
.bl_virtual_table.bl_table__reception {

	height: 629px;

	$columns:
		0 0 48px center center,
		0 0 108px center center,
		0 0 138px center center,
		1 1 675px center flex-start,
		0 0 160px center center,
		0 0 132px flex-start center,
		0 0 168px center center,
		0 0 108px center center;

	@include tableColumnStyle( $columns );


	td > .el_btnTxt__underLine {
		margin-left: 32px;
	}
}

// レビュー特典登録 プレゼント品
.bl_table.bl_table__privilege {

	height: 632px;

	$columns:
		0 0 48px center center,
		1 1 406px center flex-start,
		0 0 74px center center,
		0 0 112px center center,
		1 0.3 896px center center;

	@include tableColumnStyle( $columns );
}

// レビュー特典登録 クーポン
.bl_table.bl_table__coupon {

	height: 632px;

	$columns:
		0 0 48px center center,
		1 1 480px center flex-start,
		0 0 112px center center,
		1 0.3 896px center center;

	@include tableColumnStyle( $columns );
}

// 商品グループ登録
.bl_table.bl_table__productGroup {

	height: 691px;

	$columns:
		0 0 48px center center,
		1 1 480px center flex-start,
		1 0 200px center flex-start,
		1 0.4 808px center center;

	@include tableColumnStyle( $columns );
}

// キャンペーン
.bl_table.bl_table__campaign {

	height: 691px;

	$columns:
		0 0 32px center center,
		0 0 48px center center,
		1 1 468px center flex-start,
		0 0 160px center center,
		0 0 160px center center,
		1 1 460px center flex-start,
		0 0 240px left space-between;

	@include tableColumnStyle( $columns );

	td:nth-child(5) {
		text-align: center;
	}

	td:nth-child(1) {
		padding: 0;
	}
	.el_dragIndicator {
		background-image: url(../img/drag_indicator_black_24dp.svg);
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		height: 100%;
	}
}


// メールテンプレート
.bl_table.bl_table__mailTemplate {

	height: 687px;

	$columns:
		0 0 48px center center,
		0 1 1176px center flex-start,
		0 0 312px left center;

	@include tableColumnStyle( $columns );

	td:nth-child(2) {
		padding-left: 48px;
	}

	.is_favorite {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 8px;
			width: 16px;
			height: 16px;
			background-image: url(../img/star.svg);
			background-repeat: no-repeat;
			transform: translateY(-50%);
		}
	}
}

// レビュー分析テーブル
.bl_table.bl_analysisTable {
	$columns:

		1 1 480px center left,
		0 0 200px center right,
		0 0 200px center right;

	@include tableColumnStyle( $columns );
}

// 全体レビュー投稿率テーブル
.bl_table.bl_postRateTable {
	$columns:

		1 1 120px center center,
		0 0 130px center right,
		0 0 130px center right,
		0 0 130px center right,
		0 0 130px center right,
		0 0 130px center right,
		0 0 130px center right,
		0 0 130px center right;

	@include tableColumnStyle( $columns );
}


// レビュー特典申込割合
.bl_table.bl_table__receptionRate {

	height: 691px;

	$columns:
		0 0 48px center center,
		1 1 456px center flex-start,
		0 0 160px center center,
		0 0 882px center center;

	@include tableColumnStyle( $columns );
}

// 会員ランク別
.bl_analysisTable.bl_analysisTable__rank {

	@include analysisTableMark;

	$colors:
		$sub1,
		$sub2,
		$sub3,
		$sub4,
		$sub5;

	@include setAnalysisColors( $colors, 5 );
}

// 性別
.bl_analysisTable.bl_analysisTable__gender {

	@include analysisTableMark;

	$colors:
		$sub1,
		$sub2,
		$sub3;

	@include setAnalysisColors( $colors, 3 );
}

// 年代
.bl_analysisTable.bl_analysisTable__age {

	@include analysisTableMark;

	$colors:
		$sub1,
		$sub2,
		$sub3,
		$sub4,
		$sub5,
		$sub6,
		$sub7;

	@include setAnalysisColors( $colors, 7 );
}

.bl_table_barGraphUnit {
	display: flex !important;
	justify-content: space-between !important;
}

.bl_table_barGraphWrap {
	flex: 0 1 50%;

	&:first-child {
		padding-right: 12px;
	}

	&:last-child {
		padding-left: 12px;
	}
}

.bl_table_barGraphWrap > p {
	margin-bottom: 8px;
	font-weight: bold;
	color: $main1;
}

.bl_table_barGraphWrap > ul {
	display: flex;
}

.bl_table_barGraphWrap > ul > li {
	position: relative;
	font-weight: bold;
	margin-right: 16px;
	padding-left: 26px;

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 0px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		transform: translateY(-50%);
	}

	$colors:
		$accent1,
		$sub8;
	@include setBarGraphColors( $colors, 2 );
}


.bl_analysisTable.bl_analysisTable__bycase {

	height: 640px;

	$columns:
		1 1 1152px left flex-start,
		0 0 100px center center,
		0 0 128px center center,
		0 0 156px center center;

	@include tableColumnStyle( $columns );

	&::-webkit-scrollbar-track {
		margin-top: 49px;
	}

	border-bottom: 1px solid $main1;
}

// テーブルヘッダーのボーダーが下側のみパターン
.bl_table.bl_table__headBorderBottom {

	height: 560px;

	$columns:
		0 1 348px center flex-start,
		0 0 110px center center;

	@include tableColumnStyle( $columns );

	&::-webkit-scrollbar-track {
		margin-top: 51px;
	}

	th {
		padding-top: 16px;
		padding-bottom: 16px;
		border-top: none;
	}
}

// テーブルヘッダーのボーダーが下側のみパターン
.bl_table.bl_table__systemAlert {

	border-bottom: none;

	$columns:
		0 1 882px center flex-start,
		0 0 110px center center;

	@include tableColumnStyle( $columns );

	td {
		// display: table-column;
		// vertical-align: middle;
		flex-wrap: wrap;
		padding-top: 16px;
		padding-bottom: 16px;
		border-bottom: none;
	}
}

.bl_table.bl_table__plan {

	border-bottom: none;

	$columns:
		0 0 200px center flex-start,
		0 1 500px left flex-start;

	@include tableColumnStyle( $columns );

	td {
		// display: table-column;
		// vertical-align: middle;
		flex-wrap: wrap;
		padding-top: 16px;
		padding-bottom: 16px;
		border-bottom: none;
	}
}

.bl_table.bl_table__notCovered {

	height: 688px;

	$columns:
		0 0 48px center center,
		0 1 410px center flex-start,
		0 1 726px center flex-start,
		0 0 162px center center,
		0 0 162px center center,
		0 0 152px left center;

	@include tableColumnStyle( $columns );

	th:nth-child(6) {
		padding-left: 40px;
	}
}

.bl_table.bl_table__prefecture {

	border-bottom: none;

	$columns:
		0 0 20% left left,
		0 0 80% left left;

	@include tableColumnStyle( $columns );

	td {
		padding-top: 8px;
		padding-bottom: 8px;
		border-bottom: 1px solid #e6e6e6;
		white-space: normal;

		label {
			white-space: nowrap;
		}
	}

}

// 5. フレックス & グリッド
// ================================================================================

// レビュー待ち状況の件数表
.bl_reviewData {
	display: flex;
	justify-content: space-between;

	&_item {
		flex: 0 1 190px;
		padding-right: 16px;
		padding-left: 16px;
		border-left: 1px solid $gray2;
	}

	&_item:last-child {
		border-right: 1px solid $gray2;
	}

	&_heading {
		margin-bottom: 9px;
		line-height: 1.8;
		font-weight: bold;
		color: $main1;
	}

	&_item > p {
		font-weight: bold;
		font-size: 24px;
	}

	.el_unit {
		margin-left: 8px;
		font-size: 14px !important;
	}
}

@include mediaQuery($SP) {
	.bl_reviewData {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		&_item {
			padding-top: 16px;
			padding-bottom: 16px;
			border: none;
			border-bottom: 1px solid $gray2;

			&:nth-child(odd) {
				position: relative;
				padding-left: 0;

				&:last-child {
					border-bottom: none;
				}

				&::after {
					content: "";
					display: block;
					position: absolute;
					top: 50%;
					right: 0;
					width: 1px;
					height: 82.02247%;
					background-color: $gray2;
					transform: translateY(-50%);
				}
			}

			&:last-child {
				border-right: none;
			}
		}
	}
}


// 6. フォーム
// ================================================================================

// ログインページ・パスワード再発行ページの1行
.bl_loginFormRow {
	margin-bottom: 32px;

	&:last-child {
		margin-bottom: 0;
	}
}

// フォームの青色ラベル
.bl_blueLabel {
	display: inline-block;
	margin-bottom: 8px;
	font-weight: bold;
	font-size: 1.28571rem;
	color: $main1;
}

// 画像アップロード
.bl_imgUpload {
	display: flex;

	&_imgWrap {
		flex: 0 0 auto;
		width: 80px;
		height: 80px;
		margin-right: 16px;
		border: 1px solid $gray2;

		> img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&_logoimgWrap {
		flex: 0 0 auto;
		width: 140px;
		height: 100px;
		margin-right: 16px;
		border: 1px solid $gray2;

		> img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&_txtWrap {
		display: flex;
		flex-direction: column;
		padding-top: 8px;

		label {
			cursor: pointer;
		}

		input[type="file"] {
			display: none;
		}

		.el_btnTxt {
			display: inline-block;
			margin-bottom: 8px;
			line-height: 1.4;
		}

		> p {
			font-size: 12px;
		}
	}
}

.bl_password {
	position: relative;

	input {
		padding-right: 40px;
	}

	.el_showPassword {
		&::before {
			content: "";
			width: 16px;
			height: 16px;
			background-image: url(../img/eye-slash-fill.svg);
			background-repeat: no-repeat;
			background-size: 100%;
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			right: 16px;
		}

		&.active::before {
			background-image: url(../img/eye-fill.svg);
		}
	}
}

// バナー追加
.bl_keywordComment {
	display: flex;
	align-items: center;
	padding-left: 26px;
	white-space: nowrap;

	> input {
		margin-right: 8px;
		margin-left: 8px;
	}
}

// アコーディオン
.bl_acc_ttl {
	position: relative;
	cursor: pointer;
	padding-left: 1.5em;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		width: 1em;
		height: 100%;
		background-color: currentcolor;
		-webkit-mask-image: url(../img/move.svg);
		-webkit-mask-position: center;
		-webkit-mask-repeat: no-repeat;
		mask-image: url(../img/move.svg);
		mask-position: center;
		mask-repeat: no-repeat;
	}

	&.is_active::before {
		transform: rotate(90deg);
	}
}

.bl_acc_ttl.is_active + .bl_acc_body {
	display: block;
}

.bl_acc_body {
	display: none;
}

// 7. リスト
// ================================================================================

// トップページのお知らせ
.bl_noticeList {
	margin-bottom: 16px;

	li {
		display: flex;
		margin-bottom: 16px;
		line-height: 1.9;

		&:last-child {
			margin-bottom: 0;
		}

		> time {
			margin-right: 24px;
		}
	}
}

// トップページのアラート
.bl_warningList {
	margin-bottom: 16px;

	li {
		padding-left: 10px;
		margin-bottom: 16px;
		line-height: 1.9;
		border-left: 4px solid $error;

		&:last-child {
			margin-bottom: 0;
		}

		> h3 {
			font-weight: bold;
			font-size: 16px;
			color: $error;
			text-decoration: underline;
		}
	}
}

// トップページのランク
.bl_rank {
	padding-bottom: 16px;

	&_item {
		padding-top: 6px;
		padding-left: 32px;
		margin-bottom: 16px;
		background-repeat: no-repeat;
		background-position: left bottom;
		background-size: 26px;
		line-height: 1.3;
		font-weight: bold;

		&:last-child {
			margin-bottom: 0;
		}

		&__1 {
			background-image: url(../img/rank-1.svg);
			color: $sub1;
		}

		&__2 {
			background-image: url(../img/rank-2.svg);
			color: $sub2;
		}

		&__3 {
			background-image: url(../img/rank-3.svg);
			color: $sub3;
		}
	}
}

.bl_checkList {
	padding: 18px 16px;

	&Wrap {
		height: 510px;
		border: 1px solid $gray2;
		overflow: auto;
		@include scrollBar;

		&::-webkit-scrollbar-track {
			margin-top: 0;
		}
	}

	&_item {
		margin-bottom: 18px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}


// 7. ヘッダー
// ================================================================================

// ヘッダー右上プルダウンメニュー
.bl_pullDown {
	position: relative;
	border-left: 1px solid $gray2;

	&:last-child {
		border-right: 1px solid $gray2;
	}

	&_selected {
		display: inline-block;
		position: relative;
		padding-top: 24px;
		padding-right: 40px;
		padding-bottom: 8px;
		padding-left: 48px;
		margin-top: -16px;
		background-color: #FFF;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		z-index: 15;

		@at-root {
			.bl_pullDown__shop & {
				max-width: calc(21em + 88px);
			}

			.bl_pullDown__user & {
				max-width: calc(9em + 88px);
			}
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: calc(50% + 8px);
			background-repeat: no-repeat;
			background-size: 100%;
			transform: translateY(-50%);
		}

		&::before {
			left: 16px;
		}

		&::after {
			right: 24px;
		}
	}

	&_list {
		position: absolute;
		padding-top: 9px;
		top: 40px;
		border: 1px solid $gray2;
		transition-property: bottom opacity;
		transition-duration: $anim_pullDown;
		overflow: hidden;
		transform: translateY(-120%);
		opacity: 0;
		z-index: 10;
	}

	&_item > a {
		display: block;
		padding: 16px 24px 16px 36px;
		background-color: #FFF;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		@at-root {

			.bl_pullDown__shop & {
				max-width: calc(21em + 48px);
			}
		}

		&:hover {
			background-color: $gray2;
		}
	}

	&_item > span {
		display: block;
		padding: 6px 24px;
		background-color: #FFF;
		color: $accent1;
		font-weight: bold;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		@at-root {

			.bl_pullDown__shop & {
				max-width: calc(21em + 48px);
			}
		}
	}
}

@include mediaQuery($MOBILE) {

	.bl_pullDown {
		border-right: 1px solid $gray2;
		border-left: none;

		&__shop {
			flex: 0 0 55%;

			.bl_pullDown_selected {
				width: 100%;
				max-width: calc(90vw * .55 - 48px);
				padding: 16px 16px 16px 42px;
			}

			.bl_pullDown_list {
				padding-top: 2px;
			}
		}

		&__user {
			flex: 0 0 45%;

			.bl_pullDown_selected {
				width: 100%;
				max-width: calc(90vw * .45 - 48px);
				padding: 16px 0 16px 32px;
			}

			.bl_pullDown_list {
				right: auto !important;
				left: 0;
			}
		}

		&:last-child {
			border-right: none;
		}

		&_selected {
			margin-top: 0;
			padding-top: 16px;
			padding-right: 24px;
			padding-bottom: 16px;
			padding-left: 34px;

			&::before,
			&::after {
				top: 50%;
			}

			&::before {
				left: 8px;
			}

			&::after {
				right: 0px;
			}
		}
	}
}

@include mediaQuery($SP) {

	.bl_pullDown {

		&__shop {

			.bl_pullDown_item {

				> a {
					max-width: calc(21em + 48px - (480px - 100vw));
				}
			}
		}
	}
}

// ショップメニュー
.bl_pullDown__shop {

	.bl_pullDown_list {
		left: 0;
	}

	.bl_pullDown_selected {

		&::before {
			width: 18px;
			height: 16px;
			background-image: url(../img/icon-shop.svg);
		}

		&::after {
			width: 8px;
			height: 6px;
			background-image: url(../img/down.svg);
		}
	}
}

// ユーザーメニュー
.bl_pullDown__user {

	.bl_pullDown_list {
		right: 0;
	}

	.bl_pullDown_selected {
		&::before {
			width: 16px;
			height: 16px;
			background-image: url(../img/icon-user.svg);
		}
	}
}

// メニュープルダウン時
.bl_pullDown.is_open {

	.bl_pullDown_list {
		transform: translateY(0%);
		opacity: 1;
	}
}

@include mediaQuery($MOBILE) {

	.bl_pullDown.is_open {

		.bl_pullDown_list {
			top: 48px;
		}
	}
}


// 8. サイドドロワー
// ================================================================================

// 共通スタイル
.bl_drawer {
	font-weight: bold;
	color: #FFF;

	&::after {
		content: "";
		display: block;
		width: 100%;
		height: calc(100vh - 285px);
		background-color: $drawer;
	}

	&_item {
		position: relative;
		border-bottom: 1px solid #FFF;

		&__top {
			.bl_drawer_icon {
				background-image: url(../img/icon-top.svg);
			}
		}
		&__privilege {
			.bl_drawer_icon {
				background-image: url(../img/icon-review.svg);
			}
		}
		&__mail {
			.bl_drawer_icon {
				background-image: url(../img/icon-mail.svg);
			}
		}
		&__analysis {
			.bl_drawer_icon {
				background-image: url(../img/icon-bunseki.svg);
			}
		}
		&__setting {
			.bl_drawer_icon {
				background-image: url(../img/icon-set.svg);
			}
		}
	}

	&_ttlWrap {
		position: relative;
		display: flex;
		align-items: center;
		position: relative;
		height: 56px;
		background-color: $drawer;
		cursor: pointer;

		// hover時のマーカー
		@include drawerMarker(3);

		&::before {
			content: "";
			display: none;
			position: absolute;
			top: 20px;
			right: 20px;
			width: 10px;
			height: 18px;
			background-image: url(../img/move.svg);
			background-repeat: no-repeat;
			transition-property: transform;
			transition-duration: $drawer_duration;
			transform-origin: center;

			.bl_drawer_item__top & {
				display: none !important;
			}
		}
	}

	&_icon {
		display: inline-block;
		width: 24px;
		height: 24px;
		margin-right: 16px;
		margin-left: 16px;
		background-repeat: no-repeat;
		background-position: center;
	}

	&_subList {
		overflow-y: hidden;

		> li {

			> a {
				display: block;
				position: relative;
				padding-top: 17px;
				padding-bottom: 17px;
				padding-left: 56px;

				// hover時のマーカー
				@include drawerMarker(0);

				// 現在のページ
				&.is_current {
					background-color: $main1;

					&::after {
						opacity: 1;
						transition: $drawer_duration;
					}
				}
			}
		}
	}
}

// ドロワー格納時
@mixin bl_drawar_not_open {
	.bl_drawer_ttl {
		display: none;
	}

	.bl_drawer_subList {
		position: absolute;
		> li {
			> a {
				padding: 17px;
			}
		}
	}

	// サブメニュー格納
	.bl_drawer_item:not(.is_open) {
		.bl_drawer_subList {
			top: 0;
			left: calc(-100% - 200px);
			width: 200px;
			height: auto;
			padding-top: 0;
			padding-bottom: 0;
			padding-left: 16px;
			background-color: $drawer;
			transition-duration: $drawer_duration;
			z-index: -10;
		}
	}

	// サブメニュー展開
	.bl_drawer_item.is_open {
		.bl_drawer_ttlWrap {
			background-color: $sub1;
		}

		.bl_drawer_subList {
			top: 0;
			left: calc(100% + 2px);
			width: 200px;
			height: auto;
			border-top: none;
			background-color: $drawer;
			transition-duration: $drawer_duration;
			z-index: -5;
		}
	}
}

// ドロワー展開
@mixin bl_drawar_open {
	width: 240px;

	// サブメニュー格納
	.bl_drawer_item:not(.is_open) {
		.bl_drawer_ttlWrap {
			position: relative;
			&::before {
				display: block;
			}
		}

		.bl_drawer_ttl {
			display: block;
		}

		.bl_drawer_subList {
			position: static;
			height: 0;
		}
	}

	// サブメニュー展開
	.bl_drawer_item.is_open {
		.bl_drawer_ttlWrap {
			position: relative;

			&::before {
				display: block;
				transform: rotate(90deg);
			}
		}

		.bl_drawer_subList {
			height: auto;
			padding-top: 16px;
			padding-bottom: 16px;
			transition-duration: $drawer_duration;

			> li {
				> a {
					padding-top: 17px;
					padding-right: 17px;
					padding-bottom: 17px;
					padding-left: 56px;
				}
			}
		}
	}
}

@include mediaQueryHigher($MOBILE) {
	.bl_drawer {
		&:not(.is_open) {
			@include bl_drawar_not_open;
		}

		&.is_open {
			@include bl_drawar_open;
		}
	}

	.bl_drawer_item.is_open {
		.bl_drawer_subList {
			border-top: 1px solid #FFF;
		}
	}
}

@include mediaQuery($MOBILE) {
	.bl_drawer {
		position: absolute;
		top: 0;
		left: 0;
		width: 240px;
		transition-property: transform;
		transition-duration: $drawer_duration;
		transform: translateX(-100%);

		&_item:first-child {
			border-top: 1px solid #FFF;
		}

		&:not(.is_open_mobile) {
			@include bl_drawar_not_open;
			.bl_drawer_ttl {
				display: block;
			}
			.bl_drawer_item.is_open {
				.bl_drawer_subList {
					top: 0;
					left: calc(-100% - 200px);
					width: 200px;
					height: auto;
					padding-top: 0;
					padding-bottom: 0;
					padding-left: 16px;
					background-color: $drawer;
					transition-duration: $drawer_duration;
					z-index: -10;
				}
			}
		}

		&.is_open_mobile {
			@include bl_drawar_open;
			transform: translateX(0%);
		}

		&_subList {
			background-color: $drawer;
		}
	}
}


// 9. ログイン画面
// ================================================================================

// ログアウト時のテキスト
.bl_loginForm_logoutNote {
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 48px;
	padding: 16px;
	border-radius: 4px;
	background-color: $gray2;
	text-align: center;
}

@include mediaQuery($MOBILE) {
	.bl_loginForm_logoutNote {
		margin-bottom: 24px;
	}
}

@keyframes shake {
	0% { transform: translateX(0); }
	25% { transform: translateX(-5px); }
	50% { transform: translateX(5px); }
	75% { transform: translateX(-5px); }
	100% { transform: translateX(0); }
}
.shake {
	animation: shake 0.5s ease;
}

// パスワード再設定画面のフォーム
.bl_passForm {
	max-width: 720px;
	height: auto;
	padding: 80px;
	border-radius: 6px;
	background-color: #FFF;
}

// アカウント招待画面のフォーム
.bl_inviteForm {
	max-width: 720px;
	height: auto;
	padding: 80px;
	border-radius: 6px;
	background-color: #FFF;
}

@include mediaQuery($SP) {
	.bl_passForm {
		width: auto;
		height: auto;
		padding: 24px;
	}
	.bl_inviteForm {
		width: auto;
		height: auto;
		padding: 24px;
	}
}


// 10. モーダルウィンドウ （商品詳細・メールプレビュー）
// ================================================================================

.bl_modalBg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(36, 36, 36, .8);
	z-index: 35;
}

// 詳細表示時に他の要素より上に表示するためのスタイル
div.show_detail {
	.ly_body {
		position: relative;
		z-index: 30;
	}

	.ly_main {
		z-index: 30;
	}
}

.bl_modal {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 496px;
	max-width: calc(100% - 48px);
	padding: 24px;
	border-radius: 6px;
	background-color: #FFF;
	box-shadow: 0px 0px 10px $gray_10;
	transform: translate(-50%, -50%);




	&_header {
		display: flex;
		justify-content: space-between;
		margin-top: -24px;
		margin-right: -24px;
		margin-left: -24px;
		padding-top: 16px;
		padding-bottom: 16px;
		padding-right: 24px;
		padding-left: 24px;
		border-radius: 6px 6px 0 0;
		background-color: $accent1;
	}

	&_ttl {
		font-weight: bold;
		font-size: 18px;
		color: #FFF;
	}

	&_table {
		display: block;
		max-height: calc(100vh - 148px);
		margin-top: 24px;
		white-space: nowrap;
		overflow-y: auto;
		@include scrollBar(0);

		th,
		td {
			padding-top: 8px;
			padding-bottom: 8px;
		}

		th {
			padding-right: 32px;
			font-weight: bold;
		}
	}
}

@include mediaQuery($SP) {

	.bl_modal {

		&_table {
			max-height: 300px;
		}
	}
}

.bl_modal.bl_modal__mailPreview {
	width: 672px;

	.bl_modal_mailHeader_row {
		display: flex;
		padding-top: 24px;
		padding-bottom: 16px;
		border-bottom: 1px solid $gray2;

		> dt {
			flex: 0 0 66px;
			font-weight: bold;
		}

		> dd {
			flex: 0 0 558px;
			color: $gray4;
		}
	}

	.bl_modal_mailBody {
		position: relative;
		max-height: calc(100vh - 276px);
		padding-top: 24px;
	}

	.bl_modal_mailBodyInner {
		height: 611px;
		max-height: calc(100vh - 292px);
		padding: 24px 20px 24px 24px;
		border: 1px solid $gray2;
		overflow-y: auto;
		white-space: pre-wrap;

		@include scrollBar(0);
	}
}

@include mediaQuery($SP) {

	.bl_modal.bl_modal__mailPreview {

		> .bl_modal_mailBody,
		.bl_modal_mailBodyInner {
			max-height: 120px;
		}
	}
}

.bl_modal.bl_modal__shopPreview {
	font-family: 'メイリオ', Meiryo,'ヒラギノ角ゴシック','Hiragino Sans',sans-serif;
	font-weight: normal;
	line-height: 1.9;
	width: 912px;
	max-width: calc(100% - 64px);

	.bl_modal_body {
		max-height: calc(100vh - 52px - 64px);
		padding: 16px 24px 48px;
		@include scrollBar(0);
		overflow-y: auto;


		.bl_modal_bodyTtl {
			padding-bottom: 8px;
			border-bottom: 1px solid $base;
			font-weight: bold;
			font-size: 16px;
		}

		.bl_modal_imgWrap {
			position: relative;
			height: 96px;
			margin-bottom: 32px;

			> img {
				position: absolute;
				top: 50%;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
				transform: translateY(-50%);
			}
		}

		.bl_modal_thankyou {
			text-align: center;
			font-size: 16px;
		}

		.bl_modal_complete {
			padding: 16px;
			border-radius: 4px;
			border: 2px solid currentColor;
			text-align: center;
			font-weight: bold;
			font-size: 16px;
			color: $red;
		}

		.el_roundBtn:hover {
			background-color: $gray5;
		}

		.bl_modal_btnWrap {
			margin-bottom: 48px;
			text-align: center;
		}

		.bl_modal_notes {
			list-style-type: square;
			list-style-position: inside;

			> li {
				line-height: 1.9;
			}
		}

		> * {
			margin-bottom: 32px;
		}

		a[href^="tel:"] {
			text-decoration: none;
			color: inherit;
			pointer-events: none;
		}
	}
}

@include mediaQuery($SP) {

	.bl_modal.bl_modal__shopPreview {

		.bl_modal_body {
			max-height: 300px;
		}
	}
}



// 11. 商品選択
// ================================================================================

.bl_selectProduct {
	display: flex;
	justify-content: space-between;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		border-style: solid;
		border-width: 8px 8px 8px 0;
		border-color: transparent $main1 transparent transparent;
		transform: translate(-50%, -50%);
	}

	&_selected {
		position: relative;
		flex: 0 1 460px;
		max-width: 460px;
		min-width: 0;
		margin-right: 40px;
		overflow-wrap: break-word;

		textarea {
			resize: none;
			width: 100%;
			height: 435px;
			padding: 16px;
			border: 1px solid $gray2;

			@include scrollBar(0);
		}
		.textarea {
			resize: none;
			max-width: 100%;
			width: 100%;
			height: 435px;
			padding: 16px;
			border: 1px solid $gray2;
			line-height: 1.5;
			overflow-x: hidden;
			overflow-y: auto;

			@include scrollBar(0);

			&.disabled {
				background-color: #FFF;
				filter: grayscale(100%) contrast(75%);
			}

			&[contenteditable=true]:empty:before {
				content: attr(placeholder);
				display: block;
				color: #aaa;
			}

			span[data-groupid] {
				position: relative;
				display: inline-block;
				padding: 4px 8px 4px 24px;
				margin: 1px;
				border-radius: 20px;
				background-color: $main1;
				color: #FFF;

				> span {
					&::after {
						content: "";
						display: inline-block;
						position: absolute;
						width: 22px;
						height: 100%;
						top: 0px;
						left: 0px;
						border-radius: 100%;
						cursor: pointer;
					}
				}

				&::before,
				&::after {
					content: "";
					display: inline-block;
					position: absolute;
					background-color: #FFF;
					transform-origin: center;
					cursor: pointer;
				}

				&::before {
					width: 10px;
					height: 1px;
					top: 50%;
					left: 6px;
					transform: translateY(calc(-50% - 0.5px)) translateX(0.5px) rotate(45deg);
				}

				&::after {
					width: 1px;
					height: 10px;
					top: 50%;
					left: calc(6px + 5px);
					transform: translateY(calc(-50% - 0.5px)) rotate(45deg);
				}
			}
		}

		> span {
			position: absolute;
			padding: 4px 8px 4px 24px;
			border-radius: 20px;
			background-color: $main1;
			color: #FFF;

			> span {
				&::after {
					content: "";
					display: inline-block;
					position: absolute;
					width: 22px;
					height: 22px;
					top: 1px;
					left: 1px;
					border-radius: 100%;
					cursor: pointer;
				}
			}

			&::before,
			&::after {
				content: "";
				display: inline-block;
				position: absolute;
				background-color: #FFF;
				transform-origin: center;
				cursor: pointer;
			}

			&::before {
				width: 10px;
				height: 1px;
				top: 50%;
				left: 6px;
				transform: translateY(calc(-50% - 0.5px)) translateX(0.5px) rotate(45deg);
			}

			&::after {
				width: 1px;
				height: 10px;
				top: 50%;
				left: calc(6px + 5px);
				transform: translateY(calc(-50% - 0.5px)) rotate(45deg);
			}
		}
	}

	&_selection {
		flex: 0 1 460px;
	}

	&_selectionTab {
		display: flex;

		> li {
			position: relative;
			margin-right: 8px;
			padding-top: 8px;
			padding-right: 16px;
			padding-bottom: 8px;
			padding-left: 34px;
			border-radius: 4px 4px 0 0;
			border: 1px solid currentColor;
			background-color: #FFF;
			font-weight: bold;
			color: $gray4;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}

			&.is_active {
				border-color: transparent;
				background-color: $main1;
				color: #FFF;

				&::before {
					background-image: url(../img/cplus.svg);
				}
			}

			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: 16px;
				width: 16px;
				height: 16px;
				background-image: url(../img/cplus-g.svg);
				background-repeat: no-repeat;
				transform: translateY(-50%);
			}
		}
	}

	&_selectionInner {
		position: relative;
		display: none;
	}

	&_selectionInner.is_active {
		display: block;
	}

	&_group {
		padding: 16px;
		height: 260px;
		border: 1px solid $main1;
		overflow-y: auto;

		li {
			margin-bottom: 16px;
			padding: 1px 0;
			line-height: 1;
		}

		.el_checkMark {
			margin-right: 8px;
		}

		@include scrollBar(0);
	}

	&_price {
		padding: 16px;
		border: 1px solid $main1;

		> * {
			display: block;
			margin-bottom: 16px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

@include mediaQuery($SP) {

	.bl_selectProduct {
		display: flex;
		flex-direction: column;

		&::after {
			transform: translate(-50%, -50%) rotate(90deg);
		}

		&_selected {
			margin-right: 0;
			margin-bottom: 40px;
		}
	}
}


// 12. 進捗ナビゲーション
// ================================================================================

.bl_progressTracker {
	display: flex;
	margin-bottom: 16px;

	&_item:first-child {
		margin-left: 0;
		padding-left: 16px;
		clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 50%, calc(100% - 16px) 100%, 0 100%);
	}

	&_item {
		margin-left: -14px;
		padding-top: 8px;
		padding-right: 32px;
		padding-bottom: 8px;
		padding-left: 32px;
		clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 50%, calc(100% - 16px) 100%, 0 100%, 16px 50%);
		background-color: #FFF;
		font-weight: bold;
		color: $gray4;

		&:not(.is_active):hover {
			color: $main1;
		}
	}

	&_item.is_active {
		background-color: $main1;
		color: #FFF;
	}

	&_step {
		display: block;
		margin-bottom: 4px;
	}

	&_title {
		font-size: 18px;
	}
}

@include mediaQuery($MOBILE) {

	.bl_progressTracker {

		&_item:first-child {
			padding-left: 12px;
			clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%, 0 100%);
		}

		&_item {
			margin-left: -5px;
			padding: 10px 16px;
			clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%, 0 100%, 8px 50%);
		}

		&_step {
			margin-bottom: 0;
		}

		&_ttl {
			display: none;
		}
	}
}

@include mediaQuery($SP) {

	.bl_progressTracker {

		&_item:first-child {
			padding-left: 100vw * 0.025;
			clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%, 0 100%);
		}

		&_item {
			margin-left: 100vw * -0.01041;
			padding: (100vw * 0.02083) (100vw * 0.03333);
			clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%, 0 100%, 8px 50%);
		}

		&_step {
			font-size: 100vw * 0.02916;
		}
	}
}


// 13. メールテンプレート
// ================================================================================


.bl_mailTemp:first-child > .bl_mailTemp_ttl {
	margin-top: 0;
}

.bl_mailTemp_ttl {
	position: relative;
	margin-top: 24px;
	padding: 16px 48px 16px 16px;
	font-weight: bold;
	background-color: $main1;
	color: #FFF;
	cursor: pointer;

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		display: block;
		background-color: #FFF;
	}

	// 横
	&::after {
		right: 16px;
		width: 23px;
		height: 2px;
		transform: translateY(-50%);
	}

	// 縦
	&::before {
		right: 27px;
		width: 2px;
		height: 23px;
		transform: translateX(0.5px) translateY(-50%);
	}

	&.is_active::before {
		display: none;
	}
}

.bl_mailTemp_ttl.is_active + .bl_mailTemp_body {
	display: block;
}

.bl_mailTemp_body {
	display: none;
	padding: 24px 0;
}

.bl_mailTemp_selectTemp {
	display: flex;
	justify-content: space-between;
	position: relative;

	&::after {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		right: 126px;
		width: 8px;
		height: 6px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(../img/down.svg);
		transform: translateY(-50%);
		pointer-events: none;
	}


	> select {
		flex: 0 1 auto;
		margin-right: 16px;
	}

	> .el_note {
		flex: 0 0 auto;
	}
}

.bl_mailTemp_test {
	display: flex;
	justify-content: space-between;

	> .el_btnInv {
		flex: 0 0 auto;
		margin-right: 8px;
	}
}


// 14. キャンペーン内容確認
// ================================================================================

.bl_confirm {

	&_ttl {
		margin-right: 8px;
		font-weight: bold;
		font-size: 18px;
		color: #FFF;
	}

	&_mailSection {
		&.bl_confirm_mailSection__disabled {
			color: #B3B3B3;
		}
	}

	&_mailSection_ttl {
		position: relative;
		margin-bottom: 16px;
		padding: 8px 0;
		border-bottom: 2px solid currentColor;
		font-weight: bold;
		font-size: 18px;
		color: $main1;
		cursor: pointer;

		&::before,
		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			background-color: $main1;
		}

		&::before {
			top: 50%;
			right: 11px;
			width: 2px;
			height: 23px;
			transform: translateY(-50%);
		}

		&::after {
			top: 50%;
			right: 0;
			width: 23px;
			height: 2px;
			transform: translateY(-50%);
		}

		+ div {
			display: none;
		}
	}

	&_mailSection_ttl.is_open {
		&::before {
			display: none;
		}

		+ div {
			display: block;
		}
	}

	p {
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el_incomplete {
		font-size: 14px;
	}
}

.bl_confirm_row {
	margin-bottom: 24px;

	&.bl_confirm_row__disabled {
		color: #B3B3B3;
	}

	> .bl_selectProduct_selected {
		flex: 0 0 auto;
		margin-right: 0;

		> textarea {
			height: 240px;
		}
	}

	ul.bl_errorStep_list > li {
		color: $main1;
		font-size: 13px;
		font-weight: bold;
		margin: 12px;
	}

	ul.bl_errorTarget_list {
		margin-bottom: 12px;
	}

	ul.bl_errorTarget_list > li {
		color: $error;
		font-size: 13px;
		margin: 8px 0 8px 12px;

		&::before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 18px;
			height: 18px;
			margin-right: 4px;
			background-image: url(../img/icon-attention.svg);
			background-repeat: no-repeat;
			background-size: 18px;
		}

		> a {
			font-weight: bold;
			text-decoration: underline;

			&:hover {
				color: $main2;
			}
		}
	}
}

.bl_confirm_ttlWrap {
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
	padding: 8px;
	background-color: $main1;

	> button {
		padding: 5px 16px 4px;
		border-radius: 4px;
		background-color: #FFF;
		white-space: nowrap;
		font-weight: bold;
		font-size: 12px;
		color: $main1;
	}
}


// レビュー分析
// ================================================================================

.bl_analysisCap {
	position: relative;

	> p {
		margin-bottom: 8px;
		font-size: 12px;

		.el_btnTxt {
			margin-left: 8px;
		}
	}
}


// グラフ
// ================================================================================

.bl_graph {

	&_header {
		margin-bottom: 24px;

		.bl_analysisCap {
			margin-bottom: 24px;
		}
	}


	&_body {
		margin-bottom: 24px;
	}

	&_footer {
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 24px;

		> span {
			margin-right: 16px;
		}

		.el_btnTerm:not(:last-child) {
			margin-right: 8px;
		}

		&.bl_graph_footer__limit {
			display: flex;
			flex-wrap: wrap-reverse;
			justify-content: space-between;

			> div {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin-bottom: -8px;
				padding-bottom: 16px;

				> * {
					margin-bottom: 8px;
				}
			}
		}
	}
}

@include mediaQuery(380) {

	.bl_graph_footer {
		justify-content: flex-start;

		> * {
			margin-bottom: 6px;
		}
	}

	.bl_graph_footer__limit {

		> div {

			> .el_termCaption {
				flex: 0 0 100%;
				text-align-last: left;
			}
		}
	}
}

.bl_graph_legend {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -16px;
	padding-bottom: 24px;

	&_item {
		display: flex;
		align-items: center;
		margin-right: 24px;
		margin-bottom: 16px;

		> * {
			margin-right: 8px;
		}

		> *:last-child {
			margin-right: 0;
		}

		&[class*="Num"] {

			.bl_graph_legendGraphic {
				background-color: currentColor;
			}
		}

		&[class*="Review"],
		&[class*="num"] {

			.bl_graph_legendGraphic {
				@include graphLegend;
			}
		}
	}

	&_item__coveredNum .bl_graph_legendGraphic {
		color: $legend1;
	}

	&_item__notCoveredNum .bl_graph_legendGraphic {
		color: $legend2;
	}

	&_item__coveredReview .bl_graph_legendGraphic {
		color: $legend3;
	}

	&_item__notCoveredReview .bl_graph_legendGraphic {
		color: $legend4;
	}

	&_item__allReview .bl_graph_legendGraphic {
		color: $legend5;
	}
	&_item__num .bl_graph_legendGraphic {
		color: $main1;
	}
}

.bl_graph_legendGraphic {
	display: inline-block;
	flex: 0 1 auto;
	width: 48px;
	height: 18px;
}

.bl_graph_legendTxt {
	font-weight: bold;
}

.bl_graph_legend .bl_graph_legend_item .el_toggleBtn {
	flex: 0 0 auto;
		width: 48px;
		height: 18px;

	> input[type="checkbox"] {
		width: 48px;
		height: 18px;
	}

	> input[type="checkbox"]:checked {

		+ span {
			left: 32px;

			&::before {
				left: -24px;
			}
		}
	}

	> span {
		width: 14px;
		height: 14px;

		&::before,
		&::after {
			font-size: 10px;
		}

		&::before {
			content: "ON";
		}

		&::after {
			content: "OFF";
			right: -24px;
		}
	}
}


// 15. 商品管理番号アップロード
// ================================================================================

.bl_productNumberUploader {
	> label {
		display: inline-block;
		margin-bottom: 8px;
	}

	input[type="file"] {
		display: none;
	}

	> p {
		margin-bottom: 8px;
		font-size: 12px;
	}

	textarea {
		height: 136px;
	}
}


// メール設定 並び替え部分
// ================================================================================
.bl_sortableField {
	display: flex;
	padding: 16px 8px 0;

	&_knob {
		flex: 0 0 auto;
		width: 16px;
		margin-right: 8px;
		background-color: $gray2;
		background-image: url(../img/b3.svg);
		background-repeat: no-repeat;
		background-position: center;
		cursor: grab;
	}

	&_txtWrap {
		flex: 1 1 auto;

		&_header {
			display: flex;
			margin-bottom: 8px;

			.el_selectWrap {
				flex: 1 1 auto;
				margin-right: 16px;
			}

			.el_btnBlueInv {
				flex: 0 0 auto;
			}
		}

		> textarea {
			width: calc(100% - 78px);
			height: 88px;
		}
	}

	.bl_panel_row__indent & {
		padding-right: 0;
		padding-left: 0;
	}
}

@include mediaQuery(480) {

	.bl_sortableField {

		&_txtWrap {

			> textarea {
				width: 100%;
			}
		}
	}
}


.bl_addSentence {
	margin-top: 16px;
	padding: 8px;
	border: 1px dashed $gray2;
	cursor: pointer;

	> p {
		position: relative;
		text-align: center;
		font-weight: bold;
		color: $gray4;

		&::after {
			content: "";
			display: inline-block;
			width: 14px;
			height: 14px;
			margin-left: 4px;
			background-image: url(../img/plus-g.svg);
			background-repeat: no-repeat;
			transform: translateY(1px);
		}
	}
}

// 16. グラフ
// ================================================================================

.bl_doughnutWrap {
	position: relative;
	margin-bottom: 22px;

	.el_tooltip {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 84px;
		transform: translate(-50%, -50%);
	}

	.el_tip {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		font-weight: bold;
		font-size: 2.08333vw;
		transform: translate(-50%, -50%);


		&::before {
			display: block;
			content: "";
			width: 26px;
			width: 1.35416vw;
			height: 24px;
			height: 1.25vw;
			margin: 0 auto 0.41666vw;
			text-align: center;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}

		&.el_tip__1 {
			color: $sub1;

			&::before {
				background-image: url(../img/rank-1.svg);
			}
		}

		&.el_tip__2 {
			color: $sub2;

			&::before {
				background-image: url(../img/rank-2.svg);
			}
		}

		&.el_tip__3 {
			color: $sub3;

			&::before {
				background-image: url(../img/rank-3.svg);
			}
		}

		&.el_tip__other {
			color: $sub5;

			&::before {
				content: "その他";
				width: fit-content;
				font-size: 12px;
				font-size: 0.625vw;
			}
		}
	}
}

.bl_canvasUnit {
	display: flex;
	justify-content: space-between;

	> .bl_canvasWrap {
		flex: 0 0 50%;
		max-width: 46%;
	}
}

.bl_canvasWrap {
	position: relative;
	margin-bottom: 24px;
}

.bl_horizontalBarCanvasWrap {
	position: relative;
}

// 分析期間設定
// ================================================================================

.bl_termSetting {
	display: none;
	position: absolute;
	width: 100%;
	padding: 24px;
	border-radius: 6px;
	background-color: #FFF;
	border: 1px solid $main1;
	box-shadow: 1px 1px 4px $gray_16;
	z-index: 50;

	&.is_open {
		display: block;
	}

	&_head {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: -8px;
		padding-bottom: 16px;
	}

	&_ttl {
		margin-right: 8px;
		margin-bottom: 8px;
		font-weight: bold;
		color: $main1;
	}

	&_desc {
		font-size: 12px;
	}

	&_body {
		margin-bottom: 16px;
	}

	&_row {
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}

		&:nth-child(1) {
			padding-bottom: 24px;
			margin-bottom: -8px;

			> label {
				white-space: nowrap;

				> span {
					display: inline-block;
				}

				> * {
					margin-bottom: 8px;
				}
			}
		}

		&:nth-child(2) {
			margin-bottom: -8px;

			> label > * {
				margin-bottom: 8px;
			}
		}

		> label {
			margin-right: 16px;

			&:last-child {
				margin-right: 0;
			}

			> span {
				vertical-align: middle;
			}
		}

		.el_inlineInputM:nth-child(2) {
			margin-left: 0;
		}
	}

	&_term {
		display: inline-block;
		margin-bottom: -8px;
		padding-left: 24px;
		text-indent: -24px;

		&:last-child {
			padding-left: 32px;
			text-indent: 0px;
		}

		> * {
			margin-bottom: 8px;
		}
	}
}

// 17. 利用ガイド
// ================================================================================

.bl_guide {

	h3 {
		margin-top: 0;
		margin-bottom: 16px;
		padding: 8px;
		border-left: 8px solid $main1;
		background-color: #F7FBFF;
		line-height: 1.6;
		font-weight: 700;
		font-size: 18px;
		color: $main1;
	}

	> span {
		margin: 16px 0;
		line-height: 1.9;
		font-weight: 500;
		font-size: 14px;
	}

	a {
		font-weight: 700;
		color: $main1;
		text-decoration: underline;
	}

	// 順番ありリスト1
	> ol, > div > div > ol {
		counter-reset: ordered-list;
		line-height: 1.9;

		> li {
			margin: 16px 0;

			> span, p:not(.comments) {
				position: relative;
				display: block;
				margin: 48px 0 32px;
				padding-left: 28px;
				line-height: 1.9;
				text-indent: -28px;
				font-weight: 700;
				font-size: 14px;

				&::before {
					counter-increment: ordered-list;
					content: counter(ordered-list);
					display: inline-flex;
					justify-content: center;
					align-items: center;
					width: 24px;
					height: 24px;
					margin-right: 4px;
					background-color: $main1;
					text-indent: 0;
					color: #FFF;
				}

				&::after {
					position: absolute;
					left: 0;
					bottom: 0;
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					background-color: $main1;
				}
			}
		}
	}

	.el_guideMenuTitle {
		display: flex;
		align-items: center;
		margin: 32px 0 16px;
		padding: 0;
		background-color: unset;
		text-indent: unset;
		font-weight: 700;
		font-size: 18px;
		color: $main1;

		&::before {
			content: "";
			display: inline-block;
			width: 32px;
			height: 32px;
			margin-right: 8px;
			border-radius: unset;
			background-repeat: no-repeat;
			background-size: contain;
		}

		&.el_guideMenuTitle__setting {
			&::before {
				background-image: url(../img/img-q-set.svg);
			}
		}

		&.el_guideMenuTitle__operation {
			&::before {
				background-image: url(../img/img-q-beginner.svg);
			}
		}
	}

	.el_btnWideBorderInv {
		text-decoration: none;
	}

	.banner {
		cursor: pointer;
		display: block;
		width: 384px;
		max-width: 100%;
		margin-top: 48px;
		margin-inline: auto;
		transition: opacity .3s ease-out;

		img {
			height: auto;
		}

		&:hover {
			opacity: .6;
		}

		+ span {
			text-align: center;
			font-weight: 700;

			+ h3 {
				margin-top: 48px;
			}
		}
	}

	span.banner_desc {
		text-align: center;
		font-weight: 700;

		+ h3 {
			margin-top: 48px;
		}
	}
}

.bl_guideMenu {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
	gap: 16px 8px;
	margin: 16px 0 32px;

	> li {
		margin: 0 !important;
		border: 1px solid #E6E6E6;

		> a:not(.el_btnWideBorderInv) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 16px;
			text-decoration: none;
			line-height: 1.9;
			font-weight: 700;
			font-size: 14px;
			transition: opacity .3s ease-out;

			&:hover {
				opacity: .7;
			}

			&::after {
				content: "";
				display: inline-block;
				width: 10px;
				height: 12px;
				background-repeat: no-repeat;
				background-size: contain;
				background-image: url(../img/icon-arrow01.svg);
			}
		}


		.el_btnWideBorderInv {
			cursor: pointer;
			width: 100%;
			line-height: 1.2;
		}
	}

	&__unique {
		grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));

		> li {
			border: none;
		}
	}
}

@include mediaQuery($SP) {
	.bl_guideMenu {
		grid-template-columns: 1fr;
	}
}

.bl_guideNav {
	margin-bottom: 24px;

	&_title {
		margin-bottom: 16px;
		padding-bottom: 4px;
		border-bottom: 2px solid $main1;
		font-weight: 700;
		font-size: 18px;
		color: $main1;
	}

	&_list {
		margin: 8px 0;
	}

	&_item {

		> a {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 8px 0;
			font-weight: 700;
			font-size: 14px;
			transition: opacity .3s ease-out;

			&:hover {
				opacity: .7;
			}

			&.selectGuide::after {
				transform: rotate(90deg);
			}

			&::after {
				content: "";
				display: block;
				width: 14px;
				height: 16px;
				background-repeat: no-repeat;
				background-size: contain;
				background-image: url(../img/icon-arrow01.svg);
			}
		}

		.el_btnWideBorderInv {
			line-height: 1.2;
			margin-top: 24px;
		}
	}

	&_item_index {

		&.index_h3 > a {
			padding-left: 8px;
		}

		&.index_h4 > a {
			padding-left: 16px;
		}

		> a {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 8px 0;
			font-weight: 700;
			font-size: 13px;
			transition: opacity .3s ease-out;

			&:hover {
				opacity: .7;
			}

			&::after {
				content: "";
				display: block;
				width: 10.5px;
				height: 12px;
				background-repeat: no-repeat;
				background-size: contain;
				background-image: url(../img/icon-arrow01.svg);
			}
		}
	}
}

@include mediaQuery(1200) {
	.bl_guideNav {

		.is_active {

			.bl_guideNav_title {

				&::after {
					transform: translateY(-50%) rotate(180deg);
				}
			}

			.bl_guideNav_list {
				height: auto;
				border-bottom: 1px solid $main1;
			}
		}

		&_title {
			position: relative;
			margin-bottom: 0;
			padding: 10px 24px;
			border-bottom-width: 1px;
			cursor: pointer;

			&::before,
			&::after {
				position: absolute;
				content: "";
				display: block;
				width: 14px;
				height: 2px;
				background-color: $main1;
			}

			&::before {
				top: 50%;
				right: 24px;
				transform: translateY(-50%);
			}

			&::after {
				top: 50%;
				right: 24px;
				transition: transform .3s ease-out;
				transform: translateY(-50%) rotate(90deg);
			}
		}

		&_list {
			height: 0;
			margin: 0;
			transition: height .3s ease-out;
			overflow: hidden;
		}

		&_item {
			background-color: #FAFAFA;

			> a {
				padding: 17px 24px;
			}
		}

		+ .el_btnWideBorderInv {
			display: block;
			width: calc(100% - 48px);
			margin: 0 auto;
		}


		&_item_index {

			&.index_h3 > a {
				padding-left: 32px;
			}

			&.index_h4 > a {
				padding-left: 40px;
			}

			> a {
				padding: 17px 24px;
			}
		}
	}
}

.bl_guideFlow {
	>span {
		text-align: center;
		font-size: 18px;
		font-weight: 700;
		color: $main1;
	}

	>ol {

		>li {

			h3 {
				position: relative;
				display: flex;
				align-items: center;
				padding: 16px 8px 16px 48px;
				margin-left: 32px;
				font-size: 24px;
				line-height: 1.3;
				border: none;

				@include mediaQuery($MOBILE) {
					font-size: 18px;
					padding-left: 32px;
				}

				>span {
					position: absolute;
					left: -32px;
					display: inline-flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 64px;
					height: 64px;
					border-radius: 50%;
					font-size: 32px;
					color: #fff;
					line-height: 1.2;
					background-color: $main1;

					@include mediaQuery($MOBILE) {
						width: 48px;
						height: 48px;
						font-size: 22px;
					}

					&::before {
						content: "STEP";
						margin-top: 5px;
						font-size: 10px;

						@include mediaQuery($MOBILE) {
							font-size: 8px;
						}
					}
				}
			}

			ul {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 24px 32px;
				padding-left: 80px !important;

				@include mediaQuery($MOBILE) {
					grid-template-columns: 1fr;
					padding-left: 0px !important;
				}

				li {
					margin: 0 !important;
					display: grid;
					align-content: stretch;
				}
			}

			>span {
				display: flex;
				align-items: center;
				gap: 16px;
				padding-left: 80px !important;
				font-size: 24px;
				color: $accent1;
				text-align: center;
				text-indent: 0;

				@include mediaQuery($MOBILE) {
					padding-left: 0px !important;
					font-size: 18px;
				}

				&::before,
				&::after {
					content: "";
					position: relative;
					height: 2px;
					width: auto;
					max-width: 100%;
					min-width:  58px;
					flex-grow: 1;
					background-color: transparent;
					background: url(../img/border01.svg) repeat-x center/contain;
				}
			}
		}
	}
}


// 18. ツールチップ
// ================================================================================
.el_btnTooltip {
	padding-top: 8px;
	padding-right: 15px;
	padding-bottom: 8px;
	padding-left: 15px;
	border-radius: 4px;
	border: 1px solid currentColor;
	font-weight: bold;
	color: $accent1;
	position: relative;

	&:disabled {
		background-color: #FFF;
		filter: grayscale(100%) contrast(75%);
	}

	&:hover {
		background-color: $accent3;
	}

	> .el_tooltip-text_hidden {
		display: none;
	}

	> .el_tooltip-text {
		opacity: 1;
		visibility: visible;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -30px;
		display: inline-block;
		padding: 5px;
		white-space: nowrap;
		font-size: 0.8rem;
		line-height: 1.3;
		background: #333;
		color: #fff;
		border-radius: 3px;
		transition: opacity 0.3s ease-in;
	
		&::before {
			content: '';
			position: absolute;
			top: -13px;
			left: 50%;
			margin-left: -7px;
			border: 7px solid transparent;
			border-bottom: 7px solid #333;
		}
	}
}

// 19. よくある質問
// ================================================================================
.bl_faqList {

	h3 {
		margin-top: 0;
		margin-bottom: 16px;
		padding: 8px;
		border-left: 8px solid $main1;
		background-color: #F7FBFF;
		line-height: 1.6;
		font-weight: 700;
		font-size: 18px;
		color: $main1;
	}

	h4 {
		margin-top: 0;
		margin-bottom: 16px;
		padding: 8px 8px 8px 27px;
		background-color: $main1;
		line-height: 1.6;
		text-indent: -19px;
		font-weight: 700;
		font-size: 16px;
		color: #FFF;

		&::before {
			content: "";
			display: inline-block;
			width: 11px;
			height: 11px;
			margin-right: 8px;
			border-radius: 50%;
			background-color: #FFF;
		}
	}

	a {
		font-weight: 700;
		color: $main1;
		text-decoration: underline;
	}

	> ul {
		margin-bottom: 16px;

		> li {
			margin: 0;

			> span {
				position: relative;
				display: block;
				padding-left: 16px;
				line-height: 1.9;
				font-weight: 700;
				font-size: 14px;

				&::before {
					position: absolute;
					top: 6px;
					left: 0;
					content: "";
					display: inline-block;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					margin-right: 4px;
					background-color: $main1;
				}
			}
		}
	}
}

.react-datepicker-popper {
	z-index: 100 !important
}